import { SelectControlled } from '@retail/backoffice-ui';
import { Col, Row } from 'antd';
import { memo } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';

import { DatePickerControlled } from '@/components/form/Datepicker';
import { InputControlled } from '@/components/form/input';
import { FIELD_LABEL_COL } from '@/constants/common';

interface WorkshopDetailsFormProps {
  control: Control<FieldValues>;
}

export const WORKSHOP_DETAILS_CAR_PICKED_UP_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const WorkshopDetailsForm = memo<WorkshopDetailsFormProps>(
  ({ control }) => {
    const { t } = useTranslation();
    return (
      <div className={cn.offset}>
        <Row>
          <Col span={12}>
            <InputControlled
              type="string"
              label={t(
                'bo.orderClaims.processing.popUp.workshopDetails.workshopAddress.title',
              )}
              placeholder={t(
                'bo.orderClaims.processing.popUp.workshopDetails.workshopAddress.dropdown.placeholder',
              )}
              controllerProps={{
                name: 'address',
                control,
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col span={12} data-qa-selector="carPickedUp">
            <SelectControlled
              labelCol={FIELD_LABEL_COL}
              allowClear
              label={t(
                'bo.orderClaims.processing.popUp.workshopDetails.carPickedUpStatus.title',
              )}
              options={WORKSHOP_DETAILS_CAR_PICKED_UP_OPTIONS}
              placeholder={t(
                'bo.orderClaims.processing.popUp.workshopDetails.carPickedUpStatus.dropdown.placeholder',
              )}
              controllerProps={{
                name: 'carPickedUp',
                control,
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <DatePickerControlled
              label={t(
                'bo.orderClaims.processing.popUp.workshopDetails.carPickedUpDate.title',
              )}
              dateFormat="DD/MM/YYYY"
              placeholder={t(
                'bo.orderClaims.processing.popUp.workshopDetails.carPickedUpDate.dropdown.placeholder',
              )}
              data-qa-selector="carPickedUpOn"
              controllerProps={{
                name: 'carPickedUpOn',
                control,
              }}
            />
          </Col>
        </Row>
      </div>
    );
  },
);
