/* eslint-disable no-unused-vars */
import { List } from 'antd';
import { FunctionComponent, memo } from 'react';

import { DocumentViewerManager } from '../../../../../../components/DocumentViewer';
import Comment from '../Comment';
import Documents from '../Documents';
import ProofRequest from '../ProofRequest';
import { UseComplaintsData } from '../hooks/useComplaintsDataV2';
import useListDataSource, {
  TUseListDataSourceProps,
} from '../hooks/useListDataSource';

export enum LIST_ITEM_TYPE {
  COMMENT = 'RetailClaimComplaintCommentProjection',
  DOCUMENT = 'RetailClaimComplaintDocumentProjection',
  PROOF_REQUEST = 'RetailClaimProofRequestProjection',
}

export const COMPONENTS: Record<
  string,
  FunctionComponent<TUseListDataSourceProps>
> = {
  [LIST_ITEM_TYPE.DOCUMENT]: Documents,
  [LIST_ITEM_TYPE.COMMENT]: Comment,
  [LIST_ITEM_TYPE.PROOF_REQUEST]: ProofRequest,
} as Record<string, FunctionComponent>;

interface ICustomerComplaintProps {
  complaintData: UseComplaintsData;
}

export const CustomerComplaint = memo<ICustomerComplaintProps>(
  ({ complaintData }) => {
    const dataSource = useListDataSource(complaintData);
    const { documents } = complaintData; // we need flat array of documents for DocumentViewerManager

    return (
      <DocumentViewerManager documents={documents}>
        <List
          dataSource={dataSource}
          data-qa-selector={`customerComplaintsList-${complaintData.complaintNumber}`}
          renderItem={({ __typename, ...data }) => {
            const ListItem = COMPONENTS[__typename];

            return ListItem ? <ListItem {...data} /> : null;
          }}
        />
      </DocumentViewerManager>
    );
  },
);
