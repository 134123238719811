import { SelectControlled } from '@retail/backoffice-ui';
import { Tooltip } from 'antd';
import cns from 'classnames';
import { at, find, flow, get, isEqual } from 'lodash/fp';
import { ReactNode, memo, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  APPROVAL_LEVEL,
  APPROVAL_OPTIONS,
  CLAIM_COST_EDIT_STATES,
  CLAIM_STATES,
} from '../../../../constants';
import { Comments } from '../../comments';

import cn from './styles.less';

import {
  RetailClaimCostCommentProjection,
  RetailClaimCostFragmentFragment,
  RetailSubClaimCommentProjection,
  UserFragmentFragment,
} from '@/apollo/gql-types';
import { useFormatDateTime } from '@/helpers/date/hooks';

const QA_DATA_ATTRIBUTES = {
  APPROVE_STATUS_SELECT: 'approve-status-select',
  APPROVE_LABEL: 'approve-label',
  APPROVED_BY: 'approved-by-user',
  APPROVE_STATUS: 'approve-status',
  COMMENTS: 'approve-comments',
};

export function getLevelComments<
  T extends RetailClaimCostCommentProjection,
  M extends Record<string, unknown>,
>(approvalLevel: keyof T) {
  return (items: M[]) =>
    items?.reduce((comments, comment) => {
      if (!get(approvalLevel, comment)) {
        return comments;
      }
      const [text, createdBy, createdOn, id] = at(
        [approvalLevel, 'createdBy', 'createdOn', 'id'],
        comment,
      );

      return [...comments, { text, createdOn, createdBy, id }];
    }, []);
}

interface ApprovalLevelUpdateProps {
  claimCostApprove: APPROVAL_LEVEL;
  costId: number;
}
interface ApprovalProps {
  label?: ReactNode;
  cost: RetailClaimCostFragmentFragment;
  approvalLevelStatus: APPROVAL_LEVEL;
  approvalLevelSetOn: string;
  approvalLevelSetBy: UserFragmentFragment;
  onApprovalLevelUpdate: ({
    claimCostApprove,
    costId,
  }: ApprovalLevelUpdateProps) => void;
  getComments: (
    cost: RetailClaimCostFragmentFragment['comments'],
  ) => RetailSubClaimCommentProjection[];
  orderClaimsStatus: CLAIM_STATES;

  loading: boolean;
  permission: boolean;
  forcePermission: boolean;
  isAllowedNotApprovedOnly: boolean;
}

export const Approval = memo<ApprovalProps>(
  ({
    label,
    approvalLevelStatus,
    approvalLevelSetOn,
    approvalLevelSetBy,
    cost,
    loading,
    onApprovalLevelUpdate,
    permission,
    forcePermission,
    isAllowedNotApprovedOnly,
    orderClaimsStatus,
    getComments,
  }) => {
    const { t } = useTranslation();
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const comments = useMemo(
      () => getComments(cost.comments ?? []),
      [cost, getComments],
    );
    const formattedDate = useFormatDateTime(approvalLevelSetOn);
    const isEditable = isEqual(
      get(['editState'], cost),
      CLAIM_COST_EDIT_STATES.EDITABLE,
    );

    const options = useMemo(() => {
      const isAllowed = isAllowedNotApprovedOnly && !forcePermission;

      return APPROVAL_OPTIONS.filter((x) =>
        isAllowed
          ? [APPROVAL_LEVEL.NOT_APPROVED, approvalLevelStatus].includes(x.value)
          : true,
      ).map(({ value, label }) => ({
        value,
        label: t(label),
        disabled: isAllowed && value !== APPROVAL_LEVEL.NOT_APPROVED,
      }));
    }, [approvalLevelStatus, isAllowedNotApprovedOnly, forcePermission, t]);

    const tooltip = useMemo(
      () =>
        flow(
          find({ value: approvalLevelStatus }),
          get(['label']),
        )(APPROVAL_OPTIONS),
      [approvalLevelStatus],
    );

    const { control } = useForm<{ approvalLevelStatus: unknown }>({
      defaultValues: {},
      mode: 'all',
    });

    const onVisibleChange = (visible: boolean) => {
      setTooltipVisible(visible);
    };

    const onApprovalChange = (claimCostApprove: APPROVAL_LEVEL) => {
      if (claimCostApprove !== approvalLevelStatus) {
        onApprovalLevelUpdate({
          claimCostApprove,
          costId: cost.id,
        });
      }

      onVisibleChange(false);
    };

    if (
      (forcePermission ||
        isAllowedNotApprovedOnly ||
        (!(orderClaimsStatus === CLAIM_STATES.CLOSED) &&
          ((approvalLevelStatus === APPROVAL_LEVEL.PENDING && permission) ||
            forcePermission))) &&
      isEditable
    ) {
      return (
        <div className={cns(cn.approvalLevel, cn.wide)}>
          <Tooltip
            className={cn.toolTip}
            overlay={t(tooltip)}
            onVisibleChange={onVisibleChange}
            visible={tooltipVisible}
          >
            <div className={cn.statusSelectWrapper}>
              <span
                data-qa-selector={QA_DATA_ATTRIBUTES.APPROVE_LABEL}
                className={cn.label}
              >
                {label}
              </span>
              <SelectControlled
                options={options}
                disabled={loading}
                value={
                  find({ value: approvalLevelStatus })(options)?.value || null
                }
                onChange={onApprovalChange}
                className={cn.select}
                data-qa-selector={QA_DATA_ATTRIBUTES.APPROVE_STATUS_SELECT}
                controllerProps={{
                  name: 'approvalLevelStatus',
                  control,
                }}
              />
            </div>
            {approvalLevelStatus !== APPROVAL_LEVEL.PENDING && (
              <div className={cn.submitter}>
                <div data-qa-selector={QA_DATA_ATTRIBUTES.APPROVED_BY}>
                  {approvalLevelSetBy
                    ? `${get('firstName', approvalLevelSetBy)} ${get(
                        'lastName',
                        approvalLevelSetBy,
                      )} ${formattedDate}`
                    : formattedDate}
                </div>
              </div>
            )}
            {comments.length > 0 && (
              <div className={cn.comments}>
                <Comments
                  list={comments}
                  qaSelector={QA_DATA_ATTRIBUTES.COMMENTS}
                />
              </div>
            )}
          </Tooltip>
        </div>
      );
    }

    if (approvalLevelStatus === APPROVAL_LEVEL.PENDING) {
      return (
        <div>
          <div className={cn.statusWrapper}>
            <span
              data-qa-selector={QA_DATA_ATTRIBUTES.APPROVE_LABEL}
              className={cn.label}
            >
              {label}
            </span>
            <p className={cns(cn.status)}>
              {t('bo.orderClaims.processing.approvalStatus.pending')}
            </p>
          </div>

          {comments.length > 0 && (
            <div className={cn.comments}>
              <Comments
                list={comments}
                qaSelector={QA_DATA_ATTRIBUTES.COMMENTS}
              />
            </div>
          )}
        </div>
      );
    }

    return (
      <div className={cns(cn.approvalLevel, cn.wide)}>
        <div
          className={cns({
            [cn.approved]: approvalLevelStatus === APPROVAL_LEVEL.APPROVED,
            [cn.notApproved]:
              approvalLevelStatus === APPROVAL_LEVEL.NOT_APPROVED,
          })}
          data-qa-selector={QA_DATA_ATTRIBUTES.APPROVE_STATUS}
        >
          <div className={cn.statusWrapper}>
            <span
              data-qa-selector={QA_DATA_ATTRIBUTES.APPROVE_LABEL}
              className={cn.label}
            >
              {label}
            </span>
            <p className={cns(cn.status)}>
              {approvalLevelStatus === APPROVAL_LEVEL.APPROVED
                ? t('bo.orderClaims.processing.approvalStatus.approved')
                : t('bo.orderClaims.processing.approvalStatus.notApproved')}
            </p>
          </div>
        </div>
        <div
          data-qa-selector={QA_DATA_ATTRIBUTES.APPROVED_BY}
          className={cn.approvedBy}
        >
          {approvalLevelSetBy
            ? `${get('firstName', approvalLevelSetBy)} ${get(
                'lastName',
                approvalLevelSetBy,
              )} ${formattedDate}`
            : formattedDate}
        </div>
        {comments.length > 0 && (
          <div className={cn.comments}>
            <Comments
              list={comments}
              qaSelector={QA_DATA_ATTRIBUTES.COMMENTS}
            />
          </div>
        )}
      </div>
    );
  },
);
