// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Err0721ahhR1XOWq1\\+ltBQ\\=\\= {\n  display: grid;\n  grid-template-columns: 294px 166px 184px 184px 60px;\n  gap: 16px;\n  align-items: end;\n  margin-bottom: 16px !important;\n}\n.Err0721ahhR1XOWq1\\+ltBQ\\=\\= .antd-4-21-7-form-item {\n  margin-bottom: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/content/change-log/Search/styles.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mDAAA;EACA,SAAA;EACA,gBAAA;EACA,8BAAA;AADF;AAKM;EACE,gBAAA;AAHR","sourcesContent":["@import '../../../../../../styles/constants.less';\n\n.form {\n  display: grid;\n  grid-template-columns: 294px 166px 184px 184px 60px;\n  gap: 16px;\n  align-items: end;\n  margin-bottom: 16px !important;\n\n  :global {\n    .@{antd-version} {\n      &-form-item {\n        margin-bottom: 0;\n      }\n    }\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "Err0721ahhR1XOWq1+ltBQ=="
};
export default ___CSS_LOADER_EXPORT___;
