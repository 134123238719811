import { Component, Dispatch } from 'react';

import Body from '../Body';
import Header from '../Header';
import { DocumentModel } from '../types';

import cn from './styles.less';
import { findDocumentByActiveUrl, mapDocuments } from './utils';

interface Props {
  documents: DocumentModel[];
  activeUrl: string;
  open: Dispatch<string>;
  close: Dispatch<void>;
}

export default class DocumentViewer extends Component<Props> {
  render() {
    const { open, close } = this.props;

    const list = mapDocuments({ documents: this.props.documents });
    const item = findDocumentByActiveUrl({
      activeUrl: this.props.activeUrl,
      documents: list,
    });

    return (
      <div className={cn.viewer}>
        <Header document={item} close={close} />
        <Body document={item} documents={list} open={open} close={close} />
      </div>
    );
  }
}
