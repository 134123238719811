import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  reasons: [string, string][];
  liablePartyType: string;
  complaintNumber: number;
}

export function ReasonsAndLiableParty({
  reasons,
  liablePartyType,
  complaintNumber,
}: Props) {
  const { t } = useTranslation();

  return (
    <Space direction="vertical" size="middle">
      <div data-qa-selector="subclaim-reasons">
        {complaintNumber && (
          <div>
            <i>
              <u>{`${t(
                'bo.orderClaims.complaints.complaint',
              )} #${complaintNumber}`}</u>
            </i>
          </div>
        )}
        <div>
          <i>
            <u>{`${t('bo.orderClaims.reason')}: `}</u>
          </i>
        </div>
        {reasons.length
          ? reasons?.map(([label, value], index) => (
              <div key={index}>
                <span
                  data-qa-selector={`reasons-${t(label).toLowerCase()}-label`}
                >{`${t(label)}: `}</span>
                <span
                  data-qa-selector={`reasons-${t(label).toLowerCase()}-value`}
                >
                  {t(value)}
                </span>
              </div>
            ))
          : '-'}
      </div>

      <div data-qa-selector="subclaim-liable-party">
        <div>
          <i>
            <u>{`${t('bo.orderClaims.liableParty')}: `}</u>
          </i>
        </div>
        {liablePartyType ? (
          <div data-qa-selector="liable-party">
            {t(`bo.orderClaims.submitReason.liableParty.${liablePartyType}`)}
          </div>
        ) : (
          '-'
        )}
      </div>
    </Space>
  );
}
