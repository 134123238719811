import {
  Layout,
  LayoutBody,
  LayoutHeader,
  PaginatedTable,
} from '@retail/backoffice-ui';
import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';
import { useUserContext } from '@retail/backoffice-ui/src/UserContext';
import { Col, Row } from 'antd';
import { flow, join, map } from 'lodash/fp';
import { memo, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DueDaysConfigButton } from './DueDaysConfigButton';
import { SearchForm } from './SearchForm';
import { useColumns } from './hooks/useColumns';
import { useSearchOrderRetailClaimsQueryAsync } from './hooks/useSearchOrderRetailClaimsQueryAsync';
import cn from './styles.less';
import { RetailClaimProjectionModel } from './types';

import { useSearchClaimAssigneeUsersQuery } from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';
import { UNASSIGNED } from '@/constants/common';
import { DOMAINS, PERMISSIONS } from '@/constants/permissions';
import { getUserAccessibleCountryCodeOptions } from '@/helpers/userAccessibleCountryCodeOptions';
import { IUserContext } from '@/providers/UserProvider';
import { OverviewSearchModel } from '@/types/OverviewSearchModel';
import { getIsTrial } from '@/utils/claims';

export const ClaimsOverview = memo(() => {
  const { t } = useTranslation();
  const { accessParameters } = useUserContext<IUserContext>();
  const {
    controller,
    formValues,
    clearController,
    updateController,
    onPageChange,
    dataSource: dirtyDataSource,
    loading: orderRetailClaimsLoading,
    onChange,
    refetch,
  } = useSearchOrderRetailClaimsQueryAsync();

  const form = useForm<OverviewSearchModel>({
    mode: 'all',
    defaultValues: formValues,
  });
  const { reset } = form;

  useEffect(() => {
    reset(formValues, { keepDirty: false });
  }, [reset, formValues]);

  const claimsAssigneeViewPermission = useCheckPermissions({
    allow: PERMISSIONS.QUERY_CLAIMS_ASSIGNEE_VIEW,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });

  const userAccessibleCountryOptions = useMemo(
    () => getUserAccessibleCountryCodeOptions(accessParameters),
    [accessParameters],
  );

  const { data: claimAssigneeUsersData, loading: claimAssigneeUsersLoading } =
    useSearchClaimAssigneeUsersQuery({
      ...REQUEST_BASE_OPTIONS,
      skip: claimsAssigneeViewPermission?.isDenied,
      variables: {
        countries: userAccessibleCountryOptions.map(
          (country) => country.value as string,
        ),
      },
    });

  const assignees = useMemo(
    () =>
      flow(
        map(({ id, firstName, lastName }) => ({
          value: id,
          label: join(' ', [firstName, lastName]),
        })),
        (entities) => [...entities, { value: UNASSIGNED, label: 'Unassigned' }],
      )(claimAssigneeUsersData?.data?.entities),
    [claimAssigneeUsersData?.data?.entities],
  );

  const dataSource = useMemo(
    () =>
      dirtyDataSource.map(
        (x) =>
          ({
            ...x,
            isTrial: getIsTrial(x.trialExpiredOn),
          } as RetailClaimProjectionModel),
      ),
    [dirtyDataSource],
  );

  const columns = useColumns({
    assignees,
    hasAtLeastOneTrial: dataSource.some((x) => x.isTrial),
  });

  return (
    <Layout>
      <LayoutHeader title={t('bo.claimsOverview.pageTitle')}>
        <DueDaysConfigButton />
      </LayoutHeader>
      <LayoutBody>
        <Row gutter={20} className={cn.offsetBottom}>
          <Col span={24}>
            <FormProvider {...form}>
              <SearchForm
                refetch={refetch}
                assignees={assignees}
                clearController={clearController}
                updateController={updateController}
                isLoading={orderRetailClaimsLoading}
                assigneeUsersIsLoading={claimAssigneeUsersLoading}
                assigneeViewPermission={claimsAssigneeViewPermission}
                userAccessibleCountryOptions={userAccessibleCountryOptions}
              />
            </FormProvider>
          </Col>
        </Row>
        <PaginatedTable
          data-qa-selector="claim-cost-table"
          rowKey="id"
          dataSource={dataSource}
          columns={columns}
          isLoading={orderRetailClaimsLoading}
          controller={controller}
          scroll={{ x: true }}
          onChange={onChange}
          onPageChange={onPageChange}
        />
      </LayoutBody>
    </Layout>
  );
});

export default ClaimsOverview;
