import { FormItem } from '@retail/backoffice-ui';
import { Input, InputNumber, InputNumberProps, InputProps } from 'antd';
import { ReactNode } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

import cn from './styles.less';

import { FIELD_LABEL_COL } from '@/constants/common';
import { genericMemo } from '@/utils/react';

const defaultFormat = (value: string | number) => value;
const defaultParse = (value: string | number) => value;

type InputControlledProps<T extends FieldValues> = {
  controllerProps: UseControllerProps<T>;
  label?: ReactNode;
  labelCol?: { span: number };
  required?: boolean;
  disabled?: boolean;
  formItemClassName?: string;
  suffix?: ReactNode;
  parse?: (value: unknown) => string | number;
  format?: (value: unknown) => string | number;
} & (InputProps & InputNumberProps);

const _InputControlled = <T extends FieldValues>({
  required,
  disabled,
  label,
  controllerProps,
  type,
  labelCol,
  suffix,
  formItemClassName,
  format = defaultFormat,
  parse = defaultParse,
  ...restInputProps
}: InputControlledProps<T>) => {
  const { field } = useController(controllerProps);

  return (
    <FormItem
      label={label}
      labelCol={labelCol || FIELD_LABEL_COL}
      required={required}
      disabled={disabled}
      controllerProps={controllerProps}
      className={formItemClassName}
      hidden={restInputProps.hidden}
    >
      <div className={cn.inputWrapper}>
        {type === 'number' ? (
          <InputNumber
            {...field}
            disabled={disabled}
            value={format(field.value)}
            onChange={(value) => field.onChange(parse(value))}
            {...restInputProps}
            decimalSeparator=","
          />
        ) : (
          <Input
            {...field}
            disabled={disabled}
            value={format(field.value)}
            onChange={(value) => field.onChange(parse(value))}
            {...restInputProps}
          />
        )}
        {suffix}
      </div>
    </FormItem>
  );
};

export const InputControlled = genericMemo(_InputControlled);
