// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DJ\\+2ykTqVBzRaKhiNJMyNg\\=\\= {\n  display: grid;\n  grid-template-columns: 250px 1fr;\n  grid-gap: 10px;\n}\n.DJ\\+2ykTqVBzRaKhiNJMyNg\\=\\=:not(:last-child) {\n  margin-bottom: 15px;\n}\n.DJ\\+2ykTqVBzRaKhiNJMyNg\\=\\= .rwO3n54mUZM-pPlKL8EJjg\\=\\= {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n.DJ\\+2ykTqVBzRaKhiNJMyNg\\=\\= .TQEbV2QidDVtHF8g4khESA\\=\\= {\n  margin-bottom: 0;\n  font-size: 16px;\n  font-weight: bold;\n}\n.DJ\\+2ykTqVBzRaKhiNJMyNg\\=\\= .aNiPcKfoV\\+bDYNZ244wrRg\\=\\= {\n  margin-bottom: 0;\n  font-size: 16px;\n}\n.DJ\\+2ykTqVBzRaKhiNJMyNg\\=\\= ._1ClTfLohsvNluraorzfXCA\\=\\= {\n  max-width: 200px;\n  margin-bottom: 0 !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/content/SubClaimModal/CostsField/styles.local.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gCAAA;EACA,cAAA;AACF;AACE;EACE,mBAAA;AACJ;AAPA;EAUI,aAAA;EACA,sBAAA;EACA,uBAAA;AAAJ;AAZA;EAgBI,gBAAA;EAEA,eAAA;EACA,iBAAA;AAFJ;AAjBA;EAuBI,gBAAA;EAEA,eAAA;AAJJ;AArBA;EA6BI,gBAAA;EACA,2BAAA;AALJ","sourcesContent":[".costsField {\n  display: grid;\n  grid-template-columns: 250px 1fr;\n  grid-gap: 10px;\n\n  &:not(:last-child) {\n    margin-bottom: 15px;\n  }\n\n  .labelWrap {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n  }\n\n  .number {\n    margin-bottom: 0;\n\n    font-size: 16px;\n    font-weight: bold;\n  }\n\n  .title {\n    margin-bottom: 0;\n\n    font-size: 16px;\n  }\n\n  .field {\n    max-width: 200px;\n    margin-bottom: 0 !important;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"costsField": "DJ+2ykTqVBzRaKhiNJMyNg==",
	"labelWrap": "rwO3n54mUZM-pPlKL8EJjg==",
	"number": "TQEbV2QidDVtHF8g4khESA==",
	"title": "aNiPcKfoV+bDYNZ244wrRg==",
	"field": "_1ClTfLohsvNluraorzfXCA=="
};
export default ___CSS_LOADER_EXPORT___;
