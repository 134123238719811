import classNames from 'classnames';
import { ReactNode } from 'react';

import cn from './styles.less';

import { FALLBACK_TEXT } from '@/constants/common';

interface Props {
  label?: ReactNode;
  value?: ReactNode;
  className?: string;
  qaSelector?: string;
  qaSelectorValue?: string;
  fallback?: ReactNode;
}

export function RowInfo({
  className,
  label,
  value,
  qaSelector,
  qaSelectorValue,
  fallback = FALLBACK_TEXT,
}: Props) {
  return (
    <section
      data-qa-selector={qaSelector}
      className={classNames(className, cn.content)}
    >
      <div>{label}:</div>{' '}
      <div>
        <strong data-qa-selector-value={qaSelectorValue}>
          {value || fallback}
        </strong>
      </div>
    </section>
  );
}
