import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';

import useActiveClaimsTab from '../../../hooks/useActiveClaimsTab';

import {
  ORDER_CLAIMS_DETAILS,
  ORDER_CLAIMS_DETAILS_WORKFLOW,
  ORDER_CLAIMS_DETAILS_CHANGELOG,
} from '@/routes';

const { TabPane } = Tabs;

export const NavigationTabs = () => {
  const { t } = useTranslation();
  const { language, orderId, claimId } = useParams();
  const navigate = useNavigate();
  const activeTabKey = useActiveClaimsTab();

  const handleTabClick = useCallback(
    (key) => {
      navigate(
        `${ORDER_CLAIMS_DETAILS.LINK({ language, orderId, claimId })}${key}`,
      );
    },
    [claimId, language, navigate, orderId],
  );

  return (
    <Tabs type="card" activeKey={activeTabKey} onTabClick={handleTabClick}>
      <TabPane
        tab={t('bo.orderClaims.tabs.workflow')}
        key={ORDER_CLAIMS_DETAILS_WORKFLOW.ROUTE}
      />
      <TabPane
        tab={t('bo.orderClaims.tabs.changeLog')}
        key={ORDER_CLAIMS_DETAILS_CHANGELOG.ROUTE}
      />
    </Tabs>
  );
};
