import {
  createFilter,
  createFilterPayload,
  createSort,
  FilterType,
  SortType,
} from '@retail/gql-utils';
import { useCallback } from 'react';

import { client } from '@/apollo/gql-client';
import { SearchRetailClaimsDocument } from '@/apollo/gql-types';
import { MAX_PAGE_SIZE } from '@/constants/common';

export function useSearchRetailClaimsDocument() {
  return useCallback(
    async ({ claimId, orderId }: { claimId: string; orderId: string }) => {
      await client.query({
        query: SearchRetailClaimsDocument,
        fetchPolicy: 'network-only',
        variables: {
          search: createFilterPayload({
            sorts: [createSort('createdOn', SortType.DESC)],
            filter: createFilter(null, FilterType.AND, [
              createFilter('id', FilterType.EQUAL, claimId),
              createFilter('orderId', FilterType.EQUAL, orderId),
            ]),
            pageSize: MAX_PAGE_SIZE,
          }),
        },
      });
    },
    [],
  );
}
