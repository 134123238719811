import cns from 'classnames';
import React, { Dispatch, PropsWithChildren, useCallback } from 'react';

import { useDocumentViewerManagerContext } from '../Context';

import cn from './styles.less';

import { useSaveVisitingLogMutation } from '@/apollo/gql-types';
import { LogEventType } from '@/enums/logEventTypes';
import { useChangeLogDetails } from '@/hooks/useChangeLogDetails';

interface Props {
  targetUrl: string;
  tag?: keyof Pick<React.ReactHTML, 'a'>;
  className?: string;
  style?: React.CSSProperties;
}

export function DocumentViewerLink({
  tag: Tag = 'a',
  targetUrl,
  className,
  style,
  children,
  ...props
}: PropsWithChildren<Props>) {
  const changeLogDetails = useChangeLogDetails();
  const [saveLog] = useSaveVisitingLogMutation();
  const { open } = useDocumentViewerManagerContext();

  const handleClick = useCallback(
    (open: Dispatch<string>) => () => {
      saveLog({
        variables: {
          model: {
            eventType: LogEventType.DOCUMENT,
            eventSource: targetUrl,
            details: {
              pageUrl: window.location.href,
              ...changeLogDetails,
            },
          },
        },
      });

      open(targetUrl);
    },
    [saveLog, targetUrl, changeLogDetails],
  );

  return (
    <Tag
      {...props}
      className={cns(className, cn.link)}
      style={style}
      onClick={handleClick(open)}
    >
      {children}
    </Tag>
  );
}
