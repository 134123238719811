import { FormItem } from '@retail/backoffice-ui';
import 'antd/es/date-picker/style/index';
import generatePicker, {
  RangePickerDateProps,
} from 'antd/lib/date-picker/generatePicker';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { memo, ReactNode, useCallback } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { FIELD_LABEL_COL } from '@/constants/common';

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

const { RangePicker } = DatePicker;

type InputControlledProps<T extends FieldValues> = {
  controllerProps: UseControllerProps<T>;
  dateFormat: string;
  label?: ReactNode;
  labelCol?: { span: number };
  required?: boolean;
  disabled?: boolean;
} & RangePickerDateProps<Date>;

export const DatePickerRangeControlled = memo<
  InputControlledProps<FieldValues>
>(
  ({
    required,
    disabled,
    label,
    labelCol,
    controllerProps,
    dateFormat,
    ...restInputProps
  }) => {
    const { field } = useController(controllerProps);
    const handleChange = useCallback(
      (values) => field.onChange(values),
      [field],
    );

    return (
      <FormItem
        label={label}
        labelCol={labelCol || FIELD_LABEL_COL}
        required={required}
        disabled={disabled}
        controllerProps={controllerProps}
      >
        <RangePicker
          {...field}
          value={field.value}
          format={dateFormat}
          onChange={handleChange}
          popupStyle={{ zIndex: 9999 }}
          {...restInputProps}
        />
      </FormItem>
    );
  },
);
