import { ApolloProvider } from '@apollo/client';
import { useDevAuthToken } from '@retail/dev-auth';
import { ConfigProvider, notification } from 'antd';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { client } from './apollo/gql-client';
import routes from './routes';

import { ANTD_EXTERNAL_VERSION_LESS } from '@/constants/antd';
import { UserProvider } from '@/providers/UserProvider';

import './i18n';

notification.config({
  top: 55,
  prefixCls: `${ANTD_EXTERNAL_VERSION_LESS}-notification`,
});

export default function Root() {
  useDevAuthToken();

  return (
    <div id="claims">
      <ConfigProvider prefixCls={ANTD_EXTERNAL_VERSION_LESS}>
        <BrowserRouter>
          <ApolloProvider client={client}>
            <UserProvider>
              <Routes>
                {routes.map(({ path, element }) => (
                  <Route key={path} path={path} element={element} />
                ))}
              </Routes>
            </UserProvider>
          </ApolloProvider>
        </BrowserRouter>
      </ConfigProvider>
    </div>
  );
}
