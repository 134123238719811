import {
  createFilter,
  createFilterPayload,
  createSort,
  FilterType,
  SortType,
} from '@retail/gql-utils';
import { notification } from 'antd';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ClaimCreateModal } from './claim-create-modal';
import { ClaimsList } from './ClaimsList';
import { GeneralInfo } from './GeneralInfo';
import { ClaimsOverview } from './overview';
import { claimSelector } from './selectors';
import cn from './styles.less';
import { SubclaimCreateCommentAndDocumentsModal } from './SubclaimCreateCommentAndDocumentsModal';
import { SubClaimCreateModal } from './SubClaimCreateModal';
import { SubclaimEditGroupModal } from './SubclaimEditGroupModal';
import { SubclaimEditModal } from './SubclaimEditModal';

import {
  useClaimOrderDetailsQuery,
  useLoadLinkedOrdersQuery,
  useRetailClaimGroupsOptionsQuery,
  useSearchSimpleRetailClaimsQuery,
} from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';
import { MAX_PAGE_SIZE } from '@/constants/common';
import { FEATURE } from '@/constants/features';
import { DOMAINS } from '@/constants/permissions';
import { FeaturesProvider } from '@/providers/FeaturesProvider';

notification.config({
  top: 55,
});

const Claims = () => {
  const { orderId, language } = useParams();

  const { data: orderData, loading: orderLoading } = useClaimOrderDetailsQuery({
    ...REQUEST_BASE_OPTIONS,
    errorPolicy: 'all',
    variables: {
      orderId,
    },
  });

  const { data: claimsData, loading: claimsLoading } =
    useSearchSimpleRetailClaimsQuery({
      ...REQUEST_BASE_OPTIONS,
      variables: {
        search: createFilterPayload({
          sorts: [createSort('createdOn', SortType.DESC)],
          filter: createFilter('orderId', FilterType.EQUAL, orderId),
          pageSize: MAX_PAGE_SIZE,
        }),
      },
    });

  const { data: claimGroupsData, loading: claimGroupsLoading } =
    useRetailClaimGroupsOptionsQuery(REQUEST_BASE_OPTIONS);

  const linkedOrders = orderData?.order?.retailOrderLinks?.map(
    ({ refId }) => refId,
  );

  const { data: ordersLinkedData, loading: ordersLinkedLoading } =
    useLoadLinkedOrdersQuery({
      skip: !linkedOrders?.length,
      context: { headers: { 'access-domain': DOMAINS.ORDER_MANAGEMENT } },
      variables: {
        search: createFilterPayload({
          filter: createFilter('id', FilterType.IN, linkedOrders),
          page: 0,
          pageSize: 100,
        }),
      },
    });

  const claimOrderDetails = useMemo(() => {
    const data = claimSelector({
      order: orderData,
      claims: claimsData,
      linkedOrders: ordersLinkedData,
    });

    return data;
  }, [claimsData, orderData, ordersLinkedData]);

  return (
    <FeaturesProvider
      names={[
        FEATURE.RETAIL_CLAIM_COST_PROCESSING,
        FEATURE.RETAIL_CLAIM_CLOSED_REASON_BACKOFFICE,
      ]}
      country={orderData?.order?.retailCountry}
    >
      <div className={cn.page}>
        <ClaimsOverview
          claims={claimsData}
          claimOrderDetails={claimOrderDetails}
          claimGroups={claimGroupsData}
          loading={claimsLoading || claimGroupsLoading}
        />
        <div>
          <ClaimsList
            claims={claimsData}
            claimGroups={claimGroupsData}
            loading={claimsLoading || claimGroupsLoading}
          />
          <GeneralInfo
            loading={orderLoading || claimsLoading || ordersLinkedLoading}
            data={claimOrderDetails}
          />
          <SubclaimEditModal claimGroups={claimGroupsData} />
          <SubclaimCreateCommentAndDocumentsModal
            retailCountry={orderData?.order?.retailCountry}
          />
          <SubClaimCreateModal
            retailCountry={orderData?.order?.retailCountry}
            claimGroups={claimGroupsData}
          />
          <ClaimCreateModal
            claimGroups={claimGroupsData}
            orderId={orderData?.order?.id}
            language={language}
            retailCountry={orderData?.order?.retailCountry}
          />
          <SubclaimEditGroupModal claimGroups={claimGroupsData} />
        </div>
      </div>
    </FeaturesProvider>
  );
};

export default Claims;
