import { List } from 'antd';
import React, { PropsWithChildren } from 'react';
import { ListItemProps } from 'antd/lib/list/Item';
import cns from 'classnames';

import cn from './styles.less';

interface Props extends ListItemProps {
  className?: string;
}

export function ListItem({
  className,
  children,
  ...rest
}: PropsWithChildren<Props>) {
  return (
    <List.Item {...rest} className={cns(className, cn.item)}>
      {children}
    </List.Item>
  );
}
