import { flow, get, head } from 'lodash/fp';
import { useCallback } from 'react';

import { client } from '@/apollo/gql-client';
import { DocumentCategoriesDocument } from '@/apollo/gql-types';

export function useDocumentTypeId(retailCountry: string) {
  return useCallback(async () => {
    const documentCategoryData = await client.query({
      query: DocumentCategoriesDocument,
      variables: {
        country: retailCountry,
        topCategory: 'SUB_CLAIM',
      },
    });

    return flow(
      get('data.documentCategories.categories'),
      head,
      get('documentTypes'),
      head,
      get('id'),
    )(documentCategoryData);
  }, [retailCountry]);
}
