import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';

import {
  RetailClaimStateDueDaysConfigProjection,
  useUpdateClaimStateDueDaysConfigMutation,
} from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';
import { DOMAINS, PERMISSIONS } from '@/constants/permissions';

interface CallbackProps {
  entities: RetailClaimStateDueDaysConfigProjection[];
}

interface Props {
  onCancel: () => void;
}

export function useSubmit({ onCancel }: Props) {
  const [update, { loading }] =
    useUpdateClaimStateDueDaysConfigMutation(REQUEST_BASE_OPTIONS);

  const { isDenied } = useCheckPermissions({
    allow: PERMISSIONS.UPDATE_CLAIMS_STATE_DUE_DAYS_CONFIG,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });

  async function onSubmit({ entities }: CallbackProps) {
    if (isDenied) {
      return;
    }

    await update({
      refetchQueries: ['ClaimStateDueDaysConfig'],
      variables: {
        input: {
          configUpdates: entities.map(({ id, dueDays }) => ({ id, dueDays })),
        },
      },
    });

    onCancel();
  }

  return {
    loading,
    isDenied,
    onSubmit,
  };
}
