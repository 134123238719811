// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ll\\+I\\+LsypQQJAm9OA8CdmA\\=\\= {\n  display: flex;\n  flex-direction: column;\n}\n@media screen and (min-width: 1600px) {\n  .ll\\+I\\+LsypQQJAm9OA8CdmA\\=\\= {\n    flex-direction: row;\n    gap: 0.25rem;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/RowInfo/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EAAA;IACE,mBAAA;IACA,YAAA;EAEF;AACF","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n\n  @media screen and (min-width: 1600px) {\n    flex-direction: row;\n    gap: 0.25rem;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "ll+I+LsypQQJAm9OA8CdmA=="
};
export default ___CSS_LOADER_EXPORT___;
