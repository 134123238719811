import { useReactiveVar } from '@apollo/client';
import { Modal, notification } from 'antd';
import { get } from 'lodash/fp';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CostForm } from './form';
import { hideModal, manageCostModal } from './manage-cost-modal';
import cn from './styles.less';
import { CostFormValues } from './types';

import {
  useCreateRetailClaimCostMutation,
  useUpdateRetailClaimCostMutation,
} from '@/apollo/gql-types';

export const CostModal = memo(() => {
  const { visible, data } = useReactiveVar(manageCostModal);
  const { t } = useTranslation();
  const { id, cost, currencyCode } = data ?? {};

  const initialValues: CostFormValues = useMemo(
    () => ({
      warranty: cost?.warrantyCoverageType || null,
      cost: cost?.costMinorUnits,
      costType: cost?.costType || null,
      orderItemId: cost?.orderItemId,
    }),
    [cost],
  );

  const {
    handleSubmit,
    control,
    reset,
    setError,
    trigger,
    watch,
    formState: { errors, isSubmitted },
    setValue,
  } = useForm<typeof initialValues>({
    defaultValues: initialValues,
    mode: 'all',
  });
  const orderItemId = watch('orderItemId');
  useEffect(() => {
    if (errors.cost) {
      trigger('cost');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItemId]);

  useEffect(() => reset(initialValues), [initialValues, reset]);

  const [create, { loading: createCostLoading }] =
    useCreateRetailClaimCostMutation({
      refetchQueries: ['SearchRetailClaims'],
      onError: (error) => {
        setError('cost', {
          type: 'cost',
          message: get('0.message')(error?.graphQLErrors),
        });
      },
      onCompleted: () => {
        hideModal();
        notification.success({
          message: 'Saved successfully',
        });
      },
    });

  const [update, { loading: updateCostLoading }] =
    useUpdateRetailClaimCostMutation({
      refetchQueries: ['SearchRetailClaims'],
      onError: (error) => {
        setError('cost', {
          type: 'cost',
          message: get('0.message')(error?.graphQLErrors),
        });
      },
      onCompleted: () => {
        hideModal();
        notification.success({
          message: 'Updated successfully',
        });
      },
    });

  const onSubmit = useCallback(
    async (claimCost) => {
      if (!cost?.id) {
        await create({
          variables: {
            claimId: id,
            claimCost,
          },
        });
      } else {
        await update({
          variables: {
            costId: cost?.id,
            claimCostUpdate: claimCost,
          },
        });
      }
    },
    [cost?.id, create, id, update],
  );

  return (
    <Modal
      centered
      width={600}
      visible={visible}
      destroyOnClose
      onOk={handleSubmit(onSubmit)}
      title={t('bo.orderClaims.processing.popUp.addCost.title')}
      onCancel={hideModal}
      okButtonProps={{
        loading: createCostLoading || updateCostLoading,
      }}
      cancelText={t('bo.orderClaims.processing.popUp.addCost.discard.cta')}
      okText={
        <span data-qa-selector="submitButton">
          {t('bo.orderClaims.processing.popUp.addCost.save.cta')}
        </span>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)} className={cn.form}>
        <CostForm
          setValue={setValue}
          control={control}
          errors={{ ...errors }}
          isSubmitted={isSubmitted}
          currencyCode={currencyCode}
        />
      </form>
    </Modal>
  );
});
