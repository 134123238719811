// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JYxD9YcgaKfKU7eePkCu-Q\\=\\= {\n  display: flex;\n  justify-content: end;\n  align-items: end;\n  height: 100%;\n  gap: 16px;\n  padding-top: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClaimsOverview/SearchForm/styles.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,oBAAA;EACA,gBAAA;EACA,YAAA;EACA,SAAA;EACA,iBAAA;AADF","sourcesContent":["@import '../../../styles/constants.less';\n\n.btn {\n  display: flex;\n  justify-content: end;\n  align-items: end;\n  height: 100%;\n  gap: 16px;\n  padding-top: 16px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "JYxD9YcgaKfKU7eePkCu-Q=="
};
export default ___CSS_LOADER_EXPORT___;
