import { Spin } from 'antd';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';

import Toolbar from '../Toolbar';
import { ViewerDocumentModel } from '../types';
import { getShrinkSize, polyfillNaturalSize } from '../utils';

import cn from './styles.less';

interface Props {
  document: ViewerDocumentModel;
}

const DocumentViewerImage = ({ document }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState<HTMLImageElement>(null);
  const [style, setStyle] = useState<CSSProperties>({
    visibility: 'hidden',
  });
  const imgRef = useRef(null);

  useEffect(() => {
    if (document.fullUrl) {
      setStyle({ visibility: 'hidden' });
      setImage(null);
      setIsLoading(true);
    }
  }, [document]);

  const setImageStyle = (newStyles: CSSProperties) =>
    setStyle((prevState) => ({ ...prevState, ...newStyles }));

  const handleLoad = useCallback(
    (event) => {
      if (event.target && image === null) {
        const image = polyfillNaturalSize(event.target);

        setStyle((prevState) => ({
          ...prevState,
          ...getShrinkSize(image),
          visibility: 'visible',
        }));
        setIsLoading(false);
        setImage(image);
      }
    },
    [image],
  );

  useEffect(() => {
    if (imgRef.current?.complete && image === null) {
      handleLoad({ target: imgRef.current });
    }
  }, [handleLoad, image, imgRef]);

  return (
    <div className={cn.imageWrapper}>
      {isLoading && <Spin className={cn.spinner} />}
      <img
        ref={imgRef}
        key={document.fullUrl}
        className={cn.image}
        style={style}
        alt={document.fullUrl}
        onLoad={handleLoad}
        src={document.fullUrl}
      />
      <Toolbar image={image} setStyle={setImageStyle} />
    </div>
  );
};

export default DocumentViewerImage;
