// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9Q\\+vfIoApvEU\\+c3aRJ7ENA\\=\\= {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n}\n._9Q\\+vfIoApvEU\\+c3aRJ7ENA\\=\\= > strong {\n  line-height: 0px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/GeneralInfo/OrderAsideInfo/OrderSummary/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACF;AACE;EACE,gBAAA;AACJ","sourcesContent":[".retailCountry {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n\n  &>strong {\n    line-height: 0px;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"retailCountry": "_9Q+vfIoApvEU+c3aRJ7ENA=="
};
export default ___CSS_LOADER_EXPORT___;
