import { EditOutlined } from '@ant-design/icons';
import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';
import { Button } from 'antd';

import cn from './styles.less';

import { ClaimDueDate } from '@/components/ClaimDueDate';
import { FALLBACK_TEXT } from '@/constants/common';
import { DOMAINS, PERMISSIONS } from '@/constants/permissions';
import { formatDate } from '@/helpers/date';
import { useActive } from '@/hooks/useActive';
import { CLAIM_STATES } from '@/pages/claims/constants';

const REFETCH_QUERIES = ['SearchOrderRetailClaims'];

interface Props {
  claimId: string;
  stateDueDate: string;
  state: string;
}

export function DueDateColumn({ claimId, stateDueDate, state }: Props) {
  const { active, setActive, setInactive } = useActive();
  const { isAllowed } = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_UPDATE_CLAIM_STATE_DUE_DATE,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });

  return state === CLAIM_STATES.CLOSED ? (
    <>{FALLBACK_TEXT}</>
  ) : (
    <div className={cn.form}>
      {active ? (
        <ClaimDueDate
          autoFocus
          claimId={claimId}
          defaultValue={stateDueDate}
          refetchQueries={REFETCH_QUERIES}
          onSuccess={setInactive}
          size="small"
        />
      ) : (
        <div className={cn.block}>
          <span>{stateDueDate ? `${formatDate(stateDueDate)} ` : null}</span>
          {isAllowed ? (
            <Button type="text" size="small" onClick={setActive}>
              <EditOutlined />
            </Button>
          ) : null}
        </div>
      )}
    </div>
  );
}
