import { notification } from 'antd';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCreateSubclaimDocuments } from '../hooks/useCreateSubclaimDocuments';
import { useSearchRetailClaimsDocument } from '../hooks/useSearchRetailClaimsDocument';

import { useSearchSimpleRetailClaimsDocument } from './useSearchSimpleRetailClaimsDocument';

import {
  RetailClaimCreateProjectionV2Input,
  RetailSubClaimCreateProjectionInput,
  useCreateRetailClaimV2Mutation,
} from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';
import { ORDER_CLAIMS_DETAILS } from '@/routes';

interface CreateClaimArg {
  orderId: string;
  language: string;
  retailCountry: string;
}

export interface CreateClaimPayloadArg
  extends Pick<RetailClaimCreateProjectionV2Input, 'assignTo' | 'claimGroupId'>,
    RetailSubClaimCreateProjectionInput {
  newDocuments?: File[];
}

export function useCreateClaimV2({
  orderId,
  language,
  retailCountry,
}: CreateClaimArg) {
  const createDocuments = useCreateSubclaimDocuments({ retailCountry });
  const refetchClaims = useSearchRetailClaimsDocument();
  const refetchSimpleRetailClaimsDocument =
    useSearchSimpleRetailClaimsDocument();
  const navigate = useNavigate();
  const [createClaim] = useCreateRetailClaimV2Mutation(REQUEST_BASE_OPTIONS);

  return useCallback(
    async ({
      assignTo,
      claimGroupId,
      newDocuments,
      areaId,
      carPartId,
      damageId,
      liablePartyType,
      comment,
    }: CreateClaimPayloadArg) => {
      const subClaim: RetailSubClaimCreateProjectionInput = {
        areaId,
        carPartId,
        damageId,
        liablePartyType,
        comment,
      };

      const { data } = await createClaim({
        variables: {
          orderId,
          claimCreate: {
            assignTo,
            claimGroupId,
            subClaim,
          },
        },
      });

      if (!data) {
        return null;
      }

      const {
        createRetailClaimV2: {
          id: claimId,
          subClaims: [{ id: subClaimId }],
        },
      } = data;

      await createDocuments({ subClaimId, newDocuments });
      await refetchClaims({ orderId, claimId });
      await refetchSimpleRetailClaimsDocument(orderId);

      notification.success({
        message: 'Claim created successfully.',
      });

      await navigate(ORDER_CLAIMS_DETAILS.LINK({ language, orderId, claimId }));
    },
    [
      createClaim,
      orderId,
      createDocuments,
      refetchClaims,
      refetchSimpleRetailClaimsDocument,
      navigate,
      language,
    ],
  );
}
