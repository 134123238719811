import { Card as AntdCard, CardProps } from 'antd';

export function Card({ headStyle, ...props }: CardProps) {
  return (
    <AntdCard
      {...props}
      headStyle={{
        background: '#f5f5f5',
        fontWeight: 'normal',
        ...headStyle,
      }}
    />
  );
}
