import { useParams } from 'react-router-dom';

import { LogProjectionInput } from '@/apollo/gql-types';

export function useChangeLogDetails(): LogProjectionInput['details'] {
  const {
    adId,
    userId,
    groupId,
    roleId,
    orderId,
    claimId,
    modal,
    packageId,
    currentStatus,
    leadId,
    id,
    retailAdId,
    refurbishmentType,
  } = useParams();

  return {
    adId,
    userId,
    groupId,
    roleId,
    orderId,
    claimId,
    modal,
    packageId,
    currentStatus,
    leadId,
    id,
    retailAdId,
    refurbishmentType,
  };
}
