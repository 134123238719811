import { ControlledPositiveIntegerInput } from '@retail/backoffice-ui/src/ControlledPositiveIntegerInput';
import { TableColumnType } from 'antd';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DueDaysConfigButtonFormModalValuesModel } from '../FormModal/types';

import { RetailClaimStateDueDaysConfigProjection } from '@/apollo/gql-types';
import { InputControlled } from '@/components/form/input';

interface Props {
  control: Control<DueDaysConfigButtonFormModalValuesModel>;
  isDenied: boolean;
}

export function useColumns({
  control,
  isDenied,
}: Props): TableColumnType<RetailClaimStateDueDaysConfigProjection>[] {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        title: <strong>{t('bo.claimsOverview.claimStatus')}</strong>,
        dataIndex: 'claimState',
        key: 'claimState',
        render: (_value, _record, index) => (
          <InputControlled
            disabled
            labelCol={{ span: 24 }}
            data-qa-selector="claimStatus"
            placeholder={t('bo.claims.selectPlaceholder')}
            controllerProps={{
              name: `entities.${index}.claimState`,
              control,
            }}
          />
        ),
      },
      {
        title: <strong>{t('bo.claimsOverview.dueDays.title')}</strong>,
        dataIndex: 'dueDays',
        key: 'dueDays',
        render: (_value, _record, index) => (
          <ControlledPositiveIntegerInput
            required
            labelCol={{ span: 24 }}
            disabled={isDenied}
            data-qa-selector="dueDays"
            parse={parseInt}
            controllerProps={{
              name: `entities.${index}.dueDays`,
              control,
            }}
          />
        ),
      },
    ],
    [control, isDenied, t],
  );
}
