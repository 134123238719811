import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { RowInfo } from '@/components/RowInfo';
import { FALLBACK_NA } from '@/constants/common';
import { i18nDetectLng } from '@/i18n/utils';
import * as ROUTES from '@/routes';

const { Text, Link } = Typography;

interface Props {
  adId?: string;
  stockNumber?: string;
}

export function StockNumber({ adId, stockNumber }: Props) {
  const { t } = useTranslation();
  const label = stockNumber ?? FALLBACK_NA;

  return (
    <RowInfo
      label={t('bo.claimsOverview.search.stockNumber.title')}
      value={
        adId ? (
          <Link
            copyable
            data-qa-selector="stock-number"
            href={ROUTES.AD_ITEM.LINK({ language: i18nDetectLng(), adId })}
          >
            {label}
          </Link>
        ) : (
          <Text data-qa-selector="stock-number" copyable>
            {label}
          </Text>
        )
      }
    />
  );
}
