import { TableColumnsType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';

export function useTableColumns() {
  const { t } = useTranslation();

  return useMemo(
    (): TableColumnsType => [
      {
        title: t('bo.orderClaims.changeLog.search.label.dateTime'),
        dataIndex: 'generalData',
        key: 'generalData',
        width: '160px',
        render: ({ createdOn, user, operation }) => (
          <span className={cn.generalDataCell}>
            <span className={cn.date} data-qa-selector="createdOn">
              {createdOn}
            </span>
            <span className={cn.user} data-qa-selector="user">
              {user}
            </span>
            <i className={cn.operation} data-qa-selector="operation">
              {operation}
            </i>
          </span>
        ),
      },
      {
        title: t('bo.orderClaims.changeLog.table.column.object'),
        dataIndex: 'objectData',
        key: 'objectData',
        width: '150px',
        render: ({ objectType, objectId }) => (
          <span className={cn.objectDataCell}>
            <strong className={cn.textBold} data-qa-selector="objectType">
              {objectType}
            </strong>
            <span data-qa-selector="objectId">{objectId}</span>
          </span>
        ),
      },
      {
        title: t('bo.orderClaims.changeLog.table.column.field'),
        dataIndex: 'field',
        key: 'field',
        width: '180px',
        render: (field) => <span data-qa-selector="field">{field}</span>,
      },
      {
        title: (
          <span>
            <strong className={cn.textBold}>
              {t('bo.orderClaims.changeLog.table.from')}
            </strong>
            <i className={cn.textDefault}>
              {` (${t('bo.orderClaims.changeLog.table.oldValue')})`}
            </i>
          </span>
        ),
        dataIndex: 'oldValue',
        key: 'oldValue',
        width: '200px',
        render: ({ defaultValue, formattedValue }) => (
          <span
            data-qa-selector="oldValue"
            data-qa-selector-default-value={defaultValue}
          >
            {formattedValue}
          </span>
        ),
      },
      {
        title: (
          <span>
            <strong className={cn.textBold}>
              {t('bo.orderClaims.changeLog.table.to')}
            </strong>
            <i className={cn.textDefault}>
              {` (${t('bo.orderClaims.changeLog.table.newValue')})`}
            </i>
          </span>
        ),
        dataIndex: 'newValue',
        key: 'newValue',
        render: ({ defaultValue, formattedValue }) => (
          <span
            data-qa-selector="newValue"
            data-qa-selector-default-value={defaultValue}
          >
            {formattedValue}
          </span>
        ),
      },
    ],
    [t],
  );
}
