// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2MIFsoRgymw9dAMI2Vxu\\+g\\=\\= {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  position: relative;\n}\n.ZsQsMKO79KwtHo31lrAJXw\\=\\= {\n  flex-shrink: 0;\n  margin: auto;\n}\n.YiKlJ7AdeH\\+DWMCPowCf0w\\=\\= {\n  height: 10%;\n  max-height: 35px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n", "",{"version":3,"sources":["webpack://./src/components/DocumentViewer/Image/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,WAAA;EACA,kBAAA;AACF;AAEA;EACE,cAAA;EAEA,YAAA;AADF;AAIA;EACE,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AAFF","sourcesContent":[".imageWrapper {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  position: relative;\n}\n\n.image {\n  flex-shrink: 0;\n\n  margin: auto;\n}\n\n.spinner {\n  height: 10%;\n  max-height: 35px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageWrapper": "_2MIFsoRgymw9dAMI2Vxu+g==",
	"image": "ZsQsMKO79KwtHo31lrAJXw==",
	"spinner": "YiKlJ7AdeH+DWMCPowCf0w=="
};
export default ___CSS_LOADER_EXPORT___;
