import { replace } from 'lodash';

import PackageJson from '../../package.json';

// antd-4.21.7
export const ANTD_EXTERNAL_VERSION = `antd-${PackageJson.dependencies.antd}`;
// antd-4-21-7
export const ANTD_EXTERNAL_VERSION_LESS = replace(
  ANTD_EXTERNAL_VERSION,
  /\./g,
  '-',
);
