import { makeVar, useReactiveVar } from '@apollo/client';
import { Modal } from 'antd';
import { memo, useCallback, useEffect } from 'react';
import { FieldValues, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Entity } from '../../selectors';

import { SubClaimForm } from './SubClaimForm';
import { useModalFormProps } from './useModalFormProps';
import { useSubclaimCostMetadata } from './useSubclaimCostMetadata';
import { useUpdateSubclaimCosts } from './useUpdateSubclaimCosts';

export const manageSubClaimModal = makeVar(false);

export const hideModal = () => manageSubClaimModal(false);
export const showModal = () => manageSubClaimModal(true);

interface SubClaimModalProps {
  claim: Entity;
}

export const SubClaimModal = memo<SubClaimModalProps>(({ claim }) => {
  const visible = useReactiveVar(manageSubClaimModal);
  const { t } = useTranslation();
  const subClaimsFieldData = useSubclaimCostMetadata(claim);

  const { onChange, currencyCode, initialValues } = useModalFormProps(claim);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: initialValues,
    mode: 'all',
  });

  const createSubclaimCosts = useUpdateSubclaimCosts(setError, hideModal);
  const claimCostDistributions = useWatch({
    control,
    name: 'claimCostDistributions',
  });

  useEffect(() => {
    setValue('total', onChange({ claimCostDistributions }));
  }, [setValue, claimCostDistributions, onChange]);

  useEffect(() => setError('total', null), [setError, claimCostDistributions]);

  useEffect(() => reset(initialValues), [initialValues, reset]);

  const onSubmit = useCallback(
    async ({ claimId, claimCostDistributions }) => {
      await createSubclaimCosts({
        variables: {
          claimId,
          claimCostDistributions,
        },
      });
    },
    [createSubclaimCosts],
  );

  return (
    <Modal
      centered
      width={600}
      visible={visible}
      onOk={handleSubmit(onSubmit)}
      okText={t('bo.orderClaims.processing.popUp.subclaimCosts.save.cta')}
      cancelText={t(
        'bo.orderClaims.processing.popUp.subclaimCosts.discard.cta',
      )}
      onCancel={() => {
        reset(initialValues);
        hideModal();
      }}
      title={
        <strong
          className="text-uppercase"
          data-qa-selector="subclaim-costs-title"
        >
          {t('bo.orderClaims.processing.popUp.subclaimCosts.title')}
        </strong>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <SubClaimForm
          control={control}
          currencyCode={currencyCode}
          subClaimsFieldData={subClaimsFieldData}
          errors={errors}
        />
      </form>
    </Modal>
  );
});
