import { CurrencyCodes } from '@retail/currency';
import { ColumnsType } from 'antd/lib/table';
import { find, get, propEq } from 'lodash/fp';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  CLAIM_STATES,
  COST_TYPE_TRANSLATIONS,
  WARRANTY_OPTIONS,
} from '../../../../constants';
import Actions from '../actions';
import { Approval, getLevelComments } from '../approval';
import { getOrderItemName } from '../cost-modal/constants';
import { useOrderItems } from '../cost-modal/useOrderItems';
import { CustomerDecision } from '../customer-decision';
import { Price } from '../price';

import useRequiredApprovalLevel from './useRequiredApprovalLevel';

import { RetailClaimCostProjection } from '@/apollo/gql-types';

interface IUseColumnsProps {
  orderClaimStatus: CLAIM_STATES;
}

const useTableColumns = ({ orderClaimStatus }: IUseColumnsProps) => {
  const { t } = useTranslation();

  const getRequiredApprovalLevelData = useRequiredApprovalLevel();

  const { orderId } = useParams();
  const orderItems = useOrderItems(orderId);

  return useMemo<ColumnsType<RetailClaimCostProjection>>(
    () => [
      {
        title: (
          <strong>
            {t('bo.orderClaims.processing.table.claimCostTypeNumber.title')}
          </strong>
        ),
        dataIndex: 'number',
        key: 'number',
        width: 120,
        render: (number) => (
          <span data-qa-selector="claim-cost-id">{number}</span>
        ),
      },
      {
        title: (
          <strong>{t('bo.orderClaims.processing.table.costType.title')}</strong>
        ),
        dataIndex: 'costType',
        key: 'costType',
        width: 150,
        render: (costType, { orderItemId }) => {
          const selectedOrderItem =
            orderItemId && orderItems
              ? orderItems
                  .filter(propEq('id', orderItemId))
                  .map(getOrderItemName)
                  .at(0)
              : null;

          const selectedOrderItemText = selectedOrderItem ? (
            <>
              <br />(
              {t(
                'bo.orderClaims.processing.table.costType.selectedOrderItem',
                'Selected order item:',
              )}{' '}
              {selectedOrderItem})
            </>
          ) : null;
          return (
            <span data-qa-selector="cell-costType">
              {t(COST_TYPE_TRANSLATIONS[costType])}
              {selectedOrderItemText}
            </span>
          );
        },
      },
      {
        title: (
          <strong>{t('bo.orderClaims.processing.table.warranty.title')}</strong>
        ),
        dataIndex: 'warrantyCoverageType',
        key: 'warrantyCoverageType',
        width: 140,
        render: (warranty) =>
          warranty ? (
            <div data-qa-selector="cell-warrantyCoverageType">
              {t(get('label', find({ value: warranty }, WARRANTY_OPTIONS)))}
            </div>
          ) : (
            <div data-qa-selector="cell-warrantyCoverageType">
              {t('bo.orderClaims.processing.warrantyType.notCovered')}
            </div>
          ),
      },
      {
        title: (
          <strong>
            {t('bo.orderClaims.processing.table.costAmount.title')}
          </strong>
        ),
        dataIndex: 'costMinorUnits',
        key: 'costMinorUnits',
        render: (_, record) => (
          <Price
            price={{
              currencyCode: record.costCurrencyCode as CurrencyCodes,
              amountMinorUnits: record.costMinorUnits,
            }}
            payment={record.payment}
          />
        ),
      },
      {
        title: (
          <strong>
            {t('bo.orderClaims.processing.table.levelApproval.title')}
          </strong>
        ),
        width: 270,
        dataIndex: 'approvalLevel',
        key: 'approvalLevel',
        render: (_, cost) => {
          const data = getRequiredApprovalLevelData(cost);

          return (
            <Approval
              cost={cost}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              orderClaimsStatus={(cost as any).ap} // there's no such field on RetailClaimCostProjection type
              {...data}
            />
          );
        },
      },
      {
        title: (
          <strong>
            {t('bo.orderClaims.processing.table.customerDecision.title')}
          </strong>
        ),
        dataIndex: 'customerDecision',
        key: 'customerDecision',
        render: (customerDecision, cost) => (
          <CustomerDecision
            cost={cost}
            orderClaimStatus={orderClaimStatus}
            getComments={getLevelComments('customerDecisionComment')}
          />
        ),
      },
      {
        title: (
          <strong>{t('bo.orderClaims.processing.table.actions.title')}</strong>
        ),
        dataIndex: 'actions',
        key: 'id',
        width: 150,
        render: (_, cost) => (
          <Actions cost={cost} orderClaimStatus={orderClaimStatus} />
        ),
      },
    ],
    [t, orderClaimStatus, getRequiredApprovalLevelData, orderItems],
  );
};

export default useTableColumns;
