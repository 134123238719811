import { MileageDto } from '@/apollo/gql-types';

const FORMATTER = new Intl.NumberFormat(process.env.BO_LOCALES, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
  style: 'decimal',
});

export const formatNumber = FORMATTER.format;

export function formatMileage({ distance, unit }: MileageDto = {}) {
  if (!distance || !unit) {
    return null;
  }

  return `${formatNumber(distance)} ${unit.toLowerCase()}`;
}
