import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { RowInfo } from '@/components/RowInfo';
import { FALLBACK_NA } from '@/constants/common';
import { i18nDetectLng } from '@/i18n/utils';
import * as ROUTES from '@/routes';

const { Text, Link } = Typography;

interface Props {
  orderId?: string;
  orderNumber?: string;
  label?: string;
}

const DEFAULT_ORDER_NUMBER_LABEL = 'bo.orderClaims.processing.orderNumber';

export function OrderNumber({ orderId, orderNumber, label }: Props) {
  const { t } = useTranslation();
  const value = orderNumber ?? FALLBACK_NA;

  return (
    <RowInfo
      label={t(label || DEFAULT_ORDER_NUMBER_LABEL)}
      value={
        orderId ? (
          <Link
            copyable
            data-qa-selector="order-number"
            href={ROUTES.ORDER.LINK({ language: i18nDetectLng(), orderId })}
          >
            {value}
          </Link>
        ) : (
          <Text data-qa-selector="order-number" copyable>
            {value}
          </Text>
        )
      }
    />
  );
}
