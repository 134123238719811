// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paEZeZmk5cL2vFiV9eO1wA\\=\\= .antd-4-21-7-modal-body {\n  padding: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/SubclaimEditGroupModal/styles.less"],"names":[],"mappings":"AAEA;EAGM,UAAA;AAHN","sourcesContent":["@import '../../../styles/constants.less';\n\n.modal {\n  :global {\n    .@{antd-version}-modal-body {\n      padding: 0;\n    }\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "paEZeZmk5cL2vFiV9eO1wA=="
};
export default ___CSS_LOADER_EXPORT___;
