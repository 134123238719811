// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._07cwf7w4dCbHTaTzwYMSLw\\=\\= .antd-4-21-7-form-item {\n  margin-bottom: 0 !important;\n}\n._07cwf7w4dCbHTaTzwYMSLw\\=\\= .antd-4-21-7-form-item-control-input {\n  min-height: 24px !important;\n}\n.\\+n2nh6esy75eZDwDGyDGwA\\=\\= {\n  display: flex;\n  justify-content: space-between;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClaimsOverview/DueDate/styles.less"],"names":[],"mappings":"AAIA;EAGM,2BAAA;AALN;AAEA;EAMM,2BAAA;AALN;AAUA;EACE,aAAA;EACA,8BAAA;AARF","sourcesContent":["@import '../../../styles/constants.less';\n\n@heightSmall: 24px;\n\n.form {\n  :global {\n    .@{antd-version}-form-item {\n      margin-bottom: 0 !important;\n    }\n    .@{antd-version}-form-item-control-input {\n      min-height: @heightSmall !important;\n    }\n  }\n}\n\n.block {\n  display: flex;\n  justify-content: space-between;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "_07cwf7w4dCbHTaTzwYMSLw==",
	"block": "+n2nh6esy75eZDwDGyDGwA=="
};
export default ___CSS_LOADER_EXPORT___;
