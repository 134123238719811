import {
  FilterType,
  SortType,
  createFilter,
  createFilterPayload,
  createSort,
} from '@retail/gql-utils';
import { Space } from 'antd';
import { flow, getOr, head } from 'lodash/fp';
import { memo, useEffect, useMemo } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { ORDER_CLAIMS_STATUSES } from '../../constants';
import { TClaimOrderDetails } from '../../selectors';
import claimSelector, { Entity } from '../selectors';

import { SubClaims } from './SubClaims';
import { AdditionalInfo } from './additional-info';
import { ChangeLog } from './change-log';
import { Compensations } from './compensations';
import { Header } from './header';
import { NavigationTabs } from './navigation-tabs';
import cn from './styles.less';
import { WorkshopDetails } from './workshop-details';

import {
  RetailClaimGroupsOptionsQuery,
  useSearchRetailClaimsQuery,
} from '@/apollo/gql-types';
import { MAX_PAGE_SIZE } from '@/constants/common';
import { FEATURE } from '@/constants/features';
import { useFeatures } from '@/providers/FeaturesProvider';
import {
  ORDER_CLAIMS_DETAILS_CHANGELOG,
  ORDER_CLAIMS_DETAILS_WORKFLOW,
} from '@/routes';

interface ContentProps {
  firstClaimId: string;
  claimGroups: RetailClaimGroupsOptionsQuery;
  claimOrderDetails: TClaimOrderDetails;
}

const Content = memo<ContentProps>(
  ({ claimGroups, firstClaimId, claimOrderDetails }) => {
    const { language, orderId, claimId } = useParams();
    const navigate = useNavigate();
    const features = useFeatures();

    const isIdValid =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
        claimId,
      );

    useEffect(() => {
      // NOTE Redirect to first claim if id isn't uuid
      if (!isIdValid) {
        navigate(
          ORDER_CLAIMS_DETAILS_WORKFLOW.LINK({
            language,
            orderId,
            claimId: firstClaimId,
          }),
        );
      }
    }, [claimId, firstClaimId, isIdValid, language, navigate, orderId]);

    const { data: claimsData, loading: claimsLoading } =
      useSearchRetailClaimsQuery({
        skip: !isIdValid,
        variables: {
          search: createFilterPayload({
            sorts: [createSort('createdOn', SortType.DESC)],
            filter: createFilter(null, FilterType.AND, [
              createFilter('id', FilterType.EQUAL, claimId),
              createFilter('orderId', FilterType.EQUAL, orderId),
            ]),
            pageSize: MAX_PAGE_SIZE,
          }),
        },
      });

    const result = useMemo(
      () =>
        claimSelector({
          claims: claimsData,
          claimGroups,
        }),
      [claimGroups, claimsData],
    );

    const claim: Entity = flow(getOr({} as Entity, 'entities'), head)(result);

    return (
      <div>
        <Header claim={claim} />
        <NavigationTabs />

        <Routes>
          <Route
            path={ORDER_CLAIMS_DETAILS_WORKFLOW.ROUTE}
            element={
              <>
                <Space
                  direction="vertical"
                  size="large"
                  className={cn.fullWidth}
                >
                  <AdditionalInfo
                    claim={claim}
                    claimOrderDetails={claimOrderDetails}
                    loading={claimsLoading}
                  />

                  <SubClaims claim={claim} loading={claimsLoading} />

                  {features[FEATURE.RETAIL_CLAIM_COST_PROCESSING] && (
                    <Compensations
                      id={claim?.id}
                      claim={claim}
                      claimsData={claimsData}
                      loading={claimsLoading}
                      country={claim?.country}
                      claimGroups={claimGroups}
                      workshopDetails={claim?.workshopDetails}
                    />
                  )}

                  <WorkshopDetails
                    id={claim?.id}
                    workshopDetails={claim?.workshopDetails}
                    orderClaimStatus={claim?.state as ORDER_CLAIMS_STATUSES}
                  />
                </Space>
              </>
            }
          />
          <Route
            path={ORDER_CLAIMS_DETAILS_CHANGELOG.ROUTE}
            element={<ChangeLog claim={claim} claimGroups={claimGroups} />}
          />
          <Route
            path="/*"
            element={
              <Navigate
                replace
                to={ORDER_CLAIMS_DETAILS_WORKFLOW.LINK({
                  language,
                  orderId,
                  claimId,
                })}
              />
            }
          />
        </Routes>
      </div>
    );
  },
);

export default Content;
