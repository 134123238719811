import { useMemo } from 'react';

import { formatDate, formatDateTime } from './index';

export const useFormatDate = (date: string, fallback = 'N/A') => {
  return useMemo(() => formatDate(date) || fallback, [date, fallback]);
};

export const useFormatDateTime = (date: string) => {
  return useMemo(() => formatDateTime(date) || 'N/A', [date]);
};
