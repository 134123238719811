// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".elr7yRPjpsm\\+fJnjFMM1-w\\=\\= {\n  padding-top: 6px !important;\n  padding-bottom: 6px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/content/customer-complaints/ListItem/styles.less"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,8BAAA;AACF","sourcesContent":[".item {\n  padding-top: 6px !important;\n  padding-bottom: 6px !important;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "elr7yRPjpsm+fJnjFMM1-w=="
};
export default ___CSS_LOADER_EXPORT___;
