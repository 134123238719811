import { CurrencyCodes } from '@retail/currency';
import {
  Control,
  FieldErrors,
  FieldValues,
  useFieldArray,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CostsField from './CostsField';
import { SubClaimsFieldData } from './useSubclaimCostMetadata';

const TOTAL_NAME = 'total';

interface Props {
  currencyCode: CurrencyCodes;
  control: Control<FieldValues>;
  subClaimsFieldData: SubClaimsFieldData[];
  errors: FieldErrors;
}

export const SubClaimForm = ({
  control,
  currencyCode,
  subClaimsFieldData,
  errors,
}: Props) => {
  const { t } = useTranslation();

  const { fields } = useFieldArray({
    control,
    name: 'claimCostDistributions',
  });

  return (
    <div>
      {fields?.map((field, index) => (
        <CostsField
          key={index}
          currencyCode={currencyCode}
          title={subClaimsFieldData?.[index]?.title}
          number={subClaimsFieldData?.[index]?.number}
          controllerProps={{
            name: `claimCostDistributions.${index}.amountMinorUnits`,
            control,
            rules: {
              required: {
                value: true,
                message: t(
                  'bo.orderClaims.processing.popUp.subclaimCosts.validation.canNotBeEmpty',
                ),
              },
            },
          }}
        />
      ))}

      <CostsField
        disabled
        errorMessage={errors?.[TOTAL_NAME]?.message}
        currencyCode={currencyCode}
        title={
          <strong className="text-uppercase">
            {t('bo.orderClaims.processing.table.footer.title')}
          </strong>
        }
        controllerProps={{
          name: TOTAL_NAME,
          control,
        }}
      />
    </div>
  );
};
