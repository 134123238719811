import { makeVar } from '@apollo/client';
import { CurrencyCodes } from '@retail/currency';

import { COST_TYPE } from '@/pages/claims/constants';

export type CostModalValues = {
  id?: string;
  cost: null | {
    id?: string;
    costMinorUnits?: number;
    warrantyCoverageType?: string;
    orderItemId?: string;
    costType?: COST_TYPE;
  };
  currencyCode?: CurrencyCodes;
};

export const manageCostModal = makeVar<{
  visible: boolean;
  data: CostModalValues | null;
}>({ visible: false, data: null });

export const hideModal = () => manageCostModal({ visible: false, data: null });
export const showModal = (data: CostModalValues) =>
  manageCostModal({ visible: true, data });
