import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDeleteRetailSubClaimMutation } from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';

interface Props {
  subClaimId: string;
  subclaimNumber: string;
  disabled: boolean;
}

export function SubclaimDeleteButton({
  subClaimId,
  subclaimNumber,
  disabled,
}: Props) {
  const [deleteSubclaim, { loading }] = useDeleteRetailSubClaimMutation({
    ...REQUEST_BASE_OPTIONS,
    refetchQueries: ['SearchRetailClaims'],
  });
  const { t } = useTranslation();

  async function onClick() {
    if (
      global.confirm(
        `${t(
          'bo.orderClaims.processing.deleteSubclaim.warning',
        )}: ${subclaimNumber} ?`,
      )
    ) {
      await deleteSubclaim({ variables: { subClaimId } });
    }
  }

  return (
    <Button
      danger
      type="primary"
      data-qa-selector="subclaim-delete"
      disabled={disabled}
      icon={<DeleteOutlined />}
      loading={loading}
      onClick={onClick}
    />
  );
}
