import { isEmpty } from 'lodash/fp';
import { useCallback } from 'react';

import { useUploadSubclaimDocuments } from './useUploadSubclaimDocuments';
import { useCredentials } from './useCredentials';
import { useDocumentTypeId } from './useDocumentTypeId';

interface PayloadArg {
  subClaimId: string;
  newDocuments: File[];
}

interface CreateCommentAndDocumentsArg {
  retailCountry: string;
}

export function useCreateSubclaimDocuments({
  retailCountry,
}: CreateCommentAndDocumentsArg) {
  const uploadDocuments = useUploadSubclaimDocuments();
  const loadCredentials = useCredentials();
  const getDocumentTypeId = useDocumentTypeId(retailCountry);

  return useCallback(
    async ({ subClaimId, newDocuments = [] }: PayloadArg) => {
      if (!isEmpty(newDocuments)) {
        const documentTypeId = await getDocumentTypeId();

        const credentials = await loadCredentials({
          documents: newDocuments,
          subClaimId,
          documentTypeId,
        });

        await uploadDocuments({
          files: newDocuments,
          credentials,
          subClaimId,
          documentTypeId,
        });
      }
    },
    [uploadDocuments, getDocumentTypeId, loadCredentials],
  );
}
