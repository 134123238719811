import { notification } from 'antd';
import axios from 'axios';
import { size } from 'lodash/fp';
import { useCallback } from 'react';

import { useCreateRetailSubClaimDocumentMutation } from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';

const FILE_UPLOADING_TIMEOUT = 1800000;
const KB = 1024;

interface Credential {
  formData: Record<string, string>;
  cdn: string;
  host: string;
  fileName: string;
}

interface PayloadArg {
  files: File[];
  credentials: Credential[];
  subClaimId: string;
  documentTypeId: string;
}

export function useUploadSubclaimDocuments() {
  const [createDocument] =
    useCreateRetailSubClaimDocumentMutation(REQUEST_BASE_OPTIONS);

  return useCallback(
    async ({ files, credentials, subClaimId, documentTypeId }: PayloadArg) => {
      for (let i = 0; i < size(files); i++) {
        const { cdn, host, formData, fileName } = credentials[i];
        const file = files[i];

        const form = new FormData();
        for (const key in formData) {
          form.append(key, formData[key]);
        }
        form.append('Content-Type', file.type);
        form.append('file', file);

        try {
          await axios({
            url: host,
            timeout: FILE_UPLOADING_TIMEOUT,
            method: 'POST',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: form,
          });

          await createDocument({
            variables: {
              subClaimId,
              retailSubClaimDocument: {
                documentTypeId,
                description: '',
                fileName: file.name,
                fileSizeKb: Math.ceil(file.size / KB),
                visibleForCustomer: false,
                sourceType: 'BACK_OFFICE',
                fullUrl: `${cdn}${fileName}`,
              },
            },
          });
        } catch ({ message }) {
          notification.warning({
            message: 'Error', // TODO Add translations
            description: message,
          });
        }
      }
    },
    [createDocument],
  );
}
