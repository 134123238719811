import { OrderItem } from './useOrderItems';

export const getOrderItemName = (orderItem: OrderItem) => {
  if (orderItem.__typename === 'AdItemProjection') {
    return [
      orderItem.ad.vehicle.manufacturer,
      orderItem.ad.vehicle.model,
      orderItem.ad.vehicle.subType,
      orderItem.ad.vehicle.subTypeExtra,
    ]
      .filter(Boolean)
      .join(' ');
  }
  if (orderItem.__typename === 'ProductItemProjection') {
    return orderItem.product.title;
  }
  if (orderItem.__typename === 'WarrantyDetailsProjection') {
    return orderItem.warranty.internalReferenceName;
  }
  if (orderItem.__typename === 'SecondaryWheelsItemProjection') {
    return orderItem.retailAdSecondaryWheels.internalRefName;
  }
  if (orderItem.__typename === 'CarRegistrationItemProjection') {
    return orderItem.carRegistration.internalReferenceName;
  }
  if (orderItem.__typename === 'VehiclePreparationItemProjection') {
    return orderItem.vehiclePreparationDTO.internalReferenceName;
  }
  return orderItem.deliveryLocationType;
};
