import { FALLBACK_NA } from '@/constants/common';
import { useFormatDate } from '@/helpers/date';

interface ReturnDateProps {
  isTrialExpired: boolean;
  trialExpiredOn: string;
  vehicleDeliveredOn: string;
}

const ReturnDate = ({
  isTrialExpired,
  vehicleDeliveredOn,
  trialExpiredOn,
}: ReturnDateProps) => {
  const Tag = isTrialExpired ? 'del' : 'span';
  const formattedDate = useFormatDate(trialExpiredOn);

  return <>{vehicleDeliveredOn ? <Tag> {formattedDate} </Tag> : FALLBACK_NA}</>;
};

export default ReturnDate;
