import { CardProps } from 'antd';

import { Card } from '@/components/Card';

export function InfoCard({ bodyStyle, ...props }: CardProps) {
  return (
    <Card
      {...props}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        ...bodyStyle,
      }}
    />
  );
}
