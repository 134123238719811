import { SelectControlled } from '@retail/backoffice-ui';
import { useMemo } from 'react';
import { Control, FieldValues, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FieldCol } from './FieldCol';

import { CLAIM_STATES_OPTIONS } from '@/pages/claims/constants';

interface Props {
  control: Control<FieldValues>;
}

export function ClaimStatus({ control }: Props) {
  const { t } = useTranslation();
  const claimStatusOptions = useMemo(
    () =>
      CLAIM_STATES_OPTIONS.map((item) => ({
        ...item,
        label: t(`bo.orderClaims.claim.status.${item.label}`),
      })),
    [t],
  );
  const isOnlyShowActive = useWatch({ control, name: 'isOnlyShowActive' });

  function checker(value: string[]) {
    return isOnlyShowActive ? [] : value;
  }

  return (
    <FieldCol>
      <SelectControlled
        allowClear
        mode="multiple"
        options={claimStatusOptions}
        data-qa-selector="claimStatus"
        label={t('bo.claimsOverview.claimStatus')}
        placeholder={t('bo.claims.selectPlaceholder')}
        checker={checker}
        disabled={isOnlyShowActive}
        controllerProps={{
          name: 'claimStatus',
          control,
        }}
      />
    </FieldCol>
  );
}
