import { memo } from 'react';

import {
  FormatCurrencyOptions,
  useFormatCurrency,
} from '@/utils/i18n/useFormatCurrency';

interface PriceProps {
  price: FormatCurrencyOptions;
}

export const Price = memo<PriceProps>(({ price }) => {
  const formatedPrice = useFormatCurrency(price);
  return <span data-qa-selector="subclaim-total-cost">{formatedPrice}</span>;
});
