import { SelectControlled } from '@retail/backoffice-ui';
import { Col, Row } from 'antd';
import { find } from 'lodash/fp';
import { useMemo } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCanEditFields } from '../hooks/useCanEditFields';
import { useClearFieldsValue } from '../hooks/useClearFieldsValue';
import { useIsSubClaimDisabled } from '../hooks/useIsSubClaimDisabled';
import { Entity } from '../overview/selectors';

import selectors from './selectors';

import { FIELD_LABEL_COL } from '@/constants/common';
import { RetailClaimGroupsOptionsQuery } from '@/apollo/gql-types';

export const AREA_ID_NAME = 'areaId';
export const DAMAGE_ID_NAME = 'damageId';
export const CAR_PART_ID_NAME = 'carPartId';
export const LIABLE_PARTY_TYPE_NAME = 'liablePartyType';

interface Props {
  claim?: Entity;
  control: Control;
  claimGroupId: string;
  claimGroups: RetailClaimGroupsOptionsQuery;
  allowClearAreaPartDamage?: boolean;
}

export function SubclaimForm({
  claim,
  control,
  claimGroupId,
  claimGroups,
  allowClearAreaPartDamage,
}: Props) {
  const { t } = useTranslation();

  const [areaId, carPartId, damageId, liablePartyType] = useWatch({
    control,
    name: [
      AREA_ID_NAME,
      CAR_PART_ID_NAME,
      DAMAGE_ID_NAME,
      LIABLE_PARTY_TYPE_NAME,
    ],
  });

  const {
    labels,
    areaOptions,
    carPartOptions,
    damageOptions,
    liablePartyOptions,
  } = useMemo(
    () =>
      selectors({
        t,
        areaId,
        damageId,
        carPartId,
        claimGroups,
        claimGroupId,
      }),
    [t, areaId, damageId, carPartId, claimGroups, claimGroupId],
  );
  const [areaLabel, partLabel, damageLabel] = labels;
  const canEditFields = useCanEditFields(claim);

  const isSubClaimDisabled = useIsSubClaimDisabled(claim);
  const isEmptyAreaOptions = !areaOptions.length;
  const isEmptyCarPartOptions = !carPartOptions.length;
  const isEmptyDamageOptions = !damageOptions.length;

  const clearFieldsValue = useClearFieldsValue();

  const liablePartyOptionsWithMissingParty = useMemo(() => {
    if (
      liablePartyType &&
      !find({ value: liablePartyType }, liablePartyOptions)
    ) {
      return [
        ...liablePartyOptions,
        {
          value: liablePartyType,
          label: t(
            `bo.orderClaims.submitReason.liableParty.${liablePartyType}`,
          ),
        },
      ];
    }
    return liablePartyOptions;
  }, [liablePartyOptions, liablePartyType, t]);

  return (
    <>
      <Row gutter={16}>
        <Col span={24} md={6} data-qa-selector={AREA_ID_NAME}>
          <SelectControlled
            labelCol={FIELD_LABEL_COL}
            showSearch
            required
            label={t(areaLabel)}
            onChange={() => clearFieldsValue(CAR_PART_ID_NAME, DAMAGE_ID_NAME)}
            placeholder={t('bo.orderClaims.form.selectPlaceholder')}
            options={areaOptions}
            allowClear={allowClearAreaPartDamage}
            disabled={
              isSubClaimDisabled || isEmptyAreaOptions || !canEditFields
            }
            controllerProps={{
              name: AREA_ID_NAME,
              control,
            }}
          />
        </Col>
        <Col span={24} md={6} data-qa-selector={CAR_PART_ID_NAME}>
          <SelectControlled
            labelCol={FIELD_LABEL_COL}
            showSearch
            required
            label={t(partLabel)}
            onChange={() => clearFieldsValue(DAMAGE_ID_NAME)}
            placeholder={t('bo.orderClaims.form.selectPlaceholder')}
            options={carPartOptions}
            allowClear={allowClearAreaPartDamage}
            disabled={
              isSubClaimDisabled || isEmptyCarPartOptions || !canEditFields
            }
            controllerProps={{
              name: CAR_PART_ID_NAME,
              control,
            }}
          />
        </Col>
        <Col span={24} md={12} data-qa-selector={DAMAGE_ID_NAME}>
          <SelectControlled
            labelCol={FIELD_LABEL_COL}
            showSearch
            required
            label={t(damageLabel)}
            placeholder={t('bo.orderClaims.form.selectPlaceholder')}
            options={damageOptions}
            allowClear={allowClearAreaPartDamage}
            disabled={
              isSubClaimDisabled || isEmptyDamageOptions || !canEditFields
            }
            controllerProps={{
              name: DAMAGE_ID_NAME,
              control,
            }}
          />
        </Col>
      </Row>
      <Row gutter={20}>
        <Col
          span={24}
          md={{ span: 12, offset: 12 }}
          data-qa-selector={LIABLE_PARTY_TYPE_NAME}
        >
          <SelectControlled
            labelCol={FIELD_LABEL_COL}
            showSearch
            label={t('bo.orderClaims.liableParty')}
            placeholder={t('bo.orderClaims.form.selectPlaceholder')}
            options={liablePartyOptionsWithMissingParty}
            controllerProps={{
              name: LIABLE_PARTY_TYPE_NAME,
              control,
            }}
          />
        </Col>
      </Row>
    </>
  );
}
