import { Typography } from 'antd';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RootSelectorResult } from '../selectors';

import { InfoCard } from './InfoCard';

import { InlineKeyValueRow } from '@/components/InlineKeyValueRow';
import { getOrderCustomerTypeLabel } from '@/helpers/fieldOptions/order/customerType';

const { Text } = Typography;

interface ICustomerProps {
  loading: boolean;
  data: {
    customer: RootSelectorResult['customer'];
    stockNumber: RootSelectorResult['stockNumber'];
  };
}

export const Customer = memo<ICustomerProps>(({ data, loading }) => {
  const { t } = useTranslation();
  const { customer, stockNumber } = data;

  const customerType = useMemo(
    () => getOrderCustomerTypeLabel(customer?.customerType),
    [customer?.customerType],
  );

  return (
    <InfoCard
      loading={loading}
      size="small"
      title={t('bo.orderClaims.orderSummary.customerSummary.title.customer')}
    >
      <InlineKeyValueRow
        qaSelector="customerType"
        label={t(
          'bo.orderClaims.orderSummary.customerSummary.title.customerType',
        )}
        value={customerType}
      />
      <InlineKeyValueRow
        qaSelector="customerName"
        label={t(
          'bo.orderClaims.orderSummary.customerSummary.title.customerName',
        )}
        value={customer?.isCorporateType ? customer?.name : customer?.fullName}
      />
      <InlineKeyValueRow
        qaSelector="customerEmail"
        label={t(
          'bo.orderClaims.orderSummary.customerSummary.title.customerEmail',
        )}
        value={
          <a
            href={`mailto:${customer?.email ?? ''}?subject=${
              stockNumber ?? ''
            }`}
          >
            <Text strong copyable>
              {customer?.email ?? 'N/A'}
            </Text>
          </a>
        }
      />
      <InlineKeyValueRow
        qaSelector="customerNumber"
        label={t(
          'bo.orderClaims.orderSummary.customerSummary.title.customerNumber',
        )}
        value={
          <a href={`tel:${customer?.phone ?? ''}`}>
            <Text strong copyable>
              {customer?.phone ?? 'N/A'}
            </Text>
          </a>
        }
      />
    </InfoCard>
  );
});
