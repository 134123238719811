import {
  filter,
  flow,
  get,
  includes,
  intersection,
  isEmpty,
  last,
  map,
  size,
  without,
} from 'lodash/fp';

import { AUX_USER_ACCESS_PARAM_VALUE } from '@/constants/auxUser/accessParams';

const minSize = 2;
const modifiers = [
  AUX_USER_ACCESS_PARAM_VALUE.NONE,
  AUX_USER_ACCESS_PARAM_VALUE.ALL,
];

export const parseAccessParams = (value: string) => {
  if (isEmpty(value) || size(value) < minSize) {
    return value;
  }

  const newValue = last(value);

  if (includes(newValue, modifiers)) {
    return [newValue];
  }

  if (intersection(modifiers, value)) {
    return without(modifiers, value);
  }

  return value;
};

export const hasAccess = (value: unknown, userAccess: unknown[]): boolean => {
  if (includes(AUX_USER_ACCESS_PARAM_VALUE.ALL, userAccess)) {
    return true;
  }

  if (includes(AUX_USER_ACCESS_PARAM_VALUE.NONE, userAccess)) {
    return false;
  }

  return includes(value, userAccess);
};

export const getUserAccess = (key: string) => {
  return flow(filter({ key }), map(get(['value'])));
};
