import { useMemo } from 'react';

import { useRefundsByOrderItemsQuery } from '@/apollo/gql-types';

export const useRefundByOrderItemExternalId = (orderId: string) => {
  const refundByOrderItem = useRefundsByOrderItemsQuery({
    variables: { orderId },
  });

  const data = useMemo(
    () =>
      (
        refundByOrderItem.data?.distributeRefundsByOrderItems?.entities ?? []
      ).reduce((prev, refundsWithOrderItem) => {
        prev[refundsWithOrderItem.externalOrderItemId] =
          (prev[refundsWithOrderItem.externalOrderItemId] || 0) +
          refundsWithOrderItem.amountMinorUnits;
        return prev;
      }, {} as Record<string, number>),
    [refundByOrderItem.data?.distributeRefundsByOrderItems?.entities],
  );
  return useMemo(
    () => ({ ...refundByOrderItem, data }),
    [data, refundByOrderItem],
  );
};
