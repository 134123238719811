// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Eddgnjyo48tmp\\+IUnWFXAQ\\=\\= {\n  margin-bottom: 20px;\n}\n.lKPKjxCq3Tzzjr1JbgmxXA\\=\\= {\n  display: block;\n  margin-bottom: 5px;\n  font-size: 16px;\n  text-transform: uppercase;\n}\n._7BSzhAYDVDd9DDYEk1Mt\\+A\\=\\= {\n  display: inline-block;\n  margin-bottom: 15px;\n  font-size: 16px;\n  text-transform: uppercase;\n}\n._2a9gq90WxK-UdL4KMUWOGA\\=\\= .antd-4-21-7-picker,\n._2a9gq90WxK-UdL4KMUWOGA\\=\\= .antd-4-21-7-picker-group-wrapper {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/content/compensations/cost-comments-modal/styles.less"],"names":[],"mappings":"AAEA;EACE,mBAAA;AADF;AAIA;EACE,cAAA;EAEA,kBAAA;EAEA,eAAA;EACA,yBAAA;AAJF;AAOA;EACE,qBAAA;EAEA,mBAAA;EAEA,eAAA;EACA,yBAAA;AAPF;AAUA;;EAIM,WAAA;AAVN","sourcesContent":["@import '../../../../../../styles/constants.less';\n\n.offset {\n  margin-bottom: 20px;\n}\n\n.compensationNumber {\n  display: block;\n\n  margin-bottom: 5px;\n\n  font-size: 16px;\n  text-transform: uppercase;\n}\n\n.total {\n  display: inline-block;\n\n  margin-bottom: 15px;\n\n  font-size: 16px;\n  text-transform: uppercase;\n}\n\n.form {\n  :global {\n    .@{antd-version}-picker,\n    .@{antd-version}-picker-group-wrapper {\n      width: 100%;\n    }\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"offset": "Eddgnjyo48tmp+IUnWFXAQ==",
	"compensationNumber": "lKPKjxCq3Tzzjr1JbgmxXA==",
	"total": "_7BSzhAYDVDd9DDYEk1Mt+A==",
	"form": "_2a9gq90WxK-UdL4KMUWOGA=="
};
export default ___CSS_LOADER_EXPORT___;
