import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { SelectControlled, TextareaControlled } from '@retail/backoffice-ui';
import { Button, Col, Row } from 'antd';
import { map } from 'lodash/fp';
import { memo, useMemo } from 'react';
import {
  Control,
  FieldErrors,
  FieldValues,
  useFieldArray,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PROOF_REQUEST_TYPE_OPTIONS } from '../../../constants';
import { CustomerComplaintControl } from '../../../customer-complaint-control';

import cn from './styles.less';

import {
  RetailClaimComplaintProjection,
  RetailSubClaimProjection,
} from '@/apollo/gql-types';

interface RequestProofFormProps {
  errors: FieldErrors;
  isSubmitted: boolean;
  control: Control<FieldValues>;
  complaints: RetailClaimComplaintProjection[];
  subClaims: RetailSubClaimProjection[];
}

export const RequestProofForm = memo<RequestProofFormProps>(
  ({ control, complaints, subClaims }) => {
    const { t } = useTranslation();

    const { fields, remove, append } = useFieldArray({
      name: 'requests',
      control,
    });

    const proofTypeOptions = useMemo(
      () =>
        map(
          (item) => ({
            ...item,
            label: t(
              `bo.orderClaims.processing.popUp.additionalProof.proofType.${item.label}`,
            ),
          }),
          PROOF_REQUEST_TYPE_OPTIONS,
        ),
      [t],
    );

    return (
      <div className={cn.offset}>
        {fields?.map((field, index) => (
          <div key={index}>
            <Row justify="space-between">
              <CustomerComplaintControl
                name={`requests.${index}.complaintId`}
                control={control}
                complaints={complaints}
                subClaims={subClaims}
                size={16}
                qaSelector={`complaint-${index}`}
              />

              <Col span={8} className={cn.alignEnd}>
                {fields.length > 1 ? (
                  <Button
                    color="danger"
                    icon={<DeleteOutlined className={cn.deleteIcon} />}
                    onClick={() => remove(index)}
                  />
                ) : null}
              </Col>
            </Row>

            <Row justify="space-between">
              <Col span={16} data-qa-selector={`${index}.type`}>
                <SelectControlled
                  required
                  options={proofTypeOptions}
                  label={t(
                    'bo.orderClaims.processing.popUp.additionalProof.dropdown.label',
                  )}
                  placeholder={t(
                    'bo.orderClaims.processing.popUp.additionalProof.dropdown.placeholder',
                  )}
                  controllerProps={{
                    name: `requests.${index}.type`,
                    control,
                  }}
                  qaSelector={`proofType-${index}`}
                />
              </Col>
            </Row>

            <TextareaControlled
              rows={5}
              required
              maxLength={1000}
              disabled={false}
              label={t(
                'bo.orderClaims.processing.popUp.additionalProof.comment.label',
              )}
              placeholder={t(
                'bo.orderClaims.processing.popUp.additionalProof.comment.placeholder',
              )}
              controllerProps={{
                name: `requests.${index}.comment`,
                control,
              }}
            />
          </div>
        ))}
        <Button
          icon={<PlusOutlined />}
          onClick={() => append({})}
          data-qa-selector="add-proof"
        >
          {t('bo.orderClaims.processing.popUp.additionalProof.addProof.cta')}
        </Button>
      </div>
    );
  },
);
