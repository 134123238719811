import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAllowedClaimStatesQuery } from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';

interface Props {
  claimId?: string;
}

export function useAllowedClaimStatesAsync({ claimId }: Props) {
  const { t } = useTranslation();
  const { data, loading } = useAllowedClaimStatesQuery({
    ...REQUEST_BASE_OPTIONS,
    skip: !claimId,
    variables: { claimId },
  });

  const { allowedNextStates, currentState } = data?.allowedClaimStates ?? {};
  const options = useMemo(
    () =>
      allowedNextStates
        ? [currentState].concat(...allowedNextStates).map((item) => ({
            value: item,
            label: t(`bo.orderClaims.claim.status.${item}`),
          }))
        : [],
    [allowedNextStates, currentState, t],
  );

  return {
    options,
    loading,
  };
}
