// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".L-zslAmSXPcMKuSm7lHADQ\\=\\= {\n  margin-bottom: 0px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClaimsOverview/SearchForm/OnlyShowActive/styles.less"],"names":[],"mappings":"AAAA;EACE,6BAAA;AACF","sourcesContent":[".section {\n  margin-bottom: 0px !important;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "L-zslAmSXPcMKuSm7lHADQ=="
};
export default ___CSS_LOADER_EXPORT___;
