import { formatCurrency } from '@retail/backoffice-ui/src/utils/formatCurrency';
import {
  CurrencyCodes,
  getCurrencySymbolByCode,
  getFormatInputFromMinorUnits,
  getParseInputToMinorUnits,
} from '@retail/currency';
import { t } from 'i18next';
import { Fragment } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ApprovalWarning } from './approval-warning';
import cn from './styles.less';
import { CostFormValues } from './types';
import { useOrderItems } from './useOrderItems';
import { useRefundByOrderItemExternalId } from './useRefundByOrderItemExternalId';

import { PRICE_INPUT_STEP } from '@/pages/claims/constants';
import { InputControlled } from '@/components/form/input';

interface Props {
  currencyCode: CurrencyCodes;
  control?: Control<CostFormValues>;
}

export function CostAmountControl({ currencyCode, control }: Props) {
  const { orderId } = useParams();
  const orderItems = useOrderItems(orderId);
  const selectedOrderItemId = useWatch({ control, name: 'orderItemId' });
  const { data: refundByOrderItemExternalId } =
    useRefundByOrderItemExternalId(orderId);
  const claimCostValue = useWatch({ control, name: 'cost' });

  return (
    <Fragment>
      <InputControlled
        label={t('bo.orderClaims.processing.popUp.addCost.costAmount.title')}
        required
        min="0"
        type="number"
        step={PRICE_INPUT_STEP}
        size="large"
        className={cn.costInput}
        format={getFormatInputFromMinorUnits(currencyCode)}
        parse={getParseInputToMinorUnits(currencyCode)}
        data-qa-selector="subclaim-costs-input"
        addonBefore={getCurrencySymbolByCode(currencyCode)}
        controllerProps={{
          name: 'cost',
          control,
          rules: {
            deps: ['orderItemId'],
            validate: (cost: number) => {
              if (selectedOrderItemId) {
                const orderItem = orderItems.find(
                  (i) => i.id === selectedOrderItemId,
                );
                const maxAllowedCost =
                  (orderItem?.priceGross?.amountMinorUnits ?? 0) -
                  (refundByOrderItemExternalId[orderItem.externalId] ?? 0);

                if (cost > maxAllowedCost) {
                  const currency = orderItem.priceGross
                    .currencyCode as CurrencyCodes;

                  const price = formatCurrency({
                    currency,
                    currencyCode: currency,
                    amountMinorUnits: maxAllowedCost,
                  });
                  const res = t(
                    'bo.orderClaims.processing.popUp.addCost.notification.costPriceInvalid',
                    {
                      price,
                      defaultValue: `This field is invalid (should be less than or equal to ${price}).`,
                    },
                  );

                  return res;
                }
              }
              return true;
            },
          },
        }}
      />
      <ApprovalWarning currencyCode={currencyCode} amount={claimCostValue} />
    </Fragment>
  );
}
