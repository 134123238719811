export const RetailClaimProjection = {
  fields: {
    costs: {
      merge(existing: object, incoming: object) {
        return incoming;
      },
    },
    subClaims: {
      merge(existing: object, incoming: object) {
        return incoming;
      },
    },
  },
};
