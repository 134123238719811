import {
  FilterType,
  SortType,
  createFilter,
  createFilterPayload,
  createSort,
} from '@retail/gql-utils';
import { useCallback } from 'react';

import { client } from '@/apollo/gql-client';
import { SearchSimpleRetailClaimsDocument } from '@/apollo/gql-types';
import { MAX_PAGE_SIZE } from '@/constants/common';

export function useSearchSimpleRetailClaimsDocument() {
  return useCallback(async (orderId: string) => {
    await client.query({
      query: SearchSimpleRetailClaimsDocument,
      fetchPolicy: 'network-only',
      variables: {
        search: createFilterPayload({
          sorts: [createSort('createdOn', SortType.DESC)],
          filter: createFilter('orderId', FilterType.EQUAL, orderId),
          pageSize: MAX_PAGE_SIZE,
        }),
      },
    });
  }, []);
}
