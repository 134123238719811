import { sortBy } from 'lodash/fp';
import { useMemo } from 'react';

import { UseComplaintsData } from './useComplaintsDataV2';

export type TUseListDataSourceProps = Omit<UseComplaintsData, 'documents'>;

const useListDataSource = (complaintsData: TUseListDataSourceProps) =>
  useMemo(() => {
    const { comments, groupedDocuments, proofRequests } = complaintsData;

    return sortBy<TUseListDataSourceProps>((item) => new Date(item.createdOn))([
      ...comments,
      ...groupedDocuments,
      ...proofRequests,
    ]);
  }, [complaintsData]);

export default useListDataSource;
