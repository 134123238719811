import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { List } from 'antd';
import React, { memo } from 'react';

import cn from './styles.less';

interface FileListProps {
  list: Array<File>;
  header?: React.ReactNode;
  handleRemove: (file: File, index: number) => void;
}

export const FileList = memo<FileListProps>(
  ({ header, list, handleRemove }) => {
    return (
      <List
        header={header}
        dataSource={list}
        itemLayout="horizontal"
        renderItem={(item, index) => (
          <List.Item
            key={index}
            className={cn.listItem}
            actions={[
              <DeleteOutlined
                key={index}
                className={cn.listItemDeleteIcon}
                onClick={() => handleRemove(item, index)}
              />,
            ]}
          >
            <PaperClipOutlined className={cn.listItemClipIcon} />
            <div className={cn.listItemContent}>{item.name}</div>
          </List.Item>
        )}
      />
    );
  },
);
