import { SelectControlled, TextareaControlled } from '@retail/backoffice-ui';
import { Col, Row } from 'antd';
import { map } from 'lodash/fp';
import { memo, useMemo } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CLOSE_REASON_OPTIONS } from '../../../constants';

import cn from './styles.less';

interface CloseReasonFormProps {
  control: Control<FieldValues>;
}

export const CloseReasonForm = memo<CloseReasonFormProps>(({ control }) => {
  const { t } = useTranslation();

  const closeReasonOptions = useMemo(
    () =>
      map((item) => ({ ...item, label: t(item.label) }), CLOSE_REASON_OPTIONS),
    [t],
  );

  return (
    <div className={cn.offset}>
      <div>
        <Row justify="space-between">
          <Col span={16} data-qa-selector="claim-close-reason-selector">
            <SelectControlled
              required
              options={closeReasonOptions}
              label={t('bo.orderClaims.close.popUp.dropdown.label')}
              placeholder={t('bo.orderClaims.close.popUp.dropdown.placeholder')}
              data-qa-selector="claim-close-reason-description"
              controllerProps={{
                name: 'reason',
                control,
              }}
            />
          </Col>
        </Row>

        <TextareaControlled
          rows={5}
          maxLength={300}
          label={t('bo.orderClaims.close.popUp.comment.label')}
          placeholder={t('bo.orderClaims.close.popUp.comment.placeholder')}
          controllerProps={{
            name: 'description',
            control,
          }}
        />
      </div>
    </div>
  );
});
