import {
  DeleteOutlined,
  EditOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';
import { Button, Tooltip } from 'antd';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CLAIM_STATES, COST_STATUS } from '../../../../constants';
import { showModal as showCostCommentsModal } from '../cost-comments-modal';
import { showModal as showCostModal } from '../cost-modal';
import { CostModalValues } from '../cost-modal/manage-cost-modal';

import useAllowedCommentFields from './hooks/useAllowedCommentFields';
import cn from './styles.less';

import {
  RetailClaimCostFragmentFragment,
  useDeleteRetailClaimCostMutation,
} from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';
import { DOMAINS, PERMISSIONS } from '@/constants/permissions';

interface ActionsProps {
  cost: RetailClaimCostFragmentFragment;
  orderClaimStatus: CLAIM_STATES;
}

const Actions = memo<ActionsProps>(({ cost, orderClaimStatus }) => {
  const { t } = useTranslation();

  const hasClaimCostDeletePermission = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_DELETE_CLAIM_COST,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });
  const hasClaimCostUpdatePermission = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_UPDATE_CLAIM_COST,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });

  const [deleteRetailClaimCost, { loading: deleteRetailClaimCostLoading }] =
    useDeleteRetailClaimCostMutation({
      ...REQUEST_BASE_OPTIONS,
      refetchQueries: ['SearchRetailClaims'],
      awaitRefetchQueries: true,
    });

  const { isCostCommentsModalAllowed, ...areCommentsAllowed } =
    useAllowedCommentFields({
      approvalLevel1: cost.approvalLevel1,
      approvalLevel2: cost.approvalLevel2,
      approvalLevel3: cost.approvalLevel3,
      customerDecision: cost.customerDecision,
      costType: cost.costType,
      costMinorUnits: cost.costMinorUnits,
      warrantyCoverageType: cost.warrantyCoverageType,
    });

  const isCostEditDisabled = useMemo(
    () => cost.status !== COST_STATUS.ACTIVE,
    [cost],
  );

  const isClaimClosed = orderClaimStatus === CLAIM_STATES.CLOSED;

  const isCostPending = useMemo(
    () => cost.status === COST_STATUS.ACTIVE,
    [cost],
  );

  const handleRemoveClick = useCallback(async () => {
    if (global.confirm(t('bo.orderClaims.processing.deleteCost.warning'))) {
      await deleteRetailClaimCost({
        variables: {
          claimCostId: cost.id,
        },
      });
    }
  }, [cost.id, deleteRetailClaimCost, t]);

  return (
    <div className={cn.actions}>
      <Tooltip
        placement="top"
        title={t('bo.orderClaims.processing.table.edit.title')}
      >
        <Button
          disabled={
            isCostEditDisabled ||
            cost.status === COST_STATUS.CANCELED ||
            !hasClaimCostUpdatePermission ||
            orderClaimStatus === CLAIM_STATES.CLOSED
          }
          type="primary"
          data-qa-selector="action-edit-button"
          onClick={() =>
            showCostModal({ cost: cost as CostModalValues['cost'] })
          }
          icon={<EditOutlined />}
        />
      </Tooltip>

      <Tooltip
        placement="top"
        title={t('bo.orderClaims.processing.table.deleteCost.title')}
      >
        <Button
          disabled={
            deleteRetailClaimCostLoading ||
            hasClaimCostDeletePermission.isDenied ||
            isClaimClosed ||
            !isCostPending
          }
          danger
          type="primary"
          onClick={handleRemoveClick}
          data-qa-selector="action-delete-button"
          icon={<DeleteOutlined />}
        />
      </Tooltip>
      <Tooltip
        placement="top"
        title={t('bo.orderClaims.processing.table.addComments.title')}
      >
        <Button
          type="primary"
          data-qa-selector="action-pencil-button"
          disabled={!isCostCommentsModalAllowed}
          onClick={() =>
            showCostCommentsModal({
              fieldsData: areCommentsAllowed,
              cost,
            })
          }
          icon={<MessageOutlined />}
        />
      </Tooltip>
    </div>
  );
});

export default Actions;
