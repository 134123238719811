import { pick } from 'lodash/fp';
import { FieldValues, useForm } from 'react-hook-form';
import { Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { makeVar, useReactiveVar } from '@apollo/client';
import React, { memo, useCallback, useEffect, useMemo } from 'react';

import cn from './styles.less';
import { WorkshopDetailsForm } from './form';

import {
  RetailClaimWorkshopDetailsProjection,
  useCreateRetailClaimWorkshopDetailsMutation,
  useUpdateRetailClaimWorkshopDetailsMutation,
} from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';

export const manageWorkshopDetailsModal = makeVar({ visible: false });

export const hideModal = () => manageWorkshopDetailsModal({ visible: false });
export const showModal = () => manageWorkshopDetailsModal({ visible: true });

interface WorkshopDetailsModalProps {
  claimId: string;
  workshop: RetailClaimWorkshopDetailsProjection;
}

export const WorkshopDetailsModal = memo<WorkshopDetailsModalProps>(
  ({ claimId, workshop }) => {
    const { visible } = useReactiveVar(manageWorkshopDetailsModal);
    const { t } = useTranslation();

    const initialValues = useMemo(
      () => ({
        carPickedUp: null,
        ...workshop,
      }),
      [workshop],
    );

    const { handleSubmit, control, reset } = useForm<FieldValues>({
      defaultValues: initialValues,
      mode: 'all',
    });

    useEffect(() => reset(initialValues), [initialValues, reset]);

    const [update] = useUpdateRetailClaimWorkshopDetailsMutation({
      ...REQUEST_BASE_OPTIONS,
      refetchQueries: ['SearchRetailClaims'],
      onCompleted: () => {
        hideModal();
        notification.success({
          message: 'Updated successfully',
        });
      },
    });

    const [create] = useCreateRetailClaimWorkshopDetailsMutation({
      ...REQUEST_BASE_OPTIONS,
      refetchQueries: ['SearchRetailClaims'],
      onCompleted: () => {
        hideModal();
        notification.success({
          message: 'Created successfully',
        });
      },
    });

    const onSubmit = useCallback(
      async (formData) => {
        if (workshop && workshop.id) {
          await update({
            variables: {
              claimId,
              retailClaimWorkshopDetails: pick(
                ['address', 'carPickedUp', 'carPickedUpOn'],
                formData,
              ),
              workshopId: workshop.id,
            },
          });
        } else {
          await create({
            variables: {
              claimId,
              retailClaimWorkshopDetails: formData,
            },
          });
        }
      },
      [claimId, create, update, workshop],
    );

    return (
      <Modal
        centered
        width={600}
        visible={visible}
        onOk={handleSubmit(onSubmit)}
        title={t('bo.orderClaims.processing.popUp.workshopDetails.title')}
        onCancel={() => manageWorkshopDetailsModal({ visible: false })}
        // TODO add translations for add/cancel cta
      >
        <form onSubmit={handleSubmit(onSubmit)} className={cn.form}>
          <WorkshopDetailsForm control={control} />
        </form>
      </Modal>
    );
  },
);
