const languages = {
  en: 'en-GB',
  de: 'de-DE',
  'de-at': 'de-AT',
  es: 'es-ES',
  fr: 'fr-FR',
  'fr-be': 'fr-BE',
  it: 'it-IT',
  nl: 'nl-NL',
  'nl-be': 'nl-BE',
  pl: 'pl-PL',
  se: 'sv-SE',
};

export default Object.freeze<Record<string, string>>(languages);
