import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

import Root from './root.component';

if (__WEBPACK_DEV_SERVER__) {
  // eslint-disable-next-line global-require
  require('antd/dist/antd.less');
  /**
   * TODO: antd/Datepicker doesn't add prefix for animation classes.
   * Importint pure css fixes it. The issue reproduces only in standalone mode.
   */
  // eslint-disable-next-line global-require
  require('antd/lib/date-picker/style/css');
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary() {
    // err, info, props
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
