import { COMMON_DOMAIN } from '@retail/backoffice-ui/src/Permission';
/* eslint-disable no-unused-vars */

const BO_MANAGEMENT = 'bo.management';

export const DOMAINS = {
  ...COMMON_DOMAIN,

  BO_MANAGEMENT,

  PRODUCT_MANAGEMENT: 'product.management',

  USER_MANAGEMENT: 'user.management',
  ROLE_USER_MANAGEMENT: 'role.user.management',
  GROUP_USER_MANAGEMENT: 'group.user.management',

  CUSTOMER_MANAGEMENT: 'customer.management',

  REFURBISHMENT_MANAGEMENT: 'refurbishment.management',

  PNL_MANAGEMENT: 'pnl.management',
  ITEM_TYPES_PNL_MANAGEMENT: 'itemtypes.pnl.management',
  PARTNERS_PNL_MANAGEMENT: 'partners.pnl.management',

  RETAIL_AD_MANAGEMENT: 'retailad.management',

  AD_MANAGEMENT: 'ad.management',
  PUBLISHING_QUEUE: 'ad.publishing-queue',
  AD_DOCUMENT_TYPES_MANAGEMENT: 'ad.document-inventory.types.management',
  CAR_REGISTRATIONS_MANAGEMENT: 'registrations.order.management',
  SECONDARY_WHEELS_ORDER_MANAGEMENT: 'secondarywheels.order.management',
  DIGITAL_HANDOVER_PROTOCOL_MANAGEMENT: 'digitalhandover.protocol.management',

  INVENTORY_SOURCING_MANAGEMENT: 'inventory.sourcing.management',

  DAD_IMPORT_MANAGEMENT: 'dad.import.management',
  INVENTORY_SOURCING_IMPORT_MANAGEMENT: 'inventory.sourcing.import.management',
  PAYMENTS_IMPORT_MANAGEMENT: 'payments.import.management',
  PNL_IMPORT_MANAGEMENT: 'pnl.import.management',
  AD_DOCUMENT_INVENTORY_MANAGEMENT: 'ad.document-inventory.items.management',
  AD_DOCUMENT_INVENTORY_PACKAGES_MANAGEMENT:
    'ad.document-inventory.packages.management',

  TASK_MANAGEMENT: 'task.management',
  CLAIM_MANAGEMENT: 'claim.management',
  APPOINTMENTS_MANAGEMENT: 'appointments.management',

  DEFAULT: BO_MANAGEMENT,

  CLM_API: '{platform}.api',
  CLM_GRANT: '{platform}.grant',

  RISK_EVALUATION: 'order.financing.risk.management',
};
