import * as Flags from '@retail/i18n/components/CountryFlags';
import { Tooltip, TooltipProps } from 'antd';
import { get } from 'lodash/fp';
import React, { memo, useMemo } from 'react';

import { toLocalizedCountry } from '@/utils/i18n/toLocalizedCountry';

type CountryFlagProps = Omit<TooltipProps, 'overlay'> & {
  country: string;
  className?: string;
  style?: React.CSSProperties;
};

const CountryFlag = memo<CountryFlagProps>(
  ({ country, className, style, ...attr }) => {
    const FlagSvg = get([country], Flags);
    const title = useMemo(() => toLocalizedCountry(country), [country]);

    return (
      <>
        {FlagSvg ? (
          <Tooltip overlay={title} {...attr}>
            <FlagSvg className={className} style={style} />
          </Tooltip>
        ) : (
          title ?? 'N/A'
        )}
      </>
    );
  },
);

export default CountryFlag;
