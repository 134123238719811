import { defaultTo, flow, get } from 'lodash/fp';
import { useCallback, useMemo } from 'react';

import { Entity } from '../../selectors';

import { ChangeLogSearch } from './Search';
import { SearchFormData } from './Search/search-form-data';
import { ChangeLogTable } from './Table';
import { createSearchVariables } from './utils';

import {
  ClaimChangelogResponseProjection,
  RetailClaimGroupsOptionsQuery,
  useClaimsChangeLogQuery,
} from '@/apollo/gql-types';

interface ChangeLogProps {
  claim: Entity;
  claimGroups: RetailClaimGroupsOptionsQuery;
}

export const ChangeLog = ({ claim, claimGroups }: ChangeLogProps) => {
  const variables = useMemo(
    () => createSearchVariables(claim?.id),
    [claim?.id],
  );

  const { data, loading, refetch } = useClaimsChangeLogQuery({
    skip: !claim?.id,
    variables,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const getClaimsChangeLog = useCallback(
    (data?: SearchFormData) => {
      refetch(createSearchVariables(claim?.id, data));
    },
    [claim?.id, refetch],
  );

  const claimsChangeLog = useMemo<ClaimChangelogResponseProjection[]>(
    () => flow(get(['changeLog', 'entities']), defaultTo([]))(data),
    [data],
  );

  return (
    <div>
      <ChangeLogSearch onSubmit={getClaimsChangeLog} disabled={loading} />

      <ChangeLogTable
        claimsChangeLog={claimsChangeLog}
        claimGroups={claimGroups}
        loading={loading}
      />
    </div>
  );
};
