import { makeVar, useReactiveVar } from '@apollo/client';
import { Button, Card, Modal } from 'antd';
import { memo, SyntheticEvent, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Entity, ExtendedSubClaim } from '../overview/selectors';
import { SubclaimForm } from '../SubclaimForm';

import {
  RetailClaimGroupsOptionsQuery,
  RetailSubClaimUpdateProjectionInput,
  useUpdateRetailOrderSubClaimMutation,
} from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';

export const manageClaimSubmissionModal = makeVar<{
  visible?: boolean;
  subclaim?: ExtendedSubClaim;
  claim?: Entity;
}>({});

export const hideModal = () => manageClaimSubmissionModal({});
export const showModal = (claim: Entity, subclaim: ExtendedSubClaim) =>
  manageClaimSubmissionModal({
    claim,
    subclaim,
    visible: true,
  });

interface Props {
  claimGroups: RetailClaimGroupsOptionsQuery;
}

export const SubclaimEditModal = memo<Props>(({ claimGroups }) => {
  const { visible, subclaim, claim } = useReactiveVar(
    manageClaimSubmissionModal,
  );
  const { t } = useTranslation();

  const [updateSubclaim] = useUpdateRetailOrderSubClaimMutation({
    ...REQUEST_BASE_OPTIONS,
    refetchQueries: ['SearchRetailClaims'],
  });

  const form = useForm<RetailSubClaimUpdateProjectionInput>({
    defaultValues: {},
    mode: 'all',
  });
  const {
    control,
    formState: { isSubmitting, isDirty, isValid },
    handleSubmit,
    reset,
  } = form;

  useEffect(
    () =>
      reset({
        areaId: subclaim?.areaId,
        carPartId: subclaim?.carPartId,
        damageId: subclaim?.damageId,
        liablePartyType: subclaim?.liablePartyType,
      }),
    [subclaim, reset],
  );

  const disabled = !isDirty || !isValid;

  async function onHandleSubmit(event: SyntheticEvent) {
    if (disabled) {
      return;
    }

    await handleSubmit((retailClaim) =>
      updateSubclaim({
        variables: {
          subClaimId: subclaim?.id,
          retailClaim,
        },
      }),
    )(event);

    hideModal();
  }

  return (
    <Modal
      centered
      visible={visible}
      width={1000}
      onCancel={hideModal}
      title={
        <strong>
          {t('bo.orderClaims.processing.table.reasonsAndLiableParty.title')}
        </strong>
      }
      footer={
        <>
          <Button type="default" onClick={hideModal}>
            <span data-qa-selector="cancel-subclaim-updating">
              {t('bo.orderClaims.form.cancelBtnText')}
            </span>
          </Button>
          <Button
            type="primary"
            loading={isSubmitting}
            disabled={disabled}
            onClick={onHandleSubmit}
          >
            <span data-qa-selector="update-subclaim">
              {t('bo.orderClaims.form.saveBtnText')}
            </span>
          </Button>
        </>
      }
    >
      <Card
        title={
          <>
            <span>{`${t('bo.orderClaims.form.sectionTitle')}: `}</span>
            <strong>{subclaim?.number}</strong>
          </>
        }
      >
        <FormProvider {...form}>
          <form onSubmit={onHandleSubmit}>
            <SubclaimForm
              claim={claim}
              control={control}
              claimGroupId={claim?.claimGroupId}
              claimGroups={claimGroups}
            />
          </form>
        </FormProvider>
      </Card>
    </Modal>
  );
});
