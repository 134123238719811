import { createContext, useContext } from 'react';

const Context = createContext({
  open: () => {},
});

export function useDocumentViewerManagerContext() {
  return useContext(Context);
}

export const DocumentViewerManagerProvider = Context.Provider;
