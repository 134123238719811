// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._35uR9sQ87SqYLMmRy1vG8w\\=\\= {\n  background-color: rgba(255, 0, 0, 0.2);\n}\n.nv3jVdbAZDmaV659vL4I2A\\=\\= {\n  background-color: rgba(255, 255, 0, 0.2);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClaimsOverview/utils/styles.less"],"names":[],"mappings":"AAEA;EACE,sCAAA;AADF;AAIA;EACE,wCAAA;AAFF","sourcesContent":["@opacity: 0.2;\n\n.redRow {\n  background-color: rgba(255, 0, 0, @opacity);\n}\n\n.yellowRow {\n  background-color: rgba(255, 255, 0, @opacity);\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"redRow": "_35uR9sQ87SqYLMmRy1vG8w==",
	"yellowRow": "nv3jVdbAZDmaV659vL4I2A=="
};
export default ___CSS_LOADER_EXPORT___;
