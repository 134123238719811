import {
  RetailClaimStateDueDaysConfigProjection,
  useClaimStateDueDaysConfigQuery,
} from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';

const DEFAULT_VALUE: RetailClaimStateDueDaysConfigProjection[] = [];

export function useClaimStateDueDaysConfigQueryAsync() {
  const { data, loading } =
    useClaimStateDueDaysConfigQuery(REQUEST_BASE_OPTIONS);

  return {
    loading,
    entities: data?.claimStateDueDaysConfig?.entities ?? DEFAULT_VALUE,
  };
}
