// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5aRzBG1pLQFxel9Fg9iGKg\\=\\= {\n  display: flex;\n  align-items: center;\n  min-height: 60px;\n  margin-bottom: 10px;\n  font-size: 24px;\n  text-transform: uppercase;\n}\n.FN3jpPwXU9mmT8K4WRbwiA\\=\\= {\n  width: 100%;\n}\n.RqMuxbZSljSusk8eXejhYA\\=\\= {\n  margin-left: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/content/header/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,yBAAA;AACF;AAEA;EACE,WAAA;AAAF;AAGA;EACE,iBAAA;AADF","sourcesContent":[".title {\n  display: flex;\n  align-items: center;\n  min-height: 60px;\n  margin-bottom: 10px;\n  font-size: 24px;\n  text-transform: uppercase;\n}\n\n.skeleton {\n  width: 100%;\n}\n\n.editButton {\n  margin-left: 10px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "_5aRzBG1pLQFxel9Fg9iGKg==",
	"skeleton": "FN3jpPwXU9mmT8K4WRbwiA==",
	"editButton": "RqMuxbZSljSusk8eXejhYA=="
};
export default ___CSS_LOADER_EXPORT___;
