import { compact } from 'lodash/fp';
import { memo } from 'react';

import cn from './styles.less';

import { RetailSubClaimCommentProjection } from '@/apollo/gql-types';
import { formatDateTime } from '@/helpers/date';

interface CommentsProps {
  title?: string;
  list: Array<RetailSubClaimCommentProjection>;
  qaSelector: string;
}

export const Comments = memo<CommentsProps>(({ list, title, qaSelector }) => (
  <div data-qa-selector={qaSelector}>
    {title && (
      <i>
        <u className={cn.listTitle}>{title}</u>
      </i>
    )}
    <ol>
      {list?.map(({ id, text, createdBy, createdOn }, index) => (
        <li key={id} className={cn.listItem}>
          <div>
            <strong
              className={cn.submitter}
              data-qa-selector={`${qaSelector}-${index}-value`}
            >
              <i>
                {`${compact([createdBy.firstName, createdBy.lastName]).join(
                  ' ',
                )} - ${formatDateTime(createdOn) || 'N/A'}`}
              </i>
            </strong>
          </div>
          <span
            className={cn.comment}
            data-qa-selector={`${qaSelector}-${index}-comment`}
          >
            {text}
          </span>
        </li>
      ))}
    </ol>
  </div>
));
