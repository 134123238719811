import { Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import cn from './styles.less';

import { i18nDetectLng } from '@/i18n/utils';
import * as ROUTES from '@/routes';

const { Text } = Typography;

interface Props {
  number: string;
  orderId: string;
  claimId: string;
  isTrial?: boolean;
  hasAtLeastOneTrial?: boolean;
}

export function ClaimNumber({
  number,
  orderId,
  claimId,
  isTrial,
  hasAtLeastOneTrial,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={cn.section}>
      {isTrial ? (
        <Tag
          data-qa-selector="trial"
          data-qa-selector-value={number}
          className={cn.trial}
          color="error"
        >
          {t('bo.orderOverview.table.column.claimNumber.trial')}
        </Tag>
      ) : null}
      <Text
        copyable={{ text: number }}
        style={{ marginLeft: hasAtLeastOneTrial ? '48px' : undefined }}
        className={cn.text}
      >
        <Link
          data-qa-selector="cell-claim-number"
          target="_blank"
          to={ROUTES.ORDER_CLAIMS_DETAILS.LINK({
            orderId,
            claimId,
            language: i18nDetectLng(),
          })}
        >
          {number ?? 'N/A'}
        </Link>
      </Text>
    </div>
  );
}
