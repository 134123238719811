// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uBI\\+WOkxQeLHM1\\+WSHiJ6Q\\=\\= {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 50px;\n  padding-left: 20px;\n  background: #000000;\n}\n.uBI\\+WOkxQeLHM1\\+WSHiJ6Q\\=\\= .bXTNDMi68y3qE2s6xibQTg\\=\\= {\n  color: #ffffff;\n}\n.uBI\\+WOkxQeLHM1\\+WSHiJ6Q\\=\\= .anticon {\n  height: 100%;\n  margin: 0;\n  padding: 14.5px 17px;\n  border: 0;\n  background: #000000;\n}\n.uBI\\+WOkxQeLHM1\\+WSHiJ6Q\\=\\= .anticon:not(:first-child) {\n  border-left: 1px solid #333333;\n}\n.uBI\\+WOkxQeLHM1\\+WSHiJ6Q\\=\\= .anticon:hover,\n.uBI\\+WOkxQeLHM1\\+WSHiJ6Q\\=\\= .anticon:active {\n  background: #707070;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DocumentViewer/Header/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EAEA,YAAA;EACA,kBAAA;EAEA,mBAAA;AADF;AAPA;EAWI,cAAA;AADJ;AAVA;EAgBM,YAAA;EACA,SAAA;EACA,oBAAA;EAEA,SAAA;EACA,mBAAA;AAJN;AAMM;EACE,8BAAA;AAJR;AAOM;;EAEE,mBAAA;AALR","sourcesContent":[".documentViewerHeader {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  height: 50px;\n  padding-left: 20px;\n\n  background: #000000;\n\n  .title {\n    color: #ffffff;\n  }\n\n  :global {\n    .anticon {\n      height: 100%;\n      margin: 0;\n      padding: 14.5px 17px;\n\n      border: 0;\n      background: #000000;\n\n      &:not(:first-child) {\n        border-left: 1px solid #333333;\n      }\n\n      &:hover,\n      &:active {\n        background: #707070;\n      }\n    }\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"documentViewerHeader": "uBI+WOkxQeLHM1+WSHiJ6Q==",
	"title": "bXTNDMi68y3qE2s6xibQTg=="
};
export default ___CSS_LOADER_EXPORT___;
