import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';
import { useUserContext } from '@retail/backoffice-ui/src/UserContext';
import { getCurrencyCodeByCountryCode } from '@retail/currency';
import { Button, Collapse, Table } from 'antd';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CLAIM_STATES } from '../../../constants';
import { Entity } from '../../selectors';

import { CostCommentsModal } from './cost-comments-modal';
import { CostModal, showModal as showCostModal } from './cost-modal';
import useTableColumns from './hooks/useTableColumns';
import { Price } from './price';
import selectors from './selectors';
import cn from './styles.less';

import {
  RetailClaimGroupsOptionsQuery,
  SearchRetailClaimsQuery,
} from '@/apollo/gql-types';
import { CollapsePanel } from '@/components/collapse-panel';
import { DOMAINS, PERMISSIONS } from '@/constants/permissions';

interface CompensationsProps {
  id: string;
  claim: Entity;
  country: string;
  loading: boolean;
  claimsData: SearchRetailClaimsQuery;
  workshopDetails: Entity['workshopDetails'];
  claimGroups: RetailClaimGroupsOptionsQuery;
}

export const Compensations = memo<CompensationsProps>(
  ({ claim, id, country, claimsData, loading, claimGroups }) => {
    const { t } = useTranslation();
    const { permissions } = useUserContext();
    const currencyCode = useMemo(
      () => getCurrencyCodeByCountryCode(country),
      [country],
    );

    const {
      orderClaimStatus,
      costs,
      claimTotalCost,
      isSubClaimsDamageInfoComplete,
    } = useMemo(
      () =>
        selectors({
          claims: claimsData,
          permissions,
          claimGroups,
        }),
      [claimsData, permissions, claimGroups],
    );

    const isClaimClosed = claim?.state === CLAIM_STATES.CLOSED;

    const columns = useTableColumns({
      orderClaimStatus: orderClaimStatus as CLAIM_STATES,
    });

    const hasClaimCostCreatePermission = useCheckPermissions({
      allow: PERMISSIONS.MUTATION_CREATE_CLAIM_COST,
      domain: DOMAINS.CLAIM_MANAGEMENT,
    });

    const handleShowCostModal = () => {
      if (!isSubClaimsDamageInfoComplete) {
        return global.alert(
          t(
            'bo.orderClaims.processing.subclaimAreaPartDamageEmpty.addCostWarning',
          ),
        );
      }
      showCostModal({ id, cost: null, currencyCode });
    };

    return (
      <Collapse expandIconPosition="end" defaultActiveKey={['1']}>
        <CollapsePanel
          key="1"
          header={
            <h3 data-qa-selector="compensations" className={cn.title}>
              {t('bo.orderClaims.processing.title')}
            </h3>
          }
        >
          <div className={cn.content}>
            <Table
              size="small"
              bordered
              rowKey="id"
              loading={loading}
              columns={columns}
              pagination={false}
              dataSource={costs}
              className={cn.table}
              data-qa-selector="claim-cost-table"
              footer={
                claimTotalCost > 0
                  ? () => (
                      <strong
                        className={cn.totalCost}
                        data-qa-selector="claim-cost-total"
                      >
                        <span className={cn.label}>{`${t(
                          'bo.orderClaims.processing.claimTotalCost.title',
                        )} `}</span>
                        <Price
                          price={{
                            amountMinorUnits: claimTotalCost,
                            currencyCode,
                          }}
                        />
                      </strong>
                    )
                  : null
              }
            />
            <div className={cn.buttonWrap}>
              <Button
                type="primary"
                disabled={
                  isClaimClosed || hasClaimCostCreatePermission.isDenied
                }
                data-qa-selector="addClaimCost"
                onClick={handleShowCostModal}
              >
                {t('bo.orderClaims.processing.addCost.CTA')}
              </Button>
            </div>
          </div>
          <CostModal />
          <CostCommentsModal />
        </CollapsePanel>
      </Collapse>
    );
  },
);
