import { useCallback } from 'react';
import fileExtension from 'file-extension';
import { get } from 'lodash/fp';

import { client } from '@/apollo/gql-client';
import { BuildImageUploadCredentialsDocument } from '@/apollo/gql-types';

interface PayloadArg {
  documents: File[];
  subClaimId: string;
  documentTypeId: string;
}

export function useCredentials() {
  return useCallback(
    async ({ documents, subClaimId, documentTypeId }: PayloadArg) => {
      const payload = documents.map((file) => ({
        id: subClaimId,
        documentTypeId,
        fileExt: fileExtension(file.name),
      }));
      const { data: credentialsData } = await client.query({
        query: BuildImageUploadCredentialsDocument,
        variables: {
          documents: {
            uploadCredentialsRequestDTOs: payload,
          },
        },
      });

      return get(
        ['buildImageUploadCredentials', 'awsUploadCredentialsDTOS'],
        credentialsData,
      );
    },
    [],
  );
}
