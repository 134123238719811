import { CUSTOMER_TYPES } from '@retail/order-constants';

export const orderCustomerTypeOptions = [
  {
    value: CUSTOMER_TYPES.PRIVATE,
    label: 'Private',
  },
  {
    value: CUSTOMER_TYPES.COMPANY,
    label: 'Company',
  },
  {
    value: CUSTOMER_TYPES.DEALER,
    label: 'Dealer',
  },
];
