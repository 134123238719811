import { LOCALES } from '@retail/i18n/constants';
import { SALUTATIONS } from '@retail/order-constants';
import { without } from 'lodash/fp';

export default [
  {
    value: SALUTATIONS.MR,
    label: 'Mr.',
    locales: without([LOCALES.SV_SE])(process.env.ENABLED_LOCALES),
  },
  {
    value: SALUTATIONS.MS,
    label: 'Ms.',
    locales: without([LOCALES.SV_SE])(process.env.ENABLED_LOCALES),
  },
  {
    value: SALUTATIONS.MRS,
    label: 'Mrs.',
    locales: [LOCALES.IT],
  },
  {
    value: SALUTATIONS.MX,
    label: 'Mx.',
    locales: [LOCALES.PL],
  },
  {
    value: SALUTATIONS.DR,
    label: 'Dr.',
    locales: [LOCALES.DE_AT, LOCALES.NL_BE, LOCALES.DE, LOCALES.PL],
  },
  {
    value: SALUTATIONS.PROF,
    label: 'Prof.',
    locales: [LOCALES.DE_AT, LOCALES.NL_BE, LOCALES.DE, LOCALES.PL],
  },
  {
    value: SALUTATIONS.PREFER_NOT_TO_SAY,
    label: 'Prefer not to say',
    locales: without([LOCALES.SV_SE])(process.env.ENABLED_LOCALES),
  },
];
