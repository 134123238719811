import { OptionModel } from '@retail/backoffice-ui';
import { ExpectationTime } from '@retail/backoffice-ui/src/ExpectationTime';
import { OrderNumber } from '@retail/backoffice-ui/src/OrderNumber';
import { StockNumber } from '@retail/backoffice-ui/src/StockNumber';
import { TableColumnType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ClaimNumber } from '../ClaimNumber';
import { DueDateColumn } from '../DueDate';
import { RetailClaimProjectionModel } from '../types';
import { dueDateCellClassName } from '../utils';

import { ClaimNextAction } from '@/components/ClaimNextAction';
import { formatDateTime } from '@/helpers/date';

interface Props {
  assignees: OptionModel[];
  hasAtLeastOneTrial?: boolean;
}

export function useColumns({
  assignees,
  hasAtLeastOneTrial,
}: Props): TableColumnType<RetailClaimProjectionModel>[] {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          title: <strong>{t('bo.claimsOverview.createdOn')}</strong>,
          dataIndex: 'createdOn',
          key: 'createdOn',
          sorter: true,
          render: (createdOn) => (
            <span data-qa-selector="cell-created-on">
              {formatDateTime(createdOn)}
            </span>
          ),
        },
        {
          title: <strong>{t('bo.claimsOverview.orderNumber')}</strong>,
          dataIndex: 'orderNumber',
          key: 'orderNumber',
          sorter: true,
          render: (_, { order }) => (
            <OrderNumber
              target="_blank"
              qaSelector="cell-order-number"
              orderId={order.id}
              orderNumber={order?.orderNumber}
            />
          ),
        },
        {
          title: <strong>{t('bo.claimsOverview.stockNumber')}</strong>,
          dataIndex: 'stockNumber',
          key: 'stockNumber',
          sorter: true,
          render: (_, { ad, order }) => (
            <StockNumber
              target="_blank"
              data-qa-selector="cell-stock-number"
              adId={ad?.id}
              stockNumber={order?.stockNumber}
            />
          ),
        },
        {
          title: <strong>{t('bo.claimsOverview.claimNumber')}</strong>,
          dataIndex: 'number',
          key: 'number',
          sorter: true,
          render: (number, { order, id, isTrial }) => (
            <ClaimNumber
              number={number}
              orderId={order.id}
              claimId={id}
              isTrial={isTrial}
              hasAtLeastOneTrial={hasAtLeastOneTrial}
            />
          ),
        },
        {
          title: (
            <strong>{t('bo.claimsOverview.assignedTo.resultsTitle')}</strong>
          ),
          dataIndex: 'assignTo',
          key: 'assignTo',
          sorter: true,
          render: (assignTo) => (
            <span data-qa-selector="cell-assigned-to">
              {assignees.find((x) => x.value == assignTo)?.label}
            </span>
          ),
        },
        {
          title: <strong>{t('bo.claimsOverview.claimStatus')}</strong>,
          dataIndex: 'state',
          key: 'state',
          sorter: true,
          render: (state) => (
            <span data-qa-selector="cell-state">
              {t(`bo.orderClaims.claim.status.${state}`)}
            </span>
          ),
        },
        {
          title: <strong>{t('bo.claimsOverview.updatedOn')}</strong>,
          dataIndex: 'updatedOn',
          key: 'updatedOn',
          sorter: true,
          render: (value) => (
            <ExpectationTime
              data-qa-selector="updatedOn"
              dateRight={value}
              suffix={t('bo.claimsOverview.updatedOn.ago')}
            />
          ),
        },
        {
          title: (
            <strong>
              {t('bo.orderClaims.changeLog.table.field.stateComment')}
            </strong>
          ),
          dataIndex: 'stateComment',
          key: 'stateComment',
          width: 500,
          render: (value, { id }) => (
            <ClaimNextAction
              size="small"
              claimId={id}
              defaultValue={value}
              refetchQueries={['SearchOrderRetailClaims']}
            />
          ),
        },
        {
          title: <strong>{t('bo.claimsOverview.dueDate')}</strong>,
          dataIndex: 'stateDueDate',
          key: 'stateDueDate',
          width: 140,
          sorter: true,
          onCell: dueDateCellClassName,
          render: (value, { id, state }) => (
            <div data-qa-selector="cell-stateDueDate">
              <DueDateColumn claimId={id} stateDueDate={value} state={state} />
            </div>
          ),
        },
      ] as TableColumnType<RetailClaimProjectionModel>[],
    [assignees, hasAtLeastOneTrial, t],
  );
}
