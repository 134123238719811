import { PERMISSIONS } from '@/constants/permissions';

export const EDIT_CLAIM_PERMISSIONS = [
  PERMISSIONS.MUTATION_ORDER_CLAIMS_CREATE,
  PERMISSIONS.MUTATION_ORDER_CLAIMS_CREATE_SUB_CLAIM,
  PERMISSIONS.MUTATION_ORDER_CLAIMS_UPDATE_SUB_CLAIM,
  PERMISSIONS.MUTATION_ORDER_CLAIMS_CREATE_SUB_CLAIM_DOC,
  PERMISSIONS.MUTATION_ORDER_CLAIMS_DELETE_SUB_CLAIM_DOC,
  PERMISSIONS.MUTATION_ORDER_CLAIMS_CREATE_SUB_CLAIM_COMMENT,
];

export const EDIT_CLOSED_CLAIM_PERMISSIONS = [
  PERMISSIONS.MUTATION_CLAIMS_UPLOAD_DOC_CLAIMS_CLOSED,
  PERMISSIONS.MUTATION_CLAIMS_EDIT_ORDER_CLAIMS_CLOSED,
];
