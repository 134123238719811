// local dependencies
import { RetailClaimProjection } from './RetailClaimProjection';
import { PageDTO_RetailClaimProjection } from './PageDTO_RetailClaimProjection';
import { RetailClaimCostCommentProjection } from './RetailClaimCostCommentProjection';

const policies = {
  RetailClaimProjection,
  PageDTO_RetailClaimProjection,
  RetailClaimCostCommentProjection,
};

export default policies;
