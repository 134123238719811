import { useCallback } from 'react';

import { useCreateSubclaimDocuments } from '../hooks/useCreateSubclaimDocuments';
import { useSearchRetailClaimsDocument } from '../hooks/useSearchRetailClaimsDocument';

import {
  RetailSubClaimCreateProjectionInput,
  useCreateRetailOrderSubClaimMutation,
} from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';

interface CreateSubclaimArg {
  orderId: string;
  claimId: string;
  retailCountry: string;
}

interface PayloadArg extends RetailSubClaimCreateProjectionInput {
  newDocuments?: File[];
}

export function useCreateSubclaim({
  orderId,
  claimId,
  retailCountry,
}: CreateSubclaimArg) {
  const [createSubClaim] =
    useCreateRetailOrderSubClaimMutation(REQUEST_BASE_OPTIONS);
  const createDocuments = useCreateSubclaimDocuments({ retailCountry });
  const refetchClaims = useSearchRetailClaimsDocument();

  return useCallback(
    async ({ newDocuments, ...retailClaim }: PayloadArg) => {
      const result = await createSubClaim({
        variables: { claimId, retailClaim },
      });
      const subClaimId = result.data?.createRetailOrderSubClaim?.id;

      await createDocuments({ subClaimId, newDocuments });

      await refetchClaims({ orderId, claimId });
    },
    [claimId, createDocuments, createSubClaim, orderId, refetchClaims],
  );
}
