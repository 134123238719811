import { SettingOutlined } from '@ant-design/icons';
import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { FormModal } from './FormModal';
import cn from './styles.less';

import { DOMAINS, PERMISSIONS } from '@/constants/permissions';
import { useActive } from '@/hooks/useActive';

export function DueDaysConfigButton() {
  const { active, setActive, setInactive } = useActive();
  const { t } = useTranslation();
  const { isAllowed } = useCheckPermissions({
    allow: PERMISSIONS.CLAIMS_STATE_DUE_DAYS_CONFIG,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });

  return isAllowed ? (
    <div className={cn.section}>
      <FormModal active={active} setInactive={setInactive} />
      <Button onClick={setActive}>
        {t('bo.claimsOverview.table.dueDays.title')}
        <SettingOutlined />
      </Button>
    </div>
  ) : null;
}
