import moment from 'moment';

const FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
const UNIT_OF_TIME = 'day';

export function getStartOfDayDate(value: string) {
  return moment(value).startOf(UNIT_OF_TIME).format(FORMAT);
}

export function getEndOfDayDate(value: string) {
  return moment(value).endOf(UNIT_OF_TIME).format(FORMAT);
}
