// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GpLADlqqbez2dXo2Vkp6QA\\=\\= {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  list-style-type: decimal;\n  margin: 0;\n  padding-left: 15px;\n  column-gap: 15px;\n  row-gap: 5px;\n}\n.GpLADlqqbez2dXo2Vkp6QA\\=\\= .xH6qe4llj2r-3CSejJSaFg\\=\\= {\n  margin-right: 15px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/content/customer-complaints/Documents/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,wBAAA;EACA,SAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;AACF;AATA;EAWI,kBAAA;AACJ","sourcesContent":[".documents {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  list-style-type: decimal;\n  margin: 0;\n  padding-left: 15px;\n  column-gap: 15px;\n  row-gap: 5px;\n\n  .documentItem {\n    margin-right: 15px;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"documents": "GpLADlqqbez2dXo2Vkp6QA==",
	"documentItem": "xH6qe4llj2r-3CSejJSaFg=="
};
export default ___CSS_LOADER_EXPORT___;
