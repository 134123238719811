import { Component } from 'react';

import { ViewerDocumentModel } from '../types';

import cn from './styles.less';

interface Props {
  document: ViewerDocumentModel;
}

export default class DocumentViewerDocument extends Component<Props> {
  render() {
    const { document } = this.props;

    // TODO: render pdf file with some lib, to keep the same cross browser behaviour
    // for example: https://www.npmjs.com/package/react-pdf (based on PDF.js)
    return (
      <object
        key={document.fullUrl}
        className={cn.document}
        data={document.fullUrl}
        type={document.fileType?.mime || ''}
      >
        <a href={document.fullUrl} target="_blank" rel="noreferrer noopener">
          {document.fileName}
        </a>
      </object>
    );
  }
}
