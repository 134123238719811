// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5M8td9sLZJsoZCD1wqEs4w\\=\\= {\n  margin-bottom: 20px;\n}\n.\\+KeJUwkmQii\\+6wdhzKQ-YA\\=\\= .antd-4-21-7-picker,\n.\\+KeJUwkmQii\\+6wdhzKQ-YA\\=\\= .antd-4-21-7-picker-group-wrapper {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/content/workshop-details/workshop-details-modal/styles.less"],"names":[],"mappings":"AAEA;EACE,mBAAA;AADF;AAIA;;EAIM,WAAA;AAJN","sourcesContent":["@import '../../../../../../styles/constants.less';\n\n.offset {\n  margin-bottom: 20px;\n}\n\n.form {\n  :global {\n    .@{antd-version}-picker,\n    .@{antd-version}-picker-group-wrapper {\n      width: 100%;\n    }\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"offset": "_5M8td9sLZJsoZCD1wqEs4w==",
	"form": "+KeJUwkmQii+6wdhzKQ-YA=="
};
export default ___CSS_LOADER_EXPORT___;
