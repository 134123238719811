import { useTranslation } from 'react-i18next';
import { TClaimOrderDetails } from 'src/pages/claims/selectors';

import { RowInfo } from '@/components/RowInfo';
import ReturnDate from '@/pages/claims/GeneralInfo/OrderAsideInfo/ReturnDate';

interface Props {
  claimOrderDetails: TClaimOrderDetails;
}

export function RightOfReturnUntil({ claimOrderDetails }: Props) {
  const { t } = useTranslation();
  const { vehicleDeliveredOn, isTrialExpired, trialExpiredOn } =
    claimOrderDetails;

  return (
    <RowInfo
      qaSelector="rightOfReturnUntil"
      label={t('bo.orderClaims.orderSummary.title.rightOfReturnUntil')}
      value={
        <ReturnDate
          isTrialExpired={isTrialExpired}
          trialExpiredOn={trialExpiredOn}
          vehicleDeliveredOn={vehicleDeliveredOn}
        />
      }
      fallback={null}
    />
  );
}
