/* eslint-disable no-unused-vars */
export enum AUX_USER_ACCESS_PARAM {
  COUNTRY = 'country',
  GROUP = 'group',
}

export enum AUX_USER_ACCESS_PARAM_VALUE {
  ALL = '*',
  NONE = '_',
}
