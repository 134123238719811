import { InfoSection } from '../InfoSection';

import { OrderNumber } from './OrderNumber';
import { StockNumber } from './StockNumber';

import { AdItemProjection, OrderProjection } from '@/apollo/gql-types';

interface Props {
  adId: AdItemProjection['id'];
  order: Pick<OrderProjection, 'id' | 'orderNumber' | 'stockNumber'>;
}

export function OrderInfo({ order, adId }: Props) {
  return (
    <InfoSection>
      <OrderNumber orderId={order?.id} orderNumber={order?.orderNumber} />
      <StockNumber adId={adId} stockNumber={order?.stockNumber} />
    </InfoSection>
  );
}
