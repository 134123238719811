import { CurrencyCodes, getCurrencySymbolByCode } from '@retail/currency';
import React, { memo } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PRICE_INPUT_STEP } from '../../../../constants';

import cn from './styles.local.less';

import { PriceInputControlled } from '@/components/form/price';

interface CostsFieldProps {
  number?: string;
  disabled?: boolean;
  isReadonly?: boolean;
  currencyCode: CurrencyCodes;
  controllerProps: UseControllerProps;
  title: React.ReactNode;
  errorMessage?: string;
}

const CostsField = memo<CostsFieldProps>(
  ({
    controllerProps,
    number,
    title,
    currencyCode,
    disabled,
    errorMessage,
  }) => {
    const { t } = useTranslation();

    return (
      <div className={cn.costsField}>
        <div className={cn.labelWrap}>
          {number && (
            <p
              className={cn.number}
              data-qa-selector="subclaim-costs-claim-number"
            >
              {`${t(
                'bo.orderClaims.processing.table.subclaimNumber.title',
              )}: ${number}`}
            </p>
          )}
          {title && <p className={cn.title}>{title}</p>}
        </div>
        <PriceInputControlled
          type="number"
          size="large"
          step={PRICE_INPUT_STEP}
          disabled={disabled}
          errorMessage={errorMessage}
          className={cn.field}
          controllerProps={controllerProps}
          data-qa-selector="subclaim-costs-input"
          addonBefore={getCurrencySymbolByCode(currencyCode)}
        />
      </div>
    );
  },
);

export default CostsField;
