import MIME from 'mime';

import { ViewerDocumentFileTypeModel } from '@/components/DocumentViewer/types';

export function getFileTypeByName(name: string): ViewerDocumentFileTypeModel {
  const mime = MIME.getType(name);

  return {
    mime,
    ext: MIME.getExtension(mime),
  };
}

export function getFileNameFromUrl(url: string) {
  try {
    const { pathname } = new global.URL(url);

    return pathname.substring(pathname.lastIndexOf('/') + 1);
  } catch (error) {
    return '';
  }
}
