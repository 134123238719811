import { Typography } from 'antd';
import cns from 'classnames';
import { useTranslation } from 'react-i18next';

import { PROOF_REQUEST_TYPE_TRANSLATIONS } from '../../../../constants';
import { CreationInfo } from '../CreationInfo';
import { ListItem } from '../ListItem';
import cn from '../styles.less';

import { RetailClaimProofRequestProjection } from '@/apollo/gql-types';

const { Paragraph } = Typography;

export interface IProofRequestProps extends RetailClaimProofRequestProjection {
  className?: string;
}

const ProofRequest = ({
  className,
  createdBy,
  createdOn,
  comment,
  type,
  id,
}: IProofRequestProps) => {
  const { t } = useTranslation();

  return (
    <ListItem
      data-qa-selector="proofRequest"
      key={id}
      className={cns(className, cn.proofRequestItem)}
    >
      <div>
        <CreationInfo createdBy={createdBy} createdOn={createdOn} />
        <Paragraph data-qa-selector="proofType" strong className={cn.proofType}>
          {`${t(
            'bo.orderClaims.processing.table.customerComplaints.proofType',
          )}: ${t(PROOF_REQUEST_TYPE_TRANSLATIONS[type])}`}
        </Paragraph>
        <Paragraph data-qa-selector="comment" className={cn.content}>
          {comment}
        </Paragraph>
      </div>
    </ListItem>
  );
};

export default ProofRequest;
