import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

import {
  ORDER_CLAIMS_DETAILS_CHANGELOG,
  ORDER_CLAIMS_DETAILS_WORKFLOW,
} from '@/routes';

const useActiveClaimsTab = () => {
  const { pathname } = useLocation();

  return useMemo(() => {
    if (pathname.includes(ORDER_CLAIMS_DETAILS_WORKFLOW.ROUTE)) {
      return ORDER_CLAIMS_DETAILS_WORKFLOW.ROUTE;
    }
    if (pathname.includes(ORDER_CLAIMS_DETAILS_CHANGELOG.ROUTE)) {
      return ORDER_CLAIMS_DETAILS_CHANGELOG.ROUTE;
    }

    return '';
  }, [pathname]);
};

export default useActiveClaimsTab;
