import {
  Filter,
  FilterType,
  createFilter,
  createFilterPayload,
  createSort,
} from '@retail/gql-utils';
import { format, startOfDay } from 'date-fns';
import { assign, flow, isEmpty } from 'lodash/fp';

import { DEFAULT_SORTING, UNASSIGNED } from '@/constants/common';
import { DATE_TIME_FORMAT_BE } from '@/constants/date';
import { OverviewSearchModel } from '@/types/OverviewSearchModel';

export const formToMeta = (defaultValues = {}) =>
  flow(
    assign(defaultValues),
    ({
      page: rawPage,
      country,
      claimStatus,
      orderNumber,
      claimNumber,
      stockNumber,
      assignTo,
      sort,
      pageSize,
      direction,
      approvalLevel1,
      approvalLevel2,
      approvalLevel3,
      customerDecision,
      liableParties,
      claimGroups,
      isOnlyShowTrialPeriod,
    }: OverviewSearchModel) => {
      const page = Number(rawPage) - 1 || 0;
      const filtersAnd: Filter[] = [];
      const filtersOr: Filter[] = [];

      if (!isEmpty(country)) {
        filtersAnd.push(
          createFilter(
            'country',
            country.length > 1 ? FilterType.IN : FilterType.EQUAL,
            country.length > 1 ? country : country[0],
          ),
        );
      }

      if (!isEmpty(claimStatus)) {
        filtersAnd.push(
          createFilter(
            'state',
            claimStatus.length > 1 ? FilterType.IN : FilterType.EQUAL,
            claimStatus.length > 1 ? claimStatus : claimStatus[0],
          ),
        );
      }

      if (orderNumber) {
        filtersAnd.push(
          createFilter(
            'orderNumber',
            FilterType.EQUAL,
            orderNumber.trim().toUpperCase(),
          ),
        );
      }

      if (claimNumber) {
        filtersAnd.push(
          createFilter('number', FilterType.EQUAL, claimNumber.trim()),
        );
      }

      if (stockNumber) {
        filtersAnd.push(
          createFilter(
            'stockNumber',
            FilterType.EQUAL,
            stockNumber.trim().toUpperCase(),
          ),
        );
      }

      if (!isEmpty(assignTo)) {
        if (assignTo.includes(UNASSIGNED)) {
          filtersAnd.push(createFilter('assignTo', FilterType.IS_NULL, null));
        } else {
          filtersAnd.push(
            createFilter(
              'assignTo',
              assignTo.length > 1 ? FilterType.IN : FilterType.EQUAL,
              assignTo.length > 1 ? assignTo : assignTo[0],
            ),
          );
        }
      }

      if (approvalLevel1) {
        filtersOr.push(
          createFilter(
            'costs.approvalLevel1',
            FilterType.EQUAL,
            approvalLevel1,
          ),
        );
      }

      if (approvalLevel2) {
        filtersOr.push(
          createFilter(
            'costs.approvalLevel2',
            FilterType.EQUAL,
            approvalLevel2,
          ),
        );
      }

      if (approvalLevel3) {
        filtersOr.push(
          createFilter(
            'costs.approvalLevel3',
            FilterType.EQUAL,
            approvalLevel3,
          ),
        );
      }

      if (!isEmpty(customerDecision)) {
        filtersAnd.push(
          createFilter(
            'costs.customerDecision',
            customerDecision.length > 1 ? FilterType.IN : FilterType.EQUAL,
            customerDecision.length > 1
              ? customerDecision
              : customerDecision[0],
          ),
        );
      }

      if (!isEmpty(claimGroups)) {
        filtersAnd.push(
          createFilter(
            'claimGroupId',
            claimGroups.length > 1 ? FilterType.IN : FilterType.EQUAL,
            claimGroups.length > 1 ? claimGroups : claimGroups[0],
          ),
        );
      }

      if (!isEmpty(liableParties)) {
        filtersAnd.push(
          createFilter(
            'subClaims.liablePartyType',
            liableParties.length > 1 ? FilterType.IN : FilterType.EQUAL,
            liableParties.length > 1 ? liableParties : liableParties[0],
          ),
        );
      }

      if (isOnlyShowTrialPeriod) {
        filtersAnd.push(
          createFilter(
            'trialExpiredOn',
            FilterType.GREATER_THAN,
            format(startOfDay(new Date()), DATE_TIME_FORMAT_BE),
          ),
        );
      }

      let filters: Filter[] = [];

      if (filtersAnd.length) {
        filters = filters.concat(
          filtersAnd.length > 1
            ? createFilter(null, FilterType.AND, filtersAnd)
            : filtersAnd[0],
        );
      }

      if (filtersOr.length) {
        filters = filters.concat(
          filtersOr.length > 1
            ? createFilter(null, FilterType.OR, filtersOr)
            : filtersOr[0],
        );
      }

      let filter = null;

      if (filters.length) {
        filter =
          filters.length > 1
            ? createFilter(null, FilterType.AND, filters)
            : filters[0];
      }

      const sorts = [];

      if (sort && direction) {
        sorts.push(createSort(sort, direction));
      } else {
        sorts.push(DEFAULT_SORTING);
      }

      return createFilterPayload({
        filter,
        sorts,
        page,
        pageSize,
      });
    },
  );
