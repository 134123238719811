import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useUpdateClaimStateDueDateMutation } from '@/apollo/gql-types';
import { DatePickerControlled } from '@/components/form/Datepicker';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';

interface FormModel {
  value?: string;
}

interface Props {
  claimId: string;
  defaultValue?: string;
  disabled?: boolean;
  label?: string;
  className?: string;
  refetchQueries?: string[];
  autoFocus?: boolean;
  size?: 'small';
  onSuccess?: () => void;
}

export function ClaimDueDate({
  claimId,
  defaultValue,
  disabled,
  label,
  className,
  refetchQueries,
  autoFocus,
  size,
  onSuccess,
}: Props) {
  const { control, resetField } = useForm<FormModel>({
    defaultValues: {},
    mode: 'all',
  });

  useEffect(() => {
    resetField('value', { defaultValue });
  }, [defaultValue, resetField]);

  const [updateStatus, { loading }] = useUpdateClaimStateDueDateMutation({
    ...REQUEST_BASE_OPTIONS,
    awaitRefetchQueries: true,
    refetchQueries,
  });

  async function onChange(dueDate: Date) {
    await updateStatus({
      variables: {
        claimId,
        dueDate: dueDate?.toISOString(),
      },
    });

    onSuccess?.();
  }

  return (
    <DatePickerControlled
      allowClear={false}
      className={className}
      autoFocus={autoFocus}
      label={label}
      dateFormat="DD/MM/YYYY"
      data-qa-selector="dueDate"
      size={size}
      disabled={disabled || loading}
      onChange={onChange}
      controllerProps={{
        name: 'value',
        control,
      }}
    />
  );
}
