import { SwitchControlled } from '@retail/backoffice-ui';
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FieldCol } from '../FieldCol';

import cn from './styles.less';

import { CLAIM_STATES } from '@/pages/claims/constants';

const SELECTED_STATUSES = [
  CLAIM_STATES.ADDITIONAL_PROOF_REQUIRED,
  CLAIM_STATES.AWAITING_COST_ESTIMATE,
  CLAIM_STATES.AWAITING_REPAIR,
  CLAIM_STATES.IN_PROGRESS,
  CLAIM_STATES.PROOF_SUBMITTED,
  CLAIM_STATES.SUBMITTED,
];

interface Props {
  control: Control<FieldValues>;
  refetch: () => void;
  setValue: UseFormSetValue<FieldValues>;
}

export function OnlyShowActive({ control, refetch, setValue }: Props) {
  const { t } = useTranslation();

  function onChange(value: boolean) {
    if (value) {
      setValue('claimStatus', SELECTED_STATUSES);
      setValue('isOnlyShowActive', true);
    } else {
      setValue('claimStatus', SELECTED_STATUSES);
      setValue('isOnlyShowActive', null);
    }

    refetch();
  }

  return (
    <FieldCol>
      <SwitchControlled
        label={t('bo.orderOverview.searchForm.field.isOnlyShowActive')}
        onChange={onChange}
        className={cn.section}
        controllerProps={{
          name: 'isOnlyShowActive',
          control,
        }}
      />
    </FieldCol>
  );
}
