import { FormItem } from '@retail/backoffice-ui';
import {
  CurrencyCodes,
  getFormatInputFromMinorUnits,
  getParseInputToMinorUnits,
} from '@retail/currency';
import { InputNumber, InputNumberProps, InputProps } from 'antd';
import { ReactNode, memo } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';

type PriceInputControlledProps<T extends FieldValues> = {
  controllerProps: UseControllerProps<T>;
  label?: ReactNode;
  step?: number;
  min?: number;
  required?: boolean;
  disabled?: boolean;
  currencyCode?: CurrencyCodes;
  errorMessage?: string;
  parse?: (value: unknown) => string | number;
  format?: (value: unknown) => string | number;
} & (InputProps & InputNumberProps);

export const PriceInputControlled = memo<
  PriceInputControlledProps<FieldValues>
>(
  ({
    label,
    controllerProps,
    min = 0,
    step = 0.01,
    currencyCode,
    errorMessage,
    required,
    disabled,
    format = (e) => e,
    parse = (e) => e,
    ...restInputProps
  }) => {
    const { i18n } = useTranslation();
    const { field } = useController(controllerProps);
    const formatValue = format
      ? getFormatInputFromMinorUnits(currencyCode)
      : format;
    const parseValue = parse ? getParseInputToMinorUnits(currencyCode) : parse;

    return (
      <FormItem
        label={label}
        required={required}
        disabled={disabled}
        errorMessage={errorMessage}
        controllerProps={controllerProps}
      >
        <div className={cn.inputWrapper}>
          <InputNumber
            min={min}
            step={step}
            disabled={disabled}
            {...field}
            lang={i18n.language}
            value={formatValue(field.value) as string}
            onChange={(value) => field.onChange(parseValue(value))}
            {...restInputProps}
          />
        </div>
      </FormItem>
    );
  },
);
