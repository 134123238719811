// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5J82Zc1ODkARZGhrtTHB3Q\\=\\= {\n  position: fixed;\n  top: calc(50% - 25px);\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  width: 65px;\n  height: 100px;\n  cursor: pointer;\n  color: #999999;\n  border: 0;\n  outline: none;\n  background: transparent;\n  font-size: 40px;\n}\n._5J82Zc1ODkARZGhrtTHB3Q\\=\\=:hover,\n._5J82Zc1ODkARZGhrtTHB3Q\\=\\=:active {\n  background: rgba(0, 0, 0, 0.8);\n}\n.F3BlzOu-KjQx25uNt0tBnQ\\=\\= {\n  left: 0;\n  border-top-right-radius: 5px;\n  border-bottom-right-radius: 5px;\n}\n.ODiy1xmEQ70M53-Az-bEEw\\=\\= {\n  right: 0;\n  border-top-left-radius: 5px;\n  border-bottom-left-radius: 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DocumentViewer/Arrows/styles.less"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,qBAAA;EAEA,oBAAA;EACA,mBAAA;EACA,uBAAA;EAEA,WAAA;EACA,aAAA;EAEA,eAAA;EAEA,cAAA;EACA,SAAA;EACA,aAAA;EACA,uBAAA;EAEA,eAAA;AANF;AAQE;;EAEE,8BAAA;AANJ;AAUA;EACE,OAAA;EAEA,4BAAA;EACA,+BAAA;AATF;AAYA;EACE,QAAA;EAEA,2BAAA;EACA,8BAAA;AAXF","sourcesContent":["@import '../vars.less';\n\n.arrow {\n  position: fixed;\n  top: ~'calc(50% - 25px)';\n\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n\n  width: 65px;\n  height: 100px;\n\n  cursor: pointer;\n\n  color: #999999;\n  border: 0;\n  outline: none;\n  background: transparent;\n\n  font-size: 40px;\n\n  &:hover,\n  &:active {\n    background: rgba(0, 0, 0, 0.8);\n  }\n}\n\n.arrowLeft {\n  left: 0;\n\n  border-top-right-radius: @button-border-radius;\n  border-bottom-right-radius: @button-border-radius;\n}\n\n.arrowRight {\n  right: 0;\n\n  border-top-left-radius: @button-border-radius;\n  border-bottom-left-radius: @button-border-radius;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrow": "_5J82Zc1ODkARZGhrtTHB3Q==",
	"arrowLeft": "F3BlzOu-KjQx25uNt0tBnQ==",
	"arrowRight": "ODiy1xmEQ70M53-Az-bEEw=="
};
export default ___CSS_LOADER_EXPORT___;
