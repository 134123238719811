import { Row } from 'antd';
import { Control, useWatch } from 'react-hook-form';

import { SubclaimCreateCommentAndDocumentsForm } from '../SubclaimCreateCommentAndDocumentsForm';
import {
  AREA_ID_NAME,
  LIABLE_PARTY_TYPE_NAME,
  SubclaimForm,
} from '../SubclaimForm';
import { CLAIM_GROUP_ID_NAME, ClaimGroupControl } from '../claim-group-control';
import { useCanEditFields } from '../hooks/useCanEditFields';
import { Entity } from '../overview/selectors';

import { RetailClaimGroupsOptionsQuery } from '@/apollo/gql-types';

interface Props {
  claim?: Entity;
  control: Control;
  claimGroups: RetailClaimGroupsOptionsQuery;
  errors: unknown;
}

export function ClaimCreateForm({
  claim,
  control,
  claimGroups,
  errors,
}: Props) {
  const [claimGroupId, areaId, liablePartyType] = useWatch({
    control,
    name: [CLAIM_GROUP_ID_NAME, AREA_ID_NAME, LIABLE_PARTY_TYPE_NAME],
  });

  const canEditFields = useCanEditFields(claim);
  const isGroupEditable = Boolean(areaId);
  const isEmptySubClaimFields = Boolean(!areaId || !liablePartyType);
  const isGroupSelected = Boolean(claimGroupId);

  return (
    <>
      <Row>
        <ClaimGroupControl
          claimGroups={claimGroups}
          control={control}
          disabled={
            (!isGroupEditable && isGroupSelected && !isEmptySubClaimFields) ||
            !canEditFields
          }
          isGroupSelected={isGroupSelected}
        />
      </Row>
      {isGroupSelected && (
        <>
          <SubclaimForm
            allowClearAreaPartDamage
            claim={claim}
            control={control}
            claimGroupId={claimGroupId}
            claimGroups={claimGroups}
          />
          <SubclaimCreateCommentAndDocumentsForm
            claim={claim}
            control={control}
            errors={errors}
          />
        </>
      )}
    </>
  );
}
