import { DELIVERY_TYPES } from '@retail/order-constants';
import { get } from 'lodash/fp';
import { createSelector } from 'reselect';

export const getDeliveryTypeLabel = createSelector(
  get('deliveryType'),
  (deliveryType: string) => {
    if (deliveryType === DELIVERY_TYPES.HOME) {
      return 'Home delivery';
    } else if (
      [DELIVERY_TYPES.NEAREST_BRANCH, DELIVERY_TYPES.MIN_ETA_BRANCH].includes(
        deliveryType,
      )
    ) {
      return 'Branch pickup';
    }
  },
);
