import { addMonths, formatISO } from 'date-fns';

import { RootSelectorResult } from '../../selectors';

import { CarDetails } from './CarDetails';
import { OrderSummary } from './OrderSummary';
import { WarrantyDetails } from './WarrantyDetails';
import { useShouldShowAdditionalOrderWarrantyDetails } from './useShouldShowAdditionalOrderWarrantyDetails';

import { useFormatDate } from '@/helpers/date';

interface OrderSummaryProps {
  loading: boolean;
  data: RootSelectorResult;
}

export function OrderAsideInfo({ data, loading }: OrderSummaryProps) {
  const {
    car,
    vin,
    price,
    mileage,
    warranty: warrantyFromOrder,
    deliveryType,
    retailCountry,
    manufacturingYear,
    licensePlate,
    paymentType,
    warrantyDetails,
    vehicleDeliveredOn,
    linkedWarranty: warrantyFromLinkedOrder,
    linkedOrderCompleted,
    type,
    linkedOrders,
  } = data;

  const shouldShowAdditionalOrderWarrantyDetails =
    useShouldShowAdditionalOrderWarrantyDetails(type, linkedOrders);
  const warranty = shouldShowAdditionalOrderWarrantyDetails
    ? warrantyFromLinkedOrder
    : warrantyFromOrder;
  const expiryDate = shouldShowAdditionalOrderWarrantyDetails
    ? vehicleDeliveredOn
      ? formatISO(
          addMonths(new Date(vehicleDeliveredOn), warranty?.monthsCovered || 0),
        )
      : null
    : warrantyDetails?.expiryDate;
  const warrantyExpiryDate = useFormatDate(expiryDate);
  return (
    <>
      <WarrantyDetails
        loading={loading}
        warranty={warranty}
        warrantyUpgradeDate={
          shouldShowAdditionalOrderWarrantyDetails ? linkedOrderCompleted : null
        }
        warrantyDetails={warrantyDetails}
        warrantyExpiryDate={warrantyExpiryDate}
      />
      <OrderSummary
        deliveryType={deliveryType}
        loading={loading}
        paymentType={paymentType}
        retailCountry={retailCountry}
      />
      <CarDetails
        car={car}
        licensePlate={licensePlate}
        loading={loading}
        manufacturingYear={manufacturingYear}
        mileage={mileage}
        price={price}
        vin={vin}
      />
    </>
  );
}
