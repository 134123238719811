import { getCurrencyCodeByCountryCode } from '@retail/currency';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Price } from '../overview/content/compensations/price';
import { RootSelectorResult } from '../selectors';

import { InfoCard } from './InfoCard';

import { InlineKeyValueRow } from '@/components/InlineKeyValueRow';

interface TotalCostProps {
  loading: boolean;
  data: {
    claimsTotalCost: RootSelectorResult['claimsTotalCost'];
    retailCountry: RootSelectorResult['retailCountry'];
  };
}

export const TotalCost = memo<TotalCostProps>(({ data, loading }) => {
  const { t } = useTranslation();
  const { claimsTotalCost, retailCountry } = data;

  const currencyCode = useMemo(
    () => getCurrencyCodeByCountryCode(retailCountry),
    [retailCountry],
  );

  return (
    <InfoCard size="small" loading={loading}>
      <InlineKeyValueRow
        qaSelector="claimTotalCost"
        label={t('bo.orderClaims.orderSummary.title.claimsTotalCost')}
        value={
          claimsTotalCost ? (
            <Price
              price={{ amountMinorUnits: claimsTotalCost, currencyCode }}
            />
          ) : null
        }
      />
    </InfoCard>
  );
});
