import { ReactNode } from 'react';

import { NA } from './NA';

interface Props {
  label: ReactNode;
  value: ReactNode;
  qaSelector: string;
  className?: string;
}

export function InlineKeyValueRow({
  label,
  value,
  qaSelector,
  className,
}: Props) {
  return (
    <div data-qa-selector={qaSelector} className={className}>
      <span>{`${label}: `}</span>
      <strong>{value ?? <NA />}</strong>
    </div>
  );
}
