import { OptionModel } from '@retail/backoffice-ui';
import {
  defaults,
  filter,
  find,
  flow,
  get,
  getOr,
  map,
  sortBy,
} from 'lodash/fp';
import { TFunction } from 'react-i18next';
import { createSelector, createStructuredSelector } from 'reselect';

import { claimGroupsSelector } from '../selectors';

import {
  RetailClaimAreaProjection,
  RetailClaimGroupsOptionsQuery,
} from '@/apollo/gql-types';

interface SelectorInput {
  claimGroups: RetailClaimGroupsOptionsQuery;
  claimGroupId: string;
  damageId: string;
  areaId: string;
  carPartId: string;
  t: TFunction<'translation', undefined>;
}

interface SelectorOutput {
  labels: string[];
  areaOptions: OptionModel[];
  liablePartyOptions: OptionModel[];
  damageOptions: OptionModel[];
  carPartOptions: OptionModel[];
}

const claimGroupSelector = createSelector(
  claimGroupsSelector,
  ({ claimGroupId }: SelectorInput) => claimGroupId,
  (data, id) => find({ id })(data),
);

const liablePartyOptions = createSelector(
  claimGroupSelector,
  ({ t }: SelectorInput) => t,
  (data, t) =>
    flow(
      get(['liableParties']),
      map(({ value, name }) => ({
        value,
        label: t(name),
      })),
      sortBy<OptionModel>('label'),
    )(data),
);

const areasOptionsSelector = createSelector(
  claimGroupSelector,
  /** @todo check [] */
  getOr<RetailClaimAreaProjection[]>([], ['areas']),
);

const carPartOptionsSelector = createSelector(
  areasOptionsSelector,
  ({ areaId }: SelectorInput) => areaId,
  (areas, areaId) =>
    flow(find({ id: areaId }), get('carParts'), defaults([]))(areas),
);

const damageOptionsSelector = createSelector(
  carPartOptionsSelector,
  ({ carPartId }: SelectorInput) => carPartId,
  (carParts, carPartId) =>
    flow(find({ id: carPartId }), get('damages'), defaults([]))(carParts),
);

const translatorSelector = ({ t }: SelectorInput) => t;

const mapOptions = <T extends { id: string; name: string; deleted?: boolean }>(
  data: T[],
  t: TFunction<'translation', undefined>,
) =>
  flow(
    filter(({ deleted }) => !deleted),
    map(({ id: value, name }: T) => ({ value, label: t(name) })),
    sortBy<OptionModel>('label'),
  )(data);

const labelsSelector = createSelector(
  claimGroupSelector,
  translatorSelector,
  (data, t) =>
    flow(
      get(['labels']),
      map((label) => t(label as string)),
    )(data),
);

export default createStructuredSelector<SelectorInput, SelectorOutput>({
  labels: flow(labelsSelector),
  liablePartyOptions,
  areaOptions: createSelector(
    areasOptionsSelector,
    translatorSelector,
    mapOptions,
  ),
  carPartOptions: createSelector(
    carPartOptionsSelector,
    translatorSelector,
    mapOptions,
  ),
  damageOptions: createSelector(
    damageOptionsSelector,
    translatorSelector,
    mapOptions,
  ),
});
