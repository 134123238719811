import { Modal, Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { makeVar, useReactiveVar } from '@apollo/client';
import { memo, SyntheticEvent, useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import { getCreateCommentPayload } from '../utils';
import { CLAIM_GROUP_ID_NAME } from '../claim-group-control';

import { useFeatureCreateClaim } from './useFeatureCreateClaim';
import { ClaimCreateForm } from './form';

import {
  RetailClaimGroupsOptionsQuery,
  RetailSubClaimCreateProjectionInput,
} from '@/apollo/gql-types';

const modalVars = makeVar(false);

export const hideModal = () => modalVars(false);
export const showModal = () => modalVars(true);

export interface ClaimCreateFormValues
  extends Omit<RetailSubClaimCreateProjectionInput, 'comment'> {
  newDocuments?: File[];
  claimGroupId?: string;
  comment?: string;
}

interface Props {
  orderId: string;
  language: string;
  retailCountry: string;
  claimGroups: RetailClaimGroupsOptionsQuery;
}

export const ClaimCreateModal = memo<Props>(
  ({ orderId, language, retailCountry, claimGroups }) => {
    const visible = useReactiveVar(modalVars);
    const { t } = useTranslation();
    const createClaim = useFeatureCreateClaim({
      orderId,
      language,
      retailCountry,
    });

    const form = useForm<ClaimCreateFormValues>({
      defaultValues: {},
      mode: 'all',
    });
    const {
      control,
      formState: { isSubmitting, errors, isDirty, isValid },
      handleSubmit,
      reset,
    } = form;

    useEffect(() => {
      if (visible) {
        reset({});
      }
    }, [visible, reset]);

    const [claimGroupId] = useWatch({
      control,
      name: [CLAIM_GROUP_ID_NAME],
    });

    const isGroupSelected = Boolean(claimGroupId);
    const disabled = !isDirty || !isValid;

    async function onHandleSubmit(event: SyntheticEvent) {
      if (disabled) {
        return;
      }

      await handleSubmit(({ comment, ...rest }) =>
        createClaim({
          ...rest,
          comment: getCreateCommentPayload({ comment })?.retailSubClaimComment,
        }),
      )(event);

      hideModal();
    }

    return (
      <Modal
        centered
        visible={visible}
        width={isGroupSelected ? 1000 : 500}
        onCancel={hideModal}
        title={<span>{`${t('bo.orderClaims.form.requestClaim')}: `}</span>}
        footer={
          <>
            <Button type="default" onClick={hideModal}>
              <span data-qa-selector="cancel-subclaim-creation">
                {t('bo.orderClaims.form.cancelBtnText')}
              </span>
            </Button>
            <Button
              type="primary"
              loading={isSubmitting}
              disabled={disabled}
              onClick={onHandleSubmit}
            >
              <span data-qa-selector="create-subclaim">
                {t('bo.orderClaims.form.saveBtnText')}
              </span>
            </Button>
          </>
        }
      >
        <Card>
          <FormProvider {...form}>
            <form onSubmit={onHandleSubmit}>
              <ClaimCreateForm
                control={control}
                claimGroups={claimGroups}
                errors={errors}
              />
            </form>
          </FormProvider>
        </Card>
      </Modal>
    );
  },
);
