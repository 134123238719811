import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';
import { Card } from 'antd';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { TClaimOrderDetails } from '../../../selectors';
import { Entity } from '../../selectors';

import { DueDate } from './DueDate';
import { OrderDetails } from './OrderDetails';
import { AssignUser } from './assign-user';
import { ClaimStatus } from './claim-status';
import cn from './styles.less';

import { DOMAINS, PERMISSIONS } from '@/constants/permissions';
import { ClaimNextAction } from '@/components/ClaimNextAction';

interface AdditionalInfoProps {
  claim: Entity;
  claimOrderDetails: TClaimOrderDetails;
  loading: boolean;
}

export const AdditionalInfo = memo<AdditionalInfoProps>(
  ({ loading, claim, claimOrderDetails }) => {
    const { adId } = claimOrderDetails;
    const { t } = useTranslation();
    const assignClaimsPermission = useCheckPermissions({
      allow: PERMISSIONS.MUTATION_ASSIGN_CLAIMS,
      domain: DOMAINS.CLAIM_MANAGEMENT,
    });

    return (
      <Card loading={loading}>
        <div className={cn.content}>
          <div>
            <div className={cn.contentControls}>
              <ClaimStatus claim={claim} />
              <AssignUser
                claimId={claim?.id}
                country={claim?.country}
                assignTo={claim?.assignTo}
                disabled={!assignClaimsPermission.isAllowed}
              />
              <DueDate claim={claim} />
            </div>
            <ClaimNextAction
              bordered
              claimId={claim?.id}
              defaultValue={claim?.stateComment}
              label={t('bo.orderClaims.nextAction')}
              refetchQueries={['SearchRetailClaims']}
            />
          </div>
          <OrderDetails
            order={claim?.order}
            adId={adId}
            claimOrderDetails={claimOrderDetails}
            claim={claim}
          />
        </div>
      </Card>
    );
  },
);
