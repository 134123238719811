import { TClaimOrderDetails } from 'src/pages/claims/selectors';

import { InfoSection } from '../InfoSection';

import { CarHandoverOn } from './CarHandoverOn';
import { HandoverDocuments } from './HandoverDocuments';
import { RightOfReturnUntil } from './RightOfReturnUntil';

interface Props {
  claimOrderDetails: TClaimOrderDetails;
}

export function CarInfo({ claimOrderDetails }: Props) {
  const { vehicleDeliveredOn } = claimOrderDetails;

  return (
    <InfoSection data-qa-selector="car-info">
      <CarHandoverOn vehicleDeliveredOn={vehicleDeliveredOn} />
      <HandoverDocuments />
      <RightOfReturnUntil claimOrderDetails={claimOrderDetails} />
    </InfoSection>
  );
}
