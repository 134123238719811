import { DeleteOutlined } from '@ant-design/icons';
import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { CLAIM_STATES } from '../../../constants';
import { Entity } from '../../selectors';

import { useDeleteRetailSubClaimDocumentMutation } from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';
import { DOMAINS, PERMISSIONS } from '@/constants/permissions';

interface Props {
  id: string;
  fileName: string;
  claimState: Entity['state'];
}

export function DeleteDocumentButton({ id, fileName, claimState }: Props) {
  const { t } = useTranslation();
  const [onSubmit, { loading }] = useDeleteRetailSubClaimDocumentMutation({
    ...REQUEST_BASE_OPTIONS,
    refetchQueries: ['SearchRetailClaims'],
    variables: { id },
  });

  const { isAllowed: canDeleteForNotClosedClaim } = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_CLAIMS_EDIT_ORDER_CLAIMS,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });
  const { isAllowed: canDeleteForClosedClaim } = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_CLAIMS_EDIT_ORDER_CLAIMS_CLOSED,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });

  function onClick() {
    if (
      window.confirm(
        t('bo.orderClaims.processing.deleteAttachment.warning', {
          replace: { fileName },
        }),
      )
    ) {
      onSubmit();
    }
  }

  const canDeleteDocument =
    (claimState === CLAIM_STATES.CLOSED && canDeleteForClosedClaim) ||
    (claimState !== CLAIM_STATES.CLOSED &&
      (canDeleteForNotClosedClaim || canDeleteForClosedClaim));

  if (!canDeleteDocument) {
    return null;
  }

  return (
    <Button
      danger
      size="small"
      type="primary"
      loading={loading}
      data-qa-selector="subclaim-delete"
      icon={<DeleteOutlined />}
      onClick={onClick}
    />
  );
}
