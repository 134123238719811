import { notification } from 'antd';
import { get } from 'lodash/fp';
import { ErrorOption } from 'react-hook-form';

import { useDistributeRetailClaimCostsMutation } from '@/apollo/gql-types';

export function useUpdateSubclaimCosts(
  setError: (name: string, value: ErrorOption) => void,
  hideModal: () => void,
) {
  const [save] = useDistributeRetailClaimCostsMutation({
    refetchQueries: ['SearchRetailClaims'],
    awaitRefetchQueries: true,
    onError: (error) => {
      setError('total', {
        type: 'graphQLErrors',
        message: get('0.message')(error?.graphQLErrors),
      });
    },
    onCompleted: () => {
      hideModal();
      notification.success({
        message: 'Saved successfully',
      });
    },
  });

  return save;
}
