import { SelectControlled } from '@retail/backoffice-ui';
import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';
import { Tooltip } from 'antd';
import cns from 'classnames';
import { find, get, isEqual, map } from 'lodash/fp';
import { memo, useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  CLAIM_COST_EDIT_STATES,
  CLAIM_STATES,
  CUSTOMER_DECISION,
  CUSTOMER_DECISION_OPTIONS,
} from '../../../../constants';
import { Comments } from '../../comments';
import { Status } from '../status';

import cn from './styles.local.less';

import {
  RetailClaimCostFragmentFragment,
  RetailSubClaimCommentProjection,
  useSetRetailClaimCostCustomerDecisionMutation,
} from '@/apollo/gql-types';
import { DOMAINS, PERMISSIONS } from '@/constants/permissions';
import { useFormatDateTime } from '@/helpers/date/hooks';

const CUSTOMER_DECISION_TO_TRANSLATION_KEY: Record<string, string> = {
  [CUSTOMER_DECISION.OFFER_SENT]:
    'bo.orderClaims.processing.customerDecisionStatus.offerSent',
  [CUSTOMER_DECISION.NOT_REQUIRED]:
    'bo.orderClaims.processing.customerDecisionStatus.notRequired',
  [CUSTOMER_DECISION.OFFER_NOT_SENT]:
    'bo.orderClaims.processing.customerDecisionStatus.offerNotSent',
  [CUSTOMER_DECISION.OFFER_ACCEPTED]:
    'bo.orderClaims.processing.customerDecisionStatus.offerAccepted',
  [CUSTOMER_DECISION.OFFER_NOT_ACCEPTED]:
    'bo.orderClaims.processing.customerDecisionStatus.offerNotAccepted',
};

interface CustomerDecisionProps {
  cost: RetailClaimCostFragmentFragment;
  getComments: (
    cost: RetailClaimCostFragmentFragment['comments'],
  ) => RetailSubClaimCommentProjection[];
  orderClaimStatus: CLAIM_STATES;
}

export const CustomerDecision = memo<CustomerDecisionProps>(
  ({ cost, getComments, orderClaimStatus }) => {
    const { t } = useTranslation();
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const hasClaimCostCustomerDecisionPermission = useCheckPermissions({
      allow: PERMISSIONS.MUTATION_SET_CLAIM_COST_CUSTOMER_DECISION,
      domain: DOMAINS.CLAIM_MANAGEMENT,
    });

    const hasClaimCostCustomerDecisionForcePermission = useCheckPermissions({
      allow: PERMISSIONS.MUTATION_SET_CLAIM_COST_CUSTOMER_DECISION_FORCE,
      domain: DOMAINS.CLAIM_MANAGEMENT,
    });
    const isEditable = isEqual(
      get(['editState'], cost),
      CLAIM_COST_EDIT_STATES.EDITABLE,
    );

    const { control } = useForm<{ customerDecisionStatus?: unknown }>({
      defaultValues: {},
      mode: 'all',
    });

    const comments = useMemo(
      () => getComments(cost.comments ?? []),
      [cost.comments, getComments],
    );
    const options = useMemo(
      () =>
        map(({ value, label }) => ({ value, label: t(label) }))(
          CUSTOMER_DECISION_OPTIONS,
        ),
      [t],
    );
    const formattedDate = useFormatDateTime(cost.customerDecisionSetOn);

    const [setRetailClaimCostCustomerDecision, { loading }] =
      useSetRetailClaimCostCustomerDecisionMutation({
        awaitRefetchQueries: true,
        refetchQueries: ['SearchRetailClaims', 'AllowedClaimStates'],
      });

    const setRetailClaimCostApprove3Handler = useCallback(
      ({ customerDecision, costId }) =>
        setRetailClaimCostCustomerDecision({
          variables: {
            customerDecision,
            costId,
          },
        }),
      [setRetailClaimCostCustomerDecision],
    );

    const customerDecision =
      find({ value: cost.customerDecision })(options) || null;

    const tooltip = get(['label'])(customerDecision);

    if (cost.customerDecision === CUSTOMER_DECISION.NOT_REQUIRED) {
      return (
        <Status>
          {t('bo.orderClaims.processing.customerDecisionStatus.notRequired')}
        </Status>
      );
    }

    const onVisibleChange = (visible: boolean) => {
      setTooltipVisible(visible);
    };

    const onDecisionChange = (customerDecision: string) => {
      if (customerDecision !== cost.customerDecision) {
        setRetailClaimCostApprove3Handler({
          customerDecision,
          costId: cost.id,
        });
      }

      onVisibleChange(false);
    };

    if (
      (hasClaimCostCustomerDecisionForcePermission.isAllowed ||
        (orderClaimStatus !== CLAIM_STATES.CLOSED &&
          (([
            CUSTOMER_DECISION.OFFER_NOT_SENT,
            CUSTOMER_DECISION.OFFER_SENT,
          ].includes(
            CUSTOMER_DECISION[cost.customerDecision as CUSTOMER_DECISION],
          ) &&
            hasClaimCostCustomerDecisionPermission.isAllowed) ||
            hasClaimCostCustomerDecisionForcePermission.isAllowed))) &&
      isEditable
    ) {
      return (
        <div className={cn.customerDecision}>
          <Tooltip
            overlay={t(tooltip)}
            onVisibleChange={onVisibleChange}
            visible={tooltipVisible}
          >
            <div
              data-qa-selector="select-customer-decision-status"
              className={cn.selectWrapper}
            >
              <SelectControlled
                options={options}
                disabled={loading}
                value={customerDecision?.value}
                onChange={onDecisionChange}
                className={cn.select}
                controllerProps={{
                  name: 'customerDecisionStatus',
                  control,
                }}
              />
            </div>
          </Tooltip>
          {cost.customerDecision !== CUSTOMER_DECISION.OFFER_NOT_SENT && (
            <div className={cn.submitter}>
              <div data-qa-selector="customer-decision-approved-by">
                {`${get(
                  'firstName',
                  cost.customerDecisionSetByProjection,
                )} ${get(
                  'lastName',
                  cost.customerDecisionSetByProjection,
                )} ${formattedDate}`}
              </div>
            </div>
          )}
          {comments.length > 0 && (
            <div className={cn.comments}>
              <Comments
                list={comments}
                qaSelector="customer-decision-comments"
              />
            </div>
          )}
        </div>
      );
    }

    if (cost.customerDecision === CUSTOMER_DECISION.OFFER_NOT_SENT) {
      return (
        <Status>
          {t(
            CUSTOMER_DECISION_TO_TRANSLATION_KEY[
              CUSTOMER_DECISION.OFFER_NOT_SENT
            ],
          )}
        </Status>
      );
    }

    if (cost.customerDecision === CUSTOMER_DECISION.OFFER_SENT) {
      return (
        <Status>
          {t(
            CUSTOMER_DECISION_TO_TRANSLATION_KEY[CUSTOMER_DECISION.OFFER_SENT],
          )}
        </Status>
      );
    }

    return (
      <div className={cn.customerDecision}>
        <div
          className={cns(cn.selectWrapper, {
            [cn.approved]:
              cost.customerDecision === CUSTOMER_DECISION.OFFER_ACCEPTED,
            [cn.notApproved]:
              cost.customerDecision === CUSTOMER_DECISION.OFFER_NOT_ACCEPTED,
          })}
          data-qa-selector="customer-decision-status"
        >
          <Status>
            {t(CUSTOMER_DECISION_TO_TRANSLATION_KEY[cost.customerDecision])}
          </Status>
        </div>
        <div
          className={cn.submitter}
          data-qa-selector="customer-decision-approved-by"
        >
          {`${get('firstName', cost.customerDecisionSetByProjection)} ${get(
            'lastName',
            cost.customerDecisionSetByProjection,
          )} ${formattedDate}`}
        </div>
        {comments.length > 0 && (
          <div className={cn.comments}>
            <Comments list={comments} qaSelector="customer-decision-comments" />
          </div>
        )}
      </div>
    );
  },
);
