// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._29i9KAJCh9hKz2Wr4rAG8Q\\=\\= {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  row-gap: 1rem;\n  column-gap: 0.5rem;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/content/additional-info/OrderDetails/InfoSection/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,aAAA;EACA,kBAAA;AACF","sourcesContent":[".content {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  row-gap: 1rem;\n  column-gap: 0.5rem;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "_29i9KAJCh9hKz2Wr4rAG8Q=="
};
export default ___CSS_LOADER_EXPORT___;
