// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QdTfrxP2bwmAnluQqRz7gw\\=\\= {\n  margin-bottom: 20px;\n}\n._7-BQSmXmt8ZR1N6N9rz\\+ZA\\=\\= {\n  display: inline-block;\n  margin-bottom: 15px;\n  font-size: 20px;\n  text-transform: uppercase;\n}\n._0p00GXzSkKayvO-dhlKleQ\\=\\= .antd-4-21-7-picker,\n._0p00GXzSkKayvO-dhlKleQ\\=\\= .antd-4-21-7-picker-group-wrapper {\n  width: 100%;\n}\n.MAU\\+sW1PY29plEQxiyGFFA\\=\\= {\n  margin-left: auto;\n  font-size: 20px;\n  color: #FF0000;\n}\n.RzveHKocY2YPiEaUC0KLcg\\=\\= {\n  display: flex !important;\n  align-items: center;\n  justify-content: flex-end;\n}\n._4F4jKquxv73AbXShIaLgxw\\=\\= {\n  display: flex;\n  align-items: center;\n}\n.Xb-0THUHXJO5gNg7zCF5uA\\=\\= {\n  text-align: left;\n  width: 50%;\n  margin-right: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/content/request-proof-modal/styles.less"],"names":[],"mappings":"AAEA;EACE,mBAAA;AADF;AAIA;EACE,qBAAA;EAEA,mBAAA;EAEA,eAAA;EACA,yBAAA;AAJF;AAOA;;EAIM,WAAA;AAPN;AAYA;EACE,iBAAA;EACA,eAAA;EACA,cAAA;AAVF;AAaA;EACE,wBAAA;EACA,mBAAA;EACA,yBAAA;AAXF;AAeA;EACE,aAAA;EACA,mBAAA;AAbF;AAgBA;EACE,gBAAA;EACA,UAAA;EACA,kBAAA;AAdF","sourcesContent":["@import '../../../../../styles/constants.less';\n\n.offset {\n  margin-bottom: 20px;\n}\n\n.total {\n  display: inline-block;\n\n  margin-bottom: 15px;\n\n  font-size: 20px;\n  text-transform: uppercase;\n}\n\n.form {\n  :global {\n    .@{antd-version}-picker,\n    .@{antd-version}-picker-group-wrapper {\n      width: 100%;\n    }\n  }\n}\n\n.deleteIcon {\n  margin-left: auto;\n  font-size: 20px;\n  color: #FF0000;\n}\n\n.alignEnd {\n  display: flex !important;\n  align-items: center;\n  justify-content: flex-end;\n}\n\n\n.modalFooter {\n  display: flex;\n  align-items: center;\n}\n\n.modalFooterText {\n  text-align: left;\n  width: 50%;\n  margin-right: auto;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"offset": "QdTfrxP2bwmAnluQqRz7gw==",
	"total": "_7-BQSmXmt8ZR1N6N9rz+ZA==",
	"form": "_0p00GXzSkKayvO-dhlKleQ==",
	"deleteIcon": "MAU+sW1PY29plEQxiyGFFA==",
	"alignEnd": "RzveHKocY2YPiEaUC0KLcg==",
	"modalFooter": "_4F4jKquxv73AbXShIaLgxw==",
	"modalFooterText": "Xb-0THUHXJO5gNg7zCF5uA=="
};
export default ___CSS_LOADER_EXPORT___;
