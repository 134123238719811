import { CUSTOMER_TYPES } from '@retail/order-constants';
import { isPast, parseISO } from 'date-fns';
import {
  at,
  defaultTo,
  filter,
  flatMap,
  flow,
  get,
  getOr,
  head,
  includes,
  join,
  reduce,
  uniq,
  sortBy,
} from 'lodash/fp';
import { createSelector, createStructuredSelector } from 'reselect';

import { getClaimTotalCost, getOrderClaimsStatus } from './utils';

import {
  ClaimOrderDetailsQuery,
  MileageDto,
  OrderAndClaimsQuery,
  RetailClaimGroupProjection,
  RetailClaimProjection,
  RetailWarrantyProjection,
  SearchRetailClaimsQuery,
  WarrantyDetailsProjection,
  LoadLinkedOrdersQuery,
  OrderPaymentState,
  OrderType,
} from '@/apollo/gql-types';
import {
  getDeliveryTypeLabel,
  getRetailCustomerFullName,
} from '@/helpers/order';
import { FormatCurrencyOptions } from '@/utils/i18n/useFormatCurrency';

// NOTE Common selectors to get data
export const orderSelector = createSelector(
  get(['order', 'order']),
  defaultTo({}),
);
export const claimsSelector = createSelector(
  getOr([], ['claims', 'claims', 'entities']),
  /** @todo test [] */
  defaultTo<RetailClaimProjection[]>([]),
);
export const claimGroupsSelector = createSelector(
  get(['claimGroups', 'claimGroups']),
  defaultTo<RetailClaimGroupProjection[]>([]),
);

const orderClaimsStatesSelector = createSelector(
  claimsSelector,
  flow(flatMap(get(['state'])), uniq),
);
export const orderClaimsStatusSelector = createSelector(
  orderClaimsStatesSelector,
  getOrderClaimsStatus,
);

export const orderClaimsCostsSelector = createSelector(
  claimsSelector,
  flow(head, get('costs'), defaultTo([])),
);

export const claimTotalCostSelector = createSelector(
  orderClaimsCostsSelector,
  getClaimTotalCost,
);

export const adSelector = createSelector(
  orderSelector,
  flow(get('adItems'), head, get('ad')),
);

export const carSelector = createSelector(
  adSelector,
  flow(get('vehicle'), (vehicle) => {
    const modelApi = getOr({}, ['modelApi'], vehicle);
    return flow(
      at(['make', 'model', 'subModel']),
      filter(Boolean),
      join(' '),
    )(modelApi);
  }),
);

export const vehicleSelector = createSelector(adSelector, get('vehicle'));
export const warrantyStateSelector = createSelector(
  claimsSelector,
  get('warranty'),
);

type Customer = OrderAndClaimsQuery['order']['customer'];
type FullCustomer = Customer & {
  fullName: string;
  isCorporateType: boolean;
};

export const customerSelector = createSelector(
  orderSelector,
  flow(get('customer'), (customer) => {
    if (!customer) {
      return {};
    }

    const { customerType, ...attr } = customer;

    return {
      ...attr,
      customerType,
      fullName: getRetailCustomerFullName(customer),
      isCorporateType: includes(customerType, [
        CUSTOMER_TYPES.COMPANY,
        CUSTOMER_TYPES.DEALER,
      ]),
    };
  }),
);

export const orderLinkedSelector = createSelector(
  get(['linkedOrders', 'linkedOrders', 'entities']),
  defaultTo([]),
);

const sortLinkedOrderByCreatedDate = sortBy(({ created }) => new Date(created));
const getLinkedOrderWithFullPaidStatus = filter(
  ({ paymentStatus, warrantyDetails }) =>
    Boolean(
      paymentStatus === OrderPaymentState.FullyPaid &&
        Array.isArray(warrantyDetails) &&
        warrantyDetails.length,
    ),
);

const mapLinkedOrders = flow(
  getLinkedOrderWithFullPaidStatus,
  sortLinkedOrderByCreatedDate,
  head,
);

export interface RootSelectorOptions {
  order: ClaimOrderDetailsQuery;
  claims: SearchRetailClaimsQuery;
  linkedOrders: LoadLinkedOrdersQuery;
}

export interface RootSelectorResult {
  id: string;
  type: OrderType;
  linkedOrders: LoadLinkedOrdersQuery['linkedOrders']['entities'];
  car: string;
  vin: string;
  adId: string;
  mileage: MileageDto;
  vehicle: string;
  stockNumber: string;
  orderNumber: string;
  deliveryType: string;
  licensePlate: string;
  retailCountry: string;
  customer: FullCustomer;
  trialExpiredOn: string;
  isTrialExpired: boolean;
  orderClaimsStatus: string;
  manufacturingYear: string;
  vehicleDeliveredOn: string;
  price: FormatCurrencyOptions;
  warranty: RetailWarrantyProjection;
  warrantyDetails: WarrantyDetailsProjection;
  claimsTotalCost: number;
  paymentType: string;
  linkedOrderNumber: string;
  linkedOrderId: string;
  linkedOrderCompleted: string;
  linkedWarranty: RetailWarrantyProjection;
  linkedWarrantyDetails: WarrantyDetailsProjection;
}

export type TClaimOrderDetails = RootSelectorResult;

export const claimSelector = createStructuredSelector<
  RootSelectorOptions,
  RootSelectorResult
>({
  // Summary
  id: flow(orderSelector, get('id')),
  type: flow(orderSelector, get('type')),
  linkedOrders: orderLinkedSelector,
  orderClaimsStatus: orderClaimsStatusSelector,
  vehicle: flow(adSelector, getOr({}, 'vehicle')),
  stockNumber: flow(orderSelector, get('stockNumber')),
  orderNumber: flow(orderSelector, get('orderNumber')),
  retailCountry: flow(orderSelector, get('retailCountry')),
  linkedOrderId: flow(
    orderLinkedSelector,
    getLinkedOrderWithFullPaidStatus,
    head,
    get('id'),
  ),
  linkedOrderNumber: flow(
    orderLinkedSelector,
    getLinkedOrderWithFullPaidStatus,
    head,
    get('orderNumber'),
  ),
  // Car details
  car: flow(carSelector),
  adId: flow(adSelector, getOr({}, 'id')),
  vin: flow(vehicleSelector, get(['vin'])),
  licensePlate: flow(vehicleSelector, get(['licensePlate'])),
  price: flow(adSelector, getOr({}, 'price')),
  manufacturingYear: flow(vehicleSelector, get(['builtYear'])),
  mileage: flow(vehicleSelector, get(['mileage'])),
  // Order details
  trialExpiredOn: flow(orderSelector, get('trialExpiredOn')),
  vehicleDeliveredOn: flow(orderSelector, get('vehicleDeliveredOn')),
  isTrialExpired: flow(orderSelector, get('trialExpiredOn'), parseISO, isPast),
  deliveryType: flow(
    orderSelector,
    get('deliveryDetails'),
    getDeliveryTypeLabel,
  ),
  // Warranty details
  warranty: flow(
    orderSelector,
    getOr([], ['warrantyDetails']),
    head,
    getOr({}, ['warranty']),
  ),
  warrantyDetails: flow(orderSelector, getOr([], ['warrantyDetails']), head),
  linkedWarranty: flow(
    orderLinkedSelector,
    mapLinkedOrders,
    getOr([], ['warrantyDetails']),
    head,
    getOr(null, ['warranty']),
  ),
  linkedWarrantyDetails: flow(
    orderLinkedSelector,
    mapLinkedOrders,
    getOr(null, ['warrantyDetails']),
    head,
  ),
  linkedOrderCompleted: flow(
    orderLinkedSelector,
    getLinkedOrderWithFullPaidStatus,
    head,
    get('completed'),
  ),
  // Customer details
  customer: flow(customerSelector),
  claimsTotalCost: flow(
    claimsSelector,
    reduce((sum, { costs }) => sum + getClaimTotalCost(costs), 0),
  ),
  paymentType: flow(orderSelector, get('paymentType')),
});
