import { SelectControlled } from '@retail/backoffice-ui';
import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';
import { find, get, isString } from 'lodash/fp';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CLAIM_STATES } from '../../../constants';
import {
  isCostTypeWorkshop,
  isRequiredSubClaimsValues,
  isWorkshopDetailsIncomplete,
  validateCloseState,
} from '../../helpers';
import { Entity } from '../../selectors';
import {
  CloseReasonModal,
  showModal as showCloseReasonModal,
} from '../close-reason';
import {
  RequestProofModal,
  showModal as showRequestProofModal,
} from '../request-proof-modal';

import { useAllowedClaimStatesAsync } from './useAllowedClaimStatesAsync';

import { useUpdateRetailOrderClaimStateMutation } from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';
import { FIELD_LABEL_COL } from '@/constants/common';
import { FEATURE } from '@/constants/features';
import { DOMAINS, PERMISSIONS } from '@/constants/permissions';
import { useFeatures } from '@/providers/FeaturesProvider';

interface Props {
  claim: Entity;
}

export function ClaimStatus({ claim }: Props) {
  const { t } = useTranslation();
  const features = useFeatures();

  const { control } = useForm<{ status: unknown }>({
    defaultValues: {},
    mode: 'all',
  });

  const isClaimCostProcessingEnabled =
    features[FEATURE.RETAIL_CLAIM_COST_PROCESSING];
  const isCloseReasonEnabled =
    features[FEATURE.RETAIL_CLAIM_CLOSED_REASON_BACKOFFICE];
  const { options: stateOptions, loading: stateLoading } =
    useAllowedClaimStatesAsync({ claimId: claim.id });

  const [updateStatus, { loading: updateStatusLoading }] =
    useUpdateRetailOrderClaimStateMutation({
      ...REQUEST_BASE_OPTIONS,
      awaitRefetchQueries: true,
      refetchQueries: ['SearchRetailClaims', 'AllowedClaimStates'],
    });

  const isWorkshopDetailsIncompleteResult = useMemo(
    () =>
      isWorkshopDetailsIncomplete({
        costs: claim?.costs,
        workshopDetails: claim?.workshopDetails,
      }),
    [claim?.costs, claim?.workshopDetails],
  );
  const isRequiredSubClaimsValuesResult = useMemo(
    () => isRequiredSubClaimsValues(claim?.subClaims, claim?.claimGroup),
    [claim?.claimGroup, claim?.subClaims],
  );

  const onStatusChange = useCallback(
    async (value) => {
      switch (value) {
        case CLAIM_STATES.ADDITIONAL_PROOF_REQUIRED: {
          showRequestProofModal({
            data: { claim },
          });
          break;
        }
        case CLAIM_STATES.CLOSED: {
          const error = validateCloseState(claim, t);

          if (isString(error) && isClaimCostProcessingEnabled) {
            return global.alert(error);
          }

          if (!isRequiredSubClaimsValuesResult) {
            return global.alert(
              t('bo.orderClaims.processing.requiredSubClaimsValues.warning'),
            );
          }

          if (
            isClaimCostProcessingEnabled &&
            claim?.costs?.length === 0 &&
            isCostTypeWorkshop(claim?.costs)
          ) {
            return global.alert(
              t('bo.orderClaims.processing.costEmpty.warning'),
            );
          }

          if (
            isClaimCostProcessingEnabled &&
            isWorkshopDetailsIncompleteResult
          ) {
            return global.alert(
              t('bo.orderClaims.processing.workshopDetailsIncomplete.warning'),
            );
          }

          const confirmed = global.confirm(
            t('bo.orderClaims.processing.confirmation.warning'),
          );
          if (!confirmed) {
            return;
          }

          if (isCloseReasonEnabled) {
            showCloseReasonModal({
              data: { claim },
            });
            return;
          }
        }
        // eslint-disable-next-line no-fallthrough
        case CLAIM_STATES.IN_PROGRESS:
        case CLAIM_STATES.AWAITING_REPAIR:
        case CLAIM_STATES.AWAITING_COST_ESTIMATE:
        case CLAIM_STATES.SUBMITTED:
        case CLAIM_STATES.PROOF_SUBMITTED:
        default: {
          await updateStatus({
            variables: {
              id: claim?.id,
              updateState: { state: value },
            },
          });
        }
      }
    },
    [
      claim,
      t,
      isClaimCostProcessingEnabled,
      isRequiredSubClaimsValuesResult,
      isWorkshopDetailsIncompleteResult,
      isCloseReasonEnabled,
      updateStatus,
    ],
  );

  const { isAllowed: hasEditOrderClaimsClosedPermission } = useCheckPermissions(
    {
      allow: PERMISSIONS.MUTATION_CLAIMS_EDIT_ORDER_CLAIMS_CLOSED,
      domain: DOMAINS.CLAIM_MANAGEMENT,
    },
  );
  const { isAllowed: hasEditOrderClaimsAllowedPermission } =
    useCheckPermissions({
      allow: PERMISSIONS.MUTATION_CLAIMS_EDIT_ORDER_CLAIMS,
      domain: DOMAINS.CLAIM_MANAGEMENT,
    });
  const { isAllowed: hasClaimsUpdateStatusPermission } = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_ORDER_CLAIMS_UPDATE_STATUS,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });

  const isClaimClosed = claim?.state === CLAIM_STATES.CLOSED;
  const canEditClosedClaimStatus =
    hasEditOrderClaimsClosedPermission || hasEditOrderClaimsAllowedPermission;
  const hasEditPermission = isClaimClosed
    ? canEditClosedClaimStatus
    : hasClaimsUpdateStatusPermission;

  return (
    <>
      <RequestProofModal />
      {isCloseReasonEnabled && <CloseReasonModal />}
      <div data-qa-selector="claim-status-dropdown" style={{ width: 200 }}>
        <SelectControlled
          labelCol={FIELD_LABEL_COL}
          showSearch
          label={t('bo.orderClaims.claimStatus')}
          onChange={onStatusChange}
          placeholder={t('bo.orderClaims.form.selectPlaceholder')}
          value={get(['value'], find({ value: claim?.state })(stateOptions))}
          options={stateOptions}
          disabled={updateStatusLoading || stateLoading || !hasEditPermission}
          controllerProps={{
            name: 'status',
            control,
          }}
        />
      </div>
    </>
  );
}
