import { SALUTATIONS } from '@retail/order-constants';

import { salutationLabelsMap } from '../fieldOptions/order/salutation';

interface Props {
  salutation: string;
  firstName: string;
  lastName: string;
}

export const getRetailCustomerFullName = ({
  salutation,
  firstName,
  lastName,
}: Props) => {
  const name = `${firstName} ${lastName}`;

  if (!salutation || salutation === SALUTATIONS.PREFER_NOT_TO_SAY) {
    return name;
  }

  return `${salutationLabelsMap[salutation]} ${name}`;
};
