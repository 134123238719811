// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".goQYTkoNsMmWm1gxxu5qaQ\\=\\= {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  gap: 30px;\n}\n.getKMlRqyx6hc5Xp352sOA\\=\\= {\n  display: flex;\n  column-gap: 1rem;\n  margin-right: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/content/additional-info/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,SAAA;AACF;AAEA;EACE,aAAA;EACA,gBAAA;EACA,kBAAA;AAAF","sourcesContent":[".content {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  gap: 30px;\n}\n\n.contentControls {\n  display: flex;\n  column-gap: 1rem;\n  margin-right: auto;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "goQYTkoNsMmWm1gxxu5qaQ==",
	"contentControls": "getKMlRqyx6hc5Xp352sOA=="
};
export default ___CSS_LOADER_EXPORT___;
