// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JEbZqZSuRn8TD4-R9Xaomg\\=\\= .antd-4-21-7-form-item-label {\n  padding-bottom: 0 !important;\n}\n.JEbZqZSuRn8TD4-R9Xaomg\\=\\= .antd-4-21-7-form-item {\n  margin-bottom: 0 !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClaimsOverview/DueDaysConfigButton/FormModal/styles.less"],"names":[],"mappings":"AAEA;EAGM,4BAAA;AAHN;AAAA;EAOM,2BAAA;AAJN","sourcesContent":["@import '../../../../styles/constants.less';\n\n.form {\n  :global {\n    .@{antd-version}-form-item-label {\n      padding-bottom: 0 !important;\n    }\n\n    .@{antd-version}-form-item {\n      margin-bottom: 0 !important;\n    }\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "JEbZqZSuRn8TD4-R9Xaomg=="
};
export default ___CSS_LOADER_EXPORT___;
