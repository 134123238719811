import { Sorts } from '@retail/gql-utils';

export const PAGE_SIZE = 20;
export const defaultCountry = 'de';
export const UNASSIGNED = 'unassigned';
export const DEFAULT_SORTING = Sorts.ask('createdOn');
export const FIELD_LABEL_COL = { span: 24 };
export const FALLBACK_TEXT = '-';
export const FALLBACK_NA = 'N/A';
export const MAX_PAGE_SIZE = 2147483647;
