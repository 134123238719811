import { TextareaControlled } from '@retail/backoffice-ui';
import { CurrencyCodes } from '@retail/currency';
import { memo } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';

import { RetailClaimCostFragmentFragment } from '@/apollo/gql-types';
import { FIELD_LABEL_COL } from '@/constants/common';
import { useFormatCurrency } from '@/utils/i18n/useFormatCurrency';

interface FieldsData {
  isLevelOneCommentAllowed?: boolean;
  isLevelTwoCommentAllowed?: boolean;
  isLevelThreeCommentAllowed?: boolean;
  isCustomerDecisionCommentAllowed?: boolean;
}

interface CostCommentsFormProps {
  cost: RetailClaimCostFragmentFragment;
  fieldsData: FieldsData;
  control: Control<FieldValues>;
}

const getApprovalCommentFieldName = ({
  isLevelOneCommentAllowed,
  isLevelTwoCommentAllowed,
  isLevelThreeCommentAllowed,
}: CostCommentsFormProps['fieldsData']): string => {
  if (isLevelOneCommentAllowed) {
    return 'claimCostComment.approvalLevel1Comment';
  }

  if (isLevelTwoCommentAllowed) {
    return 'claimCostComment.approvalLevel2Comment';
  }

  if (isLevelThreeCommentAllowed) {
    return 'claimCostComment.approvalLevel3Comment';
  }

  return '';
};

export const CostCommentsForm = memo<CostCommentsFormProps>(
  ({ control, cost, fieldsData }) => {
    const { t } = useTranslation();
    const formattedCost = useFormatCurrency({
      amountMinorUnits: cost?.costMinorUnits,
      currencyCode: cost?.costCurrencyCode as CurrencyCodes,
    });
    const approvalCommentFieldName = getApprovalCommentFieldName(fieldsData);

    return (
      <div className={cn.offset}>
        <strong className={cn.compensationNumber}>
          {`${t(
            'bo.orderClaims.processing.popUp.costComments.compensation',
          )}  #: ${cost.number}`}
        </strong>
        <strong className={cn.total}>
          {`${t(
            'bo.orderClaims.processing.popUp.costComments.cost',
          )}: ${formattedCost}`}
        </strong>

        {approvalCommentFieldName && (
          <TextareaControlled
            labelCol={FIELD_LABEL_COL}
            rows={5}
            maxLength={300}
            label={t(
              'bo.orderClaims.processing.popUp.costComments.approvalComments',
            )}
            controllerProps={{
              name: approvalCommentFieldName,
              control,
            }}
          />
        )}

        {fieldsData?.isCustomerDecisionCommentAllowed && (
          <TextareaControlled
            labelCol={FIELD_LABEL_COL}
            rows={5}
            maxLength={300}
            label={t(
              'bo.orderClaims.processing.popUp.costComments.customerDecisionComments',
            )}
            controllerProps={{
              name: 'claimCostComment.customerDecisionComment',
              control,
            }}
          />
        )}
      </div>
    );
  },
);
