import { makeVar, useReactiveVar } from '@apollo/client';
import { Button, Modal, notification } from 'antd';
import { get } from 'lodash/fp';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CostCommentsForm } from './form';
import cn from './styles.less';

import { useCreateRetailClaimCostsCommentMutation } from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';

export const manageCostCommentsModal = makeVar({ visible: false, data: null });

export const hideModal = () =>
  manageCostCommentsModal({ visible: false, data: null });
export const showModal = (data: unknown) =>
  manageCostCommentsModal({ visible: true, data });

export const CostCommentsModal = memo(() => {
  const { visible, data } = useReactiveVar(manageCostCommentsModal);
  const { t } = useTranslation();

  const fieldsData = get('fieldsData')(data);
  const cost = get('cost')(data);

  const initialValues = useMemo(
    () => ({
      claimCostId: cost?.id,
      claimCostComment: {
        approvalLevel1Comment: fieldsData?.isLevelOneCommentAllowed ? '' : null,
        approvalLevel2Comment: fieldsData?.isLevelTwoCommentAllowed ? '' : null,
        approvalLevel3Comment: fieldsData?.isLevelThreeCommentAllowed
          ? ''
          : null,
        customerDecisionComment: fieldsData?.isCustomerDecisionCommentAllowed
          ? ''
          : null,
      },
    }),
    [
      cost?.id,
      fieldsData?.isLevelOneCommentAllowed,
      fieldsData?.isLevelTwoCommentAllowed,
      fieldsData?.isLevelThreeCommentAllowed,
      fieldsData?.isCustomerDecisionCommentAllowed,
    ],
  );

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isSubmitting },
  } = useForm<FieldValues>({
    defaultValues: initialValues,
    mode: 'all',
  });

  useEffect(() => reset(initialValues), [initialValues, reset]);

  const [create] = useCreateRetailClaimCostsCommentMutation({
    ...REQUEST_BASE_OPTIONS,
    refetchQueries: ['SearchRetailClaims'],
    onCompleted: () => {
      notification.success({
        message: 'Created successfully',
      });
    },
  });

  const onSubmit = useCallback(
    async (formData) => {
      await create({
        variables: {
          claimCostId: formData?.claimCostId,
          claimCostComment: formData?.claimCostComment,
        },
      });
      hideModal();
    },
    [create],
  );

  return (
    <Modal
      centered
      width={600}
      visible={visible}
      onOk={handleSubmit(onSubmit)}
      onCancel={hideModal}
      title={
        <strong
          className="text-uppercase"
          data-qa-selector="cost-comments-title"
        >
          {t('bo.orderClaims.processing.popUp.costComments.title')}
        </strong>
      }
      footer={
        <>
          <Button type="default" onClick={hideModal}>
            <span data-qa-selector="cancel-cost-comments-modal">
              {t('bo.orderClaims.processing.popUp.costComments.discard.cta')}
            </span>
          </Button>
          <Button
            type="primary"
            loading={isSubmitting}
            disabled={!isDirty}
            onClick={handleSubmit(onSubmit)}
          >
            <span data-qa-selector="update-cost-comments-modal">
              {t('bo.orderClaims.processing.popUp.costComments.save.cta')}
            </span>
          </Button>
        </>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)} className={cn.form}>
        <CostCommentsForm
          cost={cost}
          control={control}
          fieldsData={fieldsData}
        />
      </form>
    </Modal>
  );
});
