import { PropsWithChildren, useState } from 'react';

import { Portal } from '../../portal';
import { DocumentViewerManagerProvider } from '../Context';
import Viewer from '../Viewer';
import { DocumentModel } from '../types';

interface Props {
  documents: DocumentModel[];
}

export function DocumentViewerManager({
  documents,
  children,
}: PropsWithChildren<Props>) {
  const [isOpen, setOpen] = useState(false);
  const [activeUrl, setActiveUrl] = useState('');

  function close() {
    setOpen(false);
    setActiveUrl('');
  }

  function open(targetUrl?: string) {
    setOpen(true);
    setActiveUrl(targetUrl);
  }

  return (
    <DocumentViewerManagerProvider value={{ open }}>
      {children}
      {isOpen && (
        <Portal lockTarget>
          <Viewer
            activeUrl={activeUrl}
            documents={documents}
            open={open}
            close={close}
          />
        </Portal>
      )}
    </DocumentViewerManagerProvider>
  );
}
