import { PropsWithChildren, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

import { target } from './utils';

interface Props {
  target?: HTMLElement;
  lockTarget: boolean;
}

const DEFAULT_TARGET = target();

export function Portal({
  target = DEFAULT_TARGET,
  lockTarget,
  children,
}: PropsWithChildren<Props>) {
  useLayoutEffect(() => {
    if (lockTarget) {
      target.style.overflow = 'hidden';
    }

    return () => {
      if (lockTarget) {
        target.style.overflow = 'initial';
      }
    };
  }, [lockTarget, target]);

  return createPortal(children, target);
}

export default Portal;
