import { memo } from 'react';

import { RootSelectorResult } from '../selectors';

import { Customer } from './Customer';
import { OrderAsideInfo } from './OrderAsideInfo';
import { ShippingAddress } from './ShippingAddress';
import { TotalCost } from './TotalCost';
import cn from './styles.less';

interface GeneralInfoProps {
  loading: boolean;
  data: RootSelectorResult;
}

export const GeneralInfo = memo<GeneralInfoProps>(({ data, loading }) => {
  const { customer, stockNumber, claimsTotalCost, retailCountry } = data;
  const totalCostData = {
    claimsTotalCost,
    retailCountry,
  };
  const customerData = {
    customer,
    stockNumber,
  };

  return (
    <div className={cn.section}>
      <TotalCost data={totalCostData} loading={loading} />
      <OrderAsideInfo data={data} loading={loading} />
      <Customer data={customerData} loading={loading} />
      <ShippingAddress />
    </div>
  );
});
