import { useTranslation } from 'react-i18next';

import CountryFlag from '../../../../../components/country-flag';
import { InfoCard } from '../../InfoCard';

import cn from './styles.less';

import { InlineKeyValueRow } from '@/components/InlineKeyValueRow';

interface Props {
  loading: boolean;
  retailCountry: string;
  paymentType: string;
  deliveryType: string;
}

export function OrderSummary({
  loading,
  retailCountry,
  paymentType,
  deliveryType,
}: Props) {
  const { t } = useTranslation();

  return (
    <InfoCard
      loading={loading}
      size="small"
      title={t('bo.orderClaims.orderSummary.title.orderSummary')}
    >
      <InlineKeyValueRow
        qaSelector="retailCountry"
        label={t('bo.claimsOverview.retailCountry')}
        className={cn.retailCountry}
        value={
          <CountryFlag
            placement="top"
            style={{ width: 25 }}
            country={retailCountry}
          />
        }
      />
      <InlineKeyValueRow
        qaSelector="deliveryType"
        label={t('bo.orderClaims.orderSummary.title.deliveryType')}
        value={deliveryType}
      />
      <InlineKeyValueRow
        qaSelector="paymentType"
        label={t('bo.orderClaims.orderSummary.title.paymentType')}
        value={paymentType}
      />
    </InfoCard>
  );
}
