// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2tUNjqPHPVa3RJsaYTma8Q\\=\\= {\n  position: fixed;\n  bottom: 20px;\n  overflow: hidden;\n  align-self: center;\n}\n._2tUNjqPHPVa3RJsaYTma8Q\\=\\= button {\n  width: 50px;\n  height: 50px;\n  color: #ffffff;\n  border: 0;\n  outline: none;\n  background: #333333;\n  font-size: 17px;\n}\n._2tUNjqPHPVa3RJsaYTma8Q\\=\\= button:hover,\n._2tUNjqPHPVa3RJsaYTma8Q\\=\\= button:active {\n  background: #707070;\n}\n._2tUNjqPHPVa3RJsaYTma8Q\\=\\= button:first-child {\n  border-top-left-radius: 5px;\n  border-bottom-left-radius: 5px;\n}\n._2tUNjqPHPVa3RJsaYTma8Q\\=\\= button:last-child {\n  border-top-right-radius: 5px;\n  border-bottom-right-radius: 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DocumentViewer/Toolbar/styles.less"],"names":[],"mappings":"AAIA;EACE,eAAA;EACA,YAAA;EAEA,gBAAA;EACA,kBAAA;AAJF;AADA;EAQI,WAAA;EACA,YAAA;EAEA,cAAA;EACA,SAAA;EACA,aAAA;EACA,mBAAA;EAEA,eAAA;AANJ;AAQI;;EAEE,mBAAA;AANN;AASI;EACE,2BAAA;EACA,8BAAA;AAPN;AAUI;EACE,4BAAA;EACA,+BAAA;AARN","sourcesContent":["@import '../vars.less';\n\n@size: 50px;\n\n.toolbar {\n  position: fixed;\n  bottom: 20px;\n\n  overflow: hidden;\n  align-self: center;\n\n  button {\n    width: @size;\n    height: @size;\n\n    color: #ffffff;\n    border: 0;\n    outline: none;\n    background: #333333;\n\n    font-size: 17px;\n\n    &:hover,\n    &:active {\n      background: #707070;\n    }\n\n    &:first-child {\n      border-top-left-radius: @button-border-radius;\n      border-bottom-left-radius: @button-border-radius;\n    }\n\n    &:last-child {\n      border-top-right-radius: @button-border-radius;\n      border-bottom-right-radius: @button-border-radius;\n    }\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbar": "_2tUNjqPHPVa3RJsaYTma8Q=="
};
export default ___CSS_LOADER_EXPORT___;
