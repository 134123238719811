// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NlPMEC2MaZT3zlIJYH35lw\\=\\= {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DocumentViewer/Link/styles.less"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF","sourcesContent":[".link {\n  cursor: pointer;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "NlPMEC2MaZT3zlIJYH35lw=="
};
export default ___CSS_LOADER_EXPORT___;
