import { makeVar, useReactiveVar } from '@apollo/client';
import { Button, Form, Modal, notification } from 'antd';
import { get } from 'lodash/fp';
import { memo, useCallback } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Entity } from '../../selectors';

import { RequestProofForm } from './RequestProofForm';
import cn from './styles.less';
import { RequestProofModalFormValuesModel } from './types';

import { useCreateAdditionalProofRequestsMutation } from '@/apollo/gql-types';

interface DataModel {
  data?: {
    claim: Entity;
  };
  visible?: boolean;
  onSuccess?: VoidFunction;
  onDismiss?: VoidFunction;
}

export const manageRequestProofModal = makeVar<DataModel>({
  data: null,
  visible: false,
  onSuccess: () => null,
  onDismiss: () => null,
});

export const hideModal = () =>
  manageRequestProofModal({
    data: null,
    visible: false,
    onSuccess: () => null,
    onDismiss: () => null,
  });
export const showModal = ({
  data,
  onSuccess = () => null,
  onDismiss = () => null,
}: DataModel) =>
  manageRequestProofModal({
    data,
    onSuccess,
    onDismiss,
    visible: true,
  });

const DEFAULT_VALUES: RequestProofModalFormValuesModel = {
  requests: [
    {
      type: null,
      comment: null,
    },
  ],
};

export const RequestProofModal = memo(() => {
  const { visible, data, onSuccess, onDismiss } = useReactiveVar(
    manageRequestProofModal,
  );
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitted, isSubmitting },
  } = useForm<FieldValues>({
    defaultValues: DEFAULT_VALUES,
    mode: 'all',
  });

  const [create] = useCreateAdditionalProofRequestsMutation({
    awaitRefetchQueries: true,
    refetchQueries: ['SearchRetailClaims'],
    onError: (error) => {
      notification.warning({
        message: 'Error',
        description: get('0.message')(error?.graphQLErrors),
      });
      onDismiss();
    },
    onCompleted: () => {
      notification.success({
        message: t('bo.orderClaims.processing.popUp.additionalProof.submitted'),
      });
      onSuccess();
    },
  });

  const onSubmit = useCallback(
    async (formData) => {
      await create({
        variables: {
          claimId: data?.claim?.id,
          proofRequestsCreateProjection: formData,
        },
      });
      reset(DEFAULT_VALUES);
      hideModal();
    },
    [create, data?.claim?.id, reset],
  );

  return (
    <Modal
      centered
      width={600}
      visible={visible}
      onCancel={() => {
        reset(DEFAULT_VALUES);
        hideModal();
      }}
      footer={
        <div className={cn.modalFooter}>
          <div className={cn.modalFooterText}>
            {t('bo.orderClaims.processing.popUp.additionalProof.warning')}
          </div>
          <Button
            key="cancel"
            type="default"
            data-qa-selector="modal-additional-proof-cancel"
            onClick={() => {
              reset(DEFAULT_VALUES);
              hideModal();
            }}
          >
            <span data-qa-selector="cancel-additional-proof-request-modal">
              {t('bo.orderClaims.processing.popUp.additionalProof.discard.cta')}
            </span>
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
            data-qa-selector="modal-additional-proof-submit"
          >
            <span data-qa-selector="request-additional-proof-request-modal">
              {t('bo.orderClaims.processing.popUp.additionalProof.save.cta')}
            </span>
          </Button>
        </div>
      }
      title={
        <strong
          className="text-uppercase"
          data-qa-selector="cost-comments-title"
        >
          {t('bo.orderClaims.processing.popUp.additionalProof.title')}
        </strong>
      }
    >
      <Form
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
        className={cn.form}
      >
        <RequestProofForm
          control={control}
          errors={{ ...errors }}
          complaints={data?.claim?.complaints}
          subClaims={data?.claim?.subClaims}
          isSubmitted={isSubmitted}
        />
      </Form>
    </Modal>
  );
});
