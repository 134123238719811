import classNames from 'classnames';
import { HTMLAttributes, PropsWithChildren } from 'react';

import cn from './styles.less';

type Props = PropsWithChildren<HTMLAttributes<HTMLElement>>;

export function InfoSection({ children, className, ...props }: Props) {
  return (
    <section {...props} className={classNames(className, cn.content)}>
      {children}
    </section>
  );
}
