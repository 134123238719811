import {
  FilterType,
  SortType,
  createFilter,
  createFilterPayload,
  createSort,
} from '@retail/gql-utils';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { orderManagementClient } from '@/apollo/gql-client';
import { useSearchDocumentsQuery } from '@/apollo/gql-types';
import {
  DocumentViewerLink,
  DocumentViewerManager,
} from '@/components/DocumentViewer';
import { RowInfo } from '@/components/RowInfo';
import { MAX_PAGE_SIZE } from '@/constants/common';

const HANDOVER_DOCUMENT_TYPE_ID = '20742317-2d69-4105-b154-bea8d45d4a3f';

const HandoverDocuments = () => {
  const { orderId } = useParams();
  const isIdValid = true;

  const { t } = useTranslation();

  const { data } = useSearchDocumentsQuery({
    skip: !isIdValid,
    variables: {
      search: createFilterPayload({
        sorts: [createSort('createdOn', SortType.DESC)],
        filter: createFilter(null, FilterType.AND, [
          createFilter(
            'documentType',
            FilterType.EQUAL,
            HANDOVER_DOCUMENT_TYPE_ID,
          ),
          createFilter('documentTopCategoryType', FilterType.EQUAL, 'ORDER'),
          createFilter('attachedTo', FilterType.EQUAL, orderId),
          createFilter('state', FilterType.EQUAL, 'UPLOADED'),
        ]),
        pageSize: MAX_PAGE_SIZE,
      }),
    },
    client: orderManagementClient,
  });

  const documents = data?.documents?.entities || [];

  return (
    <DocumentViewerManager documents={documents}>
      <RowInfo
        label={t('bo.orderClaims.handoverDocument')}
        value={
          documents.length > 0 ? (
            <DocumentViewerLink
              key={documents[0]?.fullUrl}
              targetUrl={documents[0]?.fullUrl}
            >
              <span>{t('bo.orderClaims.handoverDocument.link')}</span>
            </DocumentViewerLink>
          ) : null
        }
      />
    </DocumentViewerManager>
  );
};

export { HandoverDocuments };
