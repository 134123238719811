import { SelectControlled } from '@retail/backoffice-ui';
import { find, join, map } from 'lodash/fp';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useSearchClaimAssigneeUsersQuery,
  useUpdateRetailOrderClaimAssigneeUserMutation,
} from '@/apollo/gql-types';
import { FIELD_LABEL_COL } from '@/constants/common';

interface AssignUserProps {
  claimId: string;
  country: string;
  assignTo: string;
  disabled: boolean;
}

export const AssignUser = ({
  claimId,
  assignTo,
  country,
  disabled,
}: AssignUserProps) => {
  const { t } = useTranslation();
  const { data: claimAssigneeUsersData, loading: claimAssigneeUsersLoading } =
    useSearchClaimAssigneeUsersQuery({
      variables: {
        countries: [country],
      },
    });

  const [assignUser, { loading: assignmentLoading }] =
    useUpdateRetailOrderClaimAssigneeUserMutation({
      refetchQueries: ['SearchRetailClaims'],
      awaitRefetchQueries: true,
    });

  const assignUsers = useMemo(
    () =>
      map(
        ({ id, firstName, lastName }) => ({
          value: id,
          label: join(' ', [firstName, lastName]),
        }),
        claimAssigneeUsersData?.data?.entities,
      ),
    [claimAssigneeUsersData?.data?.entities],
  );

  const value = useMemo(
    () => find({ value: assignTo })(assignUsers) || null,
    [assignTo, assignUsers],
  );

  const handleAssignUser = useCallback(
    async (value) => {
      const updateUserAssign = {
        assignTo: value ?? null,
      };

      if (value) {
        await assignUser({ variables: { updateUserAssign, claimId } });
      } else {
        const confirmText = t('bo.orderClaims.notification.unassignClaim');
        if (global.confirm(confirmText)) {
          await assignUser({ variables: { updateUserAssign, claimId } });
        }
      }
    },
    [assignUser, claimId, t],
  );

  const { control } = useForm<{ status: unknown }>({
    defaultValues: {},
    mode: 'all',
  });

  return (
    <div data-qa-selector="assignee-dropdown" style={{ width: 200 }}>
      <SelectControlled
        labelCol={FIELD_LABEL_COL}
        allowClear
        showSearch
        label={t('bo.claimsOverview.search.assignedTo.title')}
        value={value?.value}
        options={assignUsers}
        onChange={handleAssignUser}
        loading={assignmentLoading}
        data-qa-selector="assign-claim"
        placeholder={t('bo.orderClaims.form.selectPlaceholder')}
        disabled={claimAssigneeUsersLoading || assignmentLoading || disabled}
        controllerProps={{
          name: 'status',
          control,
        }}
      />
    </div>
  );
};
