import { useCallback } from 'react';

import { Entity } from '../overview/selectors';
import { useSearchRetailClaimsDocument } from '../hooks/useSearchRetailClaimsDocument';

import { useUpdateRetailOrderClaimGroupMutation } from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';

export function useUpdateClaimGroup() {
  const [updateClaimGroup] =
    useUpdateRetailOrderClaimGroupMutation(REQUEST_BASE_OPTIONS);
  const refetchClaims = useSearchRetailClaimsDocument();

  return useCallback(
    async (claim: Entity) => {
      const { id: claimId, claimGroupId } = claim;
      await updateClaimGroup({
        variables: {
          claimId,
          updateGroup: {
            claimGroupId,
          },
        },
      });

      await refetchClaims({ orderId: claim.order.id, claimId: claim.id });
    },
    [refetchClaims, updateClaimGroup],
  );
}
