import { filter, getOr, map } from 'lodash/fp';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { groupDocumentsByTime, IGroupedDocument } from '../../../helpers';

import {
  RetailClaimComplaintCommentProjection,
  RetailClaimComplaintDocumentProjection,
  RetailClaimComplaintProjection,
  RetailClaimProofRequestProjection,
  RetailSubClaimDocumentProjection,
} from '@/apollo/gql-types';

const BODY_DAMAGE_CATEGORY_ID = '08d8c188-f5f7-4b20-ac12-ec0c6f8ee840';
const PROCESS_DELAY_CATEGORY_ID = '08b68507-d48c-45f2-ba35-4048a359ca51';

export interface UseComplaintsData {
  title: string;
  complaintNumber: number;
  comments: RetailClaimComplaintCommentProjection[];
  documents: RetailClaimComplaintDocumentProjection[];
  groupedDocuments: IGroupedDocument[];
  proofRequests: RetailClaimProofRequestProjection[];
  createdOn?: string;
  __typename?: string;
}

const useComplaintsData = ({
  complaints,
  proofRequests,
  claimGroupId,
}: {
  complaints: RetailClaimComplaintProjection[];
  proofRequests: RetailClaimProofRequestProjection[];
  claimGroupId: string;
}) => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      map((complaint: RetailClaimComplaintProjection): UseComplaintsData => {
        const {
          area,
          damage,
          complaintNumber,
          namingVersion,
          complaintArea,
          complaintDamage,
          complaintPart,
        } = complaint;
        const documents = getOr([], ['documents'], complaint);
        const groupedDocuments = documents.length
          ? groupDocumentsByTime(
              documents as RetailSubClaimDocumentProjection[],
            )
          : [];
        const comments = getOr([], ['comments'], complaint);
        const complaintProofRequests = filter(
          ({ complaintId }) => complaintId === complaint.id,
          proofRequests,
        );

        const damageArea = [];

        if (namingVersion === 2) {
          if (claimGroupId === BODY_DAMAGE_CATEGORY_ID) {
            complaintArea &&
              damageArea.push(
                t(
                  `bo.orderClaims.form.customerComplaints.area.${complaintArea}`,
                ),
              );
            complaintDamage &&
              damageArea.push(
                t(
                  `bo.orderClaims.form.customerComplaints.area.damage.${complaintDamage}`,
                ),
              );
          }
          if (
            ![BODY_DAMAGE_CATEGORY_ID, PROCESS_DELAY_CATEGORY_ID].includes(
              claimGroupId,
            )
          ) {
            complaintPart &&
              damageArea.push(
                t(`bo.orderClaims.submitReason.carParts.${complaintPart}`),
              );
          }
        } else {
          if (area) {
            damageArea.push(
              t(`bo.orderClaims.form.customerComplaints.area.${area}`),
            );
          }
          if (damage) {
            damageArea.push(
              t(`bo.orderClaims.form.customerComplaints.area.damage.${damage}`),
            );
          }
        }

        return {
          title: `${t(
            'bo.orderClaims.complaints.complaint',
          )} #${complaintNumber}. ${damageArea.join(' | ')}`,
          complaintNumber,
          documents,
          groupedDocuments,
          comments,
          proofRequests: complaintProofRequests,
        };
      }, complaints),

    [claimGroupId, complaints, proofRequests, t],
  );
};

export default useComplaintsData;
