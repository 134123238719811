// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".efukghsXYekZXGpn5399bQ\\=\\= {\n  position: fixed;\n  z-index: 1110;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  flex-direction: column;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DocumentViewer/Viewer/styles.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EAEA,aAAA;EACA,sBAAA;AAAF","sourcesContent":[".viewer {\n  position: fixed;\n  z-index: 1110;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n\n  display: flex;\n  flex-direction: column;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewer": "efukghsXYekZXGpn5399bQ=="
};
export default ___CSS_LOADER_EXPORT___;
