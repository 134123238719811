import { Space } from 'antd';
import cns from 'classnames';
import { map } from 'lodash/fp';
import { ReactElement } from 'react';

import { IGroupedDocument } from '../../../helpers';
import { CreationInfo } from '../CreationInfo';
import { ListItem } from '../ListItem';

import { DownloadButton } from './DownloadButton';
import cn from './styles.less';

import { DocumentViewerLink } from '@/components/DocumentViewer';

interface RenderSuffixProps {
  id: string;
  fileName: string;
}

export interface DocumentProps extends IGroupedDocument {
  className?: string;
  renderSuffix?: (props: RenderSuffixProps) => ReactElement;
}

const Documents = ({
  className,
  createdBy,
  createdOn,
  sourceType,
  documents,
  renderSuffix,
}: DocumentProps) => (
  <ListItem
    key={createdOn}
    data-qa-selector="documents"
    className={cns(className)}
  >
    <div>
      <CreationInfo
        createdBy={createdBy}
        createdOn={createdOn}
        sourceType={sourceType}
      />
      <ol className={cn.documents}>
        {map(({ id, fullUrl, fileName }) => (
          <li key={id} className={cn.documentItem}>
            <Space>
              <DocumentViewerLink
                data-qa-selector="fileName"
                targetUrl={fullUrl}
              >
                {fileName}
              </DocumentViewerLink>
              <DownloadButton targetUrl={fullUrl} fileName={fileName} />
              {renderSuffix?.({ id, fileName })}
            </Space>
          </li>
        ))(documents)}
      </ol>
    </div>
  </ListItem>
);

export default Documents;
