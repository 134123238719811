import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Spin } from 'antd';
import { isEmpty, flow, get, head } from 'lodash/fp';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';

import { TClaimOrderDetails } from '../selectors';

import cn from './styles.less';
import Content from './content';
import selector from './selectors';

import {
  RetailClaimGroupsOptionsQuery,
  SearchRetailClaimsQuery,
} from '@/apollo/gql-types';
import { ORDER_CLAIMS_DETAILS } from '@/routes';

interface ClaimsOverviewProps {
  loading: boolean;
  claims: SearchRetailClaimsQuery;
  claimGroups: RetailClaimGroupsOptionsQuery;
  claimOrderDetails: TClaimOrderDetails;
}

export const ClaimsOverview = memo<ClaimsOverviewProps>(
  ({ loading, claims, claimGroups, claimOrderDetails }) => {
    const { language, orderId } = useParams();
    const { t } = useTranslation();
    const result = useMemo(
      () =>
        selector({
          claims,
          claimGroups,
        }),
      [claimGroups, claims],
    );

    const firstClaimId = flow(head, get('id'))(result?.entities);

    if (loading) {
      return (
        <div className={cn.spin}>
          <Spin />
        </div>
      );
    }

    if (isEmpty(result?.entities)) {
      return (
        <div>
          <Alert
            showIcon
            type="warning"
            message={t('bo.orderClaims.notFoundText')}
          />
        </div>
      );
    }

    return (
      <Routes>
        <Route
          path={`${ORDER_CLAIMS_DETAILS.ROUTE}/*`}
          element={
            <Content
              firstClaimId={firstClaimId}
              claimGroups={claimGroups}
              claimOrderDetails={claimOrderDetails}
            />
          }
        />
        <Route
          path="/*"
          element={
            <Navigate
              replace
              to={ORDER_CLAIMS_DETAILS.LINK({
                language,
                orderId,
                claimId: firstClaimId,
              })}
            />
          }
        />
      </Routes>
    );
  },
);
