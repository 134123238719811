import { CurrencyCodes } from '@retail/currency';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import { useFormatCurrency } from '@/utils/i18n/useFormatCurrency';

const MIN_MAX_PER_CURRENCY = {
  [CurrencyCodes.EUR]: {
    MIN: 30000,
    MAX: 200000,
  },
  [CurrencyCodes.PLN]: {
    MIN: 130000,
    MAX: 900000,
  },
  [CurrencyCodes.SEK]: {
    MIN: 300000,
    MAX: 2000000,
  },
};

interface Props {
  amount: number;
  currencyCode: CurrencyCodes;
}

export const ApprovalWarning = ({
  amount,
  currencyCode = CurrencyCodes.EUR,
}: Props) => {
  const currency = MIN_MAX_PER_CURRENCY[currencyCode];
  const formattedPriceMin = useFormatCurrency({
    amountMinorUnits: currency.MIN,
  });
  const formattedPriceMax = useFormatCurrency({
    amountMinorUnits: currency.MAX,
  });

  if (!amount) {
    return null;
  }

  if (amount <= currency.MIN) {
    return null;
  }
  if (amount > currency.MIN && amount <= currency.MAX) {
    return (
      <Trans
        i18nKey="bo.orderClaims.processing.popUp.addCost.notification.level2ApprovalRequired"
        values={{
          amount: formattedPriceMin,
          currency: currencyCode,
        }}
      />
    );
  }

  return (
    <Trans
      i18nKey="bo.orderClaims.processing.popUp.addCost.notification.level3ApprovalRequired"
      values={{
        amount: formattedPriceMax,
        currency: currencyCode,
      }}
    />
  );
};

ApprovalWarning.propTypes = {
  amount: PropTypes.number,
  currencyCode: PropTypes.string,
};
