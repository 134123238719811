/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useRef } from 'react';

import { ViewerDocumentModel } from './types';

interface Props {
  document: ViewerDocumentModel;
}

export function Video({ document }: Props) {
  const videoElement = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoElement.current) {
      videoElement.current.load();
      videoElement.current.play();
    }
  }, [document]);

  return (
    <video
      controls
      height="auto"
      style={{ maxWidth: '100%' }}
      ref={videoElement}
    >
      <track />
      <source src={document.fullUrl} type="video/mp4" />
    </video>
  );
}
