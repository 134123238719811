import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { RetailSubClaimUpdateProjectionInput } from '@/apollo/gql-types';

export function useClearFieldsValue() {
  const { resetField, unregister } = useFormContext();

  return useCallback(
    (...fields: (keyof RetailSubClaimUpdateProjectionInput)[]) => {
      fields.forEach((field) => {
        resetField(field, {
          defaultValue: null,
          keepDirty: false,
          keepError: false,
          keepTouched: false,
        });

        unregister(field);
      });
    },
    [resetField, unregister],
  );
}
