import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';
import { every } from 'lodash/fp';
import { useMemo } from 'react';

import { APPROVAL_LEVEL, CUSTOMER_DECISION } from '../../../../../constants';

import { DOMAINS, PERMISSIONS } from '@/constants/permissions';

const CLAIM_APPROVAL_NOT_ALLOWED_STATUSES = [APPROVAL_LEVEL.NOT_REQUIRED];
const CUSTOMER_DECISION_NOT_ALLOWED_STATUSES = [CUSTOMER_DECISION.NOT_REQUIRED];

interface Props {
  approvalLevel1: string;
  approvalLevel2: string;
  approvalLevel3: string;
  customerDecision: string;
  costType: string;
  costMinorUnits: number;
  warrantyCoverageType: string;
}

const useAllowedCommentFields = ({
  approvalLevel1,
  approvalLevel2,
  approvalLevel3,
  customerDecision,
  costType,
  costMinorUnits,
  warrantyCoverageType,
}: Props) => {
  const { isAllowed: hasClaimsEditCommentsPermission } = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_ORDER_CLAIMS_CREATE_CLAIM_COSTS_COMMENT,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });

  const { isAllowed: hasLevelOneAccess } = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_SET_CLAIM_COST_APPROVE_1,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });
  const hasAllowedStatus1 = every((status) => approvalLevel1 !== status)(
    CLAIM_APPROVAL_NOT_ALLOWED_STATUSES,
  );
  const isLevelOneCommentAllowed =
    (hasClaimsEditCommentsPermission || hasLevelOneAccess) && hasAllowedStatus1;

  const { isAllowed: hasLevelTwoAccess } = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_SET_CLAIM_COST_APPROVE_2,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });
  const hasAllowedStatus2 = every((status) => approvalLevel2 !== status)(
    CLAIM_APPROVAL_NOT_ALLOWED_STATUSES,
  );
  const isLevelTwoCommentAllowed =
    (hasClaimsEditCommentsPermission || hasLevelTwoAccess) && hasAllowedStatus2;

  const { isAllowed: hasLevelThreeAccess } = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_SET_CLAIM_COST_APPROVE_2,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });
  const hasAllowedStatus3 = every((status) => approvalLevel3 !== status)(
    CLAIM_APPROVAL_NOT_ALLOWED_STATUSES,
  );
  const isLevelThreeCommentAllowed =
    (hasClaimsEditCommentsPermission || hasLevelThreeAccess) &&
    hasAllowedStatus3;

  const { isAllowed: hasCustomerDecisionAccess } = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_SET_CLAIM_COST_CUSTOMER_DECISION,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });
  const hasAllowedStatusCustomerDecision = every(
    (status) => customerDecision !== status,
  )(CUSTOMER_DECISION_NOT_ALLOWED_STATUSES);

  const isCustomerDecisionCommentAllowed =
    (hasClaimsEditCommentsPermission || hasCustomerDecisionAccess) &&
    hasAllowedStatusCustomerDecision;

  const isCostCommentsModalAllowed = useMemo(
    () =>
      costType &&
      warrantyCoverageType &&
      costMinorUnits &&
      (isLevelOneCommentAllowed ||
        isLevelTwoCommentAllowed ||
        isLevelThreeCommentAllowed ||
        isCustomerDecisionCommentAllowed ||
        hasClaimsEditCommentsPermission),
    [
      costType,
      costMinorUnits,
      warrantyCoverageType,
      isLevelOneCommentAllowed,
      isLevelTwoCommentAllowed,
      isLevelThreeCommentAllowed,
      isCustomerDecisionCommentAllowed,
      hasClaimsEditCommentsPermission,
    ],
  );

  return useMemo(
    () => ({
      isCostCommentsModalAllowed,
      isLevelOneCommentAllowed,
      isLevelTwoCommentAllowed,
      isLevelThreeCommentAllowed,
      isCustomerDecisionCommentAllowed,
    }),
    [
      isCostCommentsModalAllowed,
      isLevelOneCommentAllowed,
      isLevelTwoCommentAllowed,
      isLevelThreeCommentAllowed,
      isCustomerDecisionCommentAllowed,
    ],
  );
};

export default useAllowedCommentFields;
