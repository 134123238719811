import { TFunction } from 'react-i18next';

import { CHANGELOG_AUX_USER_TYPES } from '../../../../../constants';

import { ClaimChangelogResponseProjection } from '@/apollo/gql-types';

const getUserName = (
  user: ClaimChangelogResponseProjection['createdBy'],
  t: TFunction,
) => {
  /** {@link user} can be null in some test data */
  const { userType, firstName, lastName } = user ?? {};

  switch (userType) {
    case CHANGELOG_AUX_USER_TYPES.CLASSIFIED_USER:
      return t('bo.orderClaims.changeLog.table.customer');
    case CHANGELOG_AUX_USER_TYPES.BACK_OFFICE:
      return `${firstName} ${lastName}`;
    default:
      return '-';
  }
};

export default getUserName;
