// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5sVGs0OOmt0PhP3p39Mu7w\\=\\= {\n  display: grid;\n  grid-template-columns: 1fr 250px;\n  grid-gap: 20px;\n  padding: 20px;\n}\n.g6neG2nMRr-fKVRJPu9t0g\\=\\= {\n  width: 100%;\n  height: 200px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n#ABSH49mIssC56G9byXFZNA\\=\\= .ITU5M7msqoTbCQyINA3hPA\\=\\=,\n#ABSH49mIssC56G9byXFZNA\\=\\= .\\+Rj-6hfJuvlYzLhtUHGpdg\\=\\=,\n#ABSH49mIssC56G9byXFZNA\\=\\= .XdEXHKEVC4Sb6H2n4g3RGw\\=\\=,\n#ABSH49mIssC56G9byXFZNA\\=\\= h1,\n#ABSH49mIssC56G9byXFZNA\\=\\= h2,\n#ABSH49mIssC56G9byXFZNA\\=\\= h3 {\n  margin-top: 0;\n  margin-bottom: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gCAAA;EACA,cAAA;EACA,aAAA;AACF;AAEA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAF;AAGA;;;;;;EAEI,aAAA;EACA,gBAAA;AAGJ","sourcesContent":[".page {\n  display: grid;\n  grid-template-columns: 1fr 250px;\n  grid-gap: 20px;\n  padding: 20px;\n}\n\n.spin {\n  width: 100%;\n  height: 200px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n#claims {\n  .h1, .h2, .h3, h1, h2, h3 {\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "_5sVGs0OOmt0PhP3p39Mu7w==",
	"spin": "g6neG2nMRr-fKVRJPu9t0g==",
	"claims": "ABSH49mIssC56G9byXFZNA==",
	"h1": "ITU5M7msqoTbCQyINA3hPA==",
	"h2": "+Rj-6hfJuvlYzLhtUHGpdg==",
	"h3": "XdEXHKEVC4Sb6H2n4g3RGw=="
};
export default ___CSS_LOADER_EXPORT___;
