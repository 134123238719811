import {
  createFilter,
  createFilterPayload,
  createSort,
  Filter,
  FilterType,
  SortType,
} from '@retail/gql-utils';

import { SearchFormData } from './Search/search-form-data';

import { MAX_PAGE_SIZE } from '@/constants/common';

export function createSearchVariables(
  claimId: string,
  { sortBy, ...filtersDic }: SearchFormData = {},
) {
  const hasRange = filtersDic.range?.length === 2;
  const filters: Filter[] = [
    createFilter('claimId', FilterType.EQUAL, claimId),
    filtersDic.typeNumber &&
      createFilter('typeNumber', FilterType.EQUAL, filtersDic.typeNumber),
    filtersDic.type?.length &&
      createFilter('type', FilterType.IN, filtersDic.type),
    hasRange &&
      createFilter('createdOn', FilterType.GREATER_THAN, filtersDic.range[0]),
    hasRange &&
      createFilter('createdOn', FilterType.LESS_THAN, filtersDic.range[1]),
  ].filter(Boolean);

  return {
    search: createFilterPayload({
      sorts: [createSort('createdOn', sortBy ?? SortType.DESC)],
      filter:
        filters.length > 1
          ? createFilter(null, FilterType.AND, filters)
          : filters[0],
      pageSize: MAX_PAGE_SIZE,
    }),
  };
}
