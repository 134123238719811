import { getDefaultLocaleOfCountry } from '@retail/i18n/utils';
import { toUpper } from 'lodash/fp';
import {
  Context,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from 'react';

import {
  RetailFeatureStatusProjectionV2,
  useRetailFeaturesStatusV2Query,
} from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';
import { defaultCountry } from '@/constants/common';

export interface IFeaturesContext {
  features: RetailFeatureStatusProjectionV2[] | null;
}

export const FeaturesContext: Context<IFeaturesContext> = createContext({
  features: [],
});

export function useFeatures() {
  const { features } = useContext(FeaturesContext);

  return useMemo(
    (): Record<string, boolean> =>
      features.reduce(
        (acc, feature) => ({
          ...acc,
          [feature.name]: feature.enabled,
        }),
        {},
      ),
    [features],
  );
}

interface Props {
  names: string[];
  country?: string;
}

export function FeaturesProvider({
  names,
  country = defaultCountry,
  children,
}: PropsWithChildren<Props>) {
  const { data } = useRetailFeaturesStatusV2Query({
    ...REQUEST_BASE_OPTIONS,
    variables: {
      names,
      context: {
        environment: toUpper(process.env.ENV),
        country,
        locale: getDefaultLocaleOfCountry(country),
      },
    },
  });

  return (
    <FeaturesContext.Provider value={{ features: data?.features ?? [] }}>
      {children}
    </FeaturesContext.Provider>
  );
}
