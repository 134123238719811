import { Button, Col, Modal, Table } from 'antd';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useClaimStateDueDaysConfigQueryAsync } from '../hooks/useClaimStateDueDaysConfigQueryAsync';
import { useColumns } from '../hooks/useColumns';
import { useSubmit } from '../hooks/useSubmit';

import cn from './styles.less';
import { DueDaysConfigButtonFormModalValuesModel } from './types';

interface Props {
  active: boolean;
  setInactive: () => void;
}

export function FormModal({ active, setInactive }: Props) {
  const { t } = useTranslation();
  const { entities, loading: itemLoading } =
    useClaimStateDueDaysConfigQueryAsync();
  const defaultValues = useMemo(
    (): DueDaysConfigButtonFormModalValuesModel => ({
      entities: entities.map(({ id, claimState, dueDays }) => ({
        id,
        dueDays,
        claimState: t(`bo.orderClaims.claim.status.${claimState}`),
      })),
    }),
    [entities, t],
  );

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues,
    mode: 'all',
  });

  function onCancel() {
    reset(defaultValues);
    setInactive();
  }

  useEffect(() => reset(defaultValues), [defaultValues, reset]);

  const { onSubmit, loading, isDenied } = useSubmit({ onCancel });
  const columns = useColumns({ control, isDenied });

  return (
    <Modal
      visible={active}
      onCancel={onCancel}
      title={t('bo.claimsOverview.table.dueDays.title')}
      footer={
        <Col span={24}>
          <Button
            data-qa-selector="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={isDenied || !isDirty || !isValid}
            loading={loading}
            type={isDirty ? 'primary' : 'default'}
          >
            {t('bo.claimsOverview.dueDays.btn.submit.title')}
          </Button>
        </Col>
      }
    >
      <form
        data-qa-selector="dueDateForm"
        className={cn.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Table
          bordered
          rowKey="id"
          pagination={false}
          columns={columns}
          loading={itemLoading}
          rowClassName="dueDateRow"
          data-qa-selector="dueDateTable"
          dataSource={entities}
        />
      </form>
    </Modal>
  );
}
