export const polyfillNaturalSize = (image: Writeable<HTMLImageElement>) => {
  // eslint-disable-next-line no-undefined
  if (image.naturalWidth === undefined) {
    image.naturalWidth = image.offsetWidth;
    image.naturalHeight = image.offsetHeight;
  }

  return image;
};

export const toSize = (width: number, height: number) => ({ width, height });
export const toPosition = (left: number, top: number) => ({ left, top });

export const getSizes = (image: HTMLImageElement) => {
  const { offsetWidth, offsetHeight, naturalWidth, naturalHeight } = image;
  const { offsetWidth: pWidth, offsetHeight: pHeight } =
    image.parentNode as HTMLElement;

  return {
    size: toSize(offsetWidth, offsetHeight),
    nSize: toSize(naturalWidth, naturalHeight),
    pSize: toSize(pWidth, pHeight),
  };
};

export const getFitSize = (image: HTMLImageElement) => {
  const { size, pSize } = getSizes(image);
  const widthCoef = pSize.width / size.width;
  const heightCoef = pSize.height / size.height;

  if (widthCoef < heightCoef) {
    return toSize(pSize.width, size.height * widthCoef);
  }

  return toSize(size.width * heightCoef, pSize.height);
};

export const getShrinkSize = (image: HTMLImageElement) => {
  const { size, pSize } = getSizes(image);

  if (size.width > pSize.width || size.height > pSize.height) {
    return getFitSize(image);
  }

  return size;
};

export const getZoomSize = (image: HTMLImageElement, zoom: number) => {
  const { size } = getSizes(image);

  return toSize(size.width * zoom, size.height * zoom);
};

export const toggleFitOrNaturalSize = (image: HTMLImageElement) => {
  const { size, nSize, pSize } = getSizes(image);

  if (size.width === pSize.width || size.height === pSize.height) {
    return nSize;
  }

  return getFitSize(image);
};

export const zoomScrollPosition = (image: HTMLImageElement, zoom: number) => {
  (image.parentNode as HTMLElement).scrollLeft *= zoom;
  (image.parentNode as HTMLElement).scrollTop *= zoom;
};

export const centerScrollPosition = (image: HTMLImageElement) => {
  const { size, pSize } = getSizes(image);
  const TWO = 2;

  (image.parentNode as HTMLElement).scrollLeft =
    (size.width - pSize.width) / TWO;
  (image.parentNode as HTMLElement).scrollTop =
    (size.height - pSize.height) / TWO;
};
