import { SortType } from '@retail/gql-utils';
import { useSearchParamsController } from '@retail/hooks';
import { useCallback } from 'react';

import { formToMeta } from '../formToMeta';

import { useSearchOrderRetailClaimsQuery } from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';
import { PAGE_SIZE } from '@/constants/common';
import { CLAIM_STATES } from '@/pages/claims/constants';
import * as ROUTES from '@/routes';
import { OverviewSearchModel } from '@/types/OverviewSearchModel';

const INITIAL_DATA: OverviewSearchModel = {
  page: 1,
  totalPages: 0,
  pageSize: PAGE_SIZE,
  claimStatus: [CLAIM_STATES.SUBMITTED],
  country: [],
  assignTo: [],
  orderNumber: '',
  claimNumber: '',
  stockNumber: '',
  claimGroups: [],
  liableParties: [],
  approvalLevel1: null,
  approvalLevel2: null,
  approvalLevel3: null,
  customerDecision: [],
  isOnlyShowActive: null,
  isOnlyShowTrialPeriod: null,
};

export function useSearchOrderRetailClaimsQueryAsync() {
  const {
    controller,
    formValues,
    clearController,
    updateController,
    onPageChange,
  } = useSearchParamsController(({ language, navigate, queryString }) => {
    navigate(
      `${ROUTES.ORDER_CLAIMS_OVERVIEW.LINK({ language })}${queryString}`,
    );
  }, INITIAL_DATA);

  const { data, loading, refetch } = useSearchOrderRetailClaimsQuery({
    ...REQUEST_BASE_OPTIONS,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      search: formToMeta()(controller),
    },
    onCompleted: (data) => {
      updateController({
        totalPages: data?.searchRetailClaims?.totalEntityCount,
      });
    },
  });

  const onChange = useCallback(
    (pagination, filters, sorter, extra) => {
      const { action } = extra;
      const { field, order } = sorter;
      if (action === 'sort' && order) {
        updateController({
          page: 1,
          sort: field,
          direction: order === 'ascend' ? SortType.ASC : SortType.DESC,
        });
      } else {
        updateController({
          page: 1,
          sort: 'createdOn',
          direction: SortType.ASC,
        });
      }
    },
    [updateController],
  );

  return {
    controller,
    formValues,
    clearController,
    updateController,
    onPageChange,
    dataSource: data?.searchRetailClaims?.entities ?? [],
    loading,
    refetch,
    onChange,
  };
}
