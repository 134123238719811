// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rh-h94eKKnfw8G6TWbPtcQ\\=\\= .V803vdO-5MCk2BIh20v5Vg\\=\\= {\n  font-size: 11px;\n}\n.rh-h94eKKnfw8G6TWbPtcQ\\=\\= .\\+Wk6l653-fzSq\\+v4a73MSQ\\=\\= {\n  font-size: 12px;\n  white-space: pre-line;\n}\n.sok4kBBMeZNJdD8trf-LkA\\=\\= {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/content/styles.less"],"names":[],"mappings":"AAAA;EAGI,eAAA;AADJ;AAFA;EAOI,eAAA;EACA,qBAAA;AAFJ;AAMA;EACE,WAAA;AAJF","sourcesContent":[".listItem {\n\n  .submitter {\n    font-size: 11px;\n  }\n\n  .comment {\n    font-size: 12px;\n    white-space: pre-line;\n  }\n}\n\n.fullWidth {\n  width: 100%;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItem": "rh-h94eKKnfw8G6TWbPtcQ==",
	"submitter": "V803vdO-5MCk2BIh20v5Vg==",
	"comment": "+Wk6l653-fzSq+v4a73MSQ==",
	"fullWidth": "sok4kBBMeZNJdD8trf-LkA=="
};
export default ___CSS_LOADER_EXPORT___;
