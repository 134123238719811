/* eslint-disable class-methods-use-this */
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import cns from 'classnames';
import { findIndex } from 'lodash/fp';
import mousetrap from 'mousetrap';
import { Component, Dispatch } from 'react';

import { ViewerDocumentModel } from '../types';

import cn from './styles.less';

import { RetailSubClaimDocumentProjection } from '@/apollo/gql-types';

interface Props {
  document: ViewerDocumentModel;
  documents: ViewerDocumentModel[];
  open: Dispatch<string>;
}

export default class DocumentViewerArrows extends Component<Props> {
  componentDidMount() {
    mousetrap.bind('left', this.openPrev);
    mousetrap.bind('right', this.openNext);
  }

  componentWillUnmount() {
    mousetrap.unbind('left');
    mousetrap.unbind('right');
  }

  openSibling = (offset: number) => {
    const { document, documents, open } = this.props;
    const activeIndex = findIndex({
      fullUrl: document.fullUrl,
    })(documents as Required<RetailSubClaimDocumentProjection>[]);
    const index = (activeIndex + offset + documents.length) % documents.length;
    const siblingDocument = documents[index];

    open(siblingDocument.fullUrl);
  };

  openPrev = () => this.openSibling(-1);

  openNext = () => this.openSibling(1);

  render() {
    return (
      <>
        <button className={cns(cn.arrow, cn.arrowLeft)} onClick={this.openPrev}>
          <LeftOutlined />
        </button>
        <button
          className={cns(cn.arrow, cn.arrowRight)}
          onClick={this.openNext}
        >
          <RightOutlined />
        </button>
      </>
    );
  }
}
