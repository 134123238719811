import { TextareaControlled } from '@retail/backoffice-ui';
import { Row } from 'antd';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ClaimGroupControl } from '../claim-group-control';
import { Entity } from '../overview/selectors';
import { SubclaimCreateCommentAndDocumentsForm } from '../SubclaimCreateCommentAndDocumentsForm';
import { SubclaimForm } from '../SubclaimForm';

import cn from './styles.less';

import {
  RetailClaimGroupsOptionsQuery,
  RetailSubClaimCreateProjectionInput,
} from '@/apollo/gql-types';
import { FIELD_LABEL_COL } from '@/constants/common';

export interface SubclaimCreateFormValues
  extends Omit<RetailSubClaimCreateProjectionInput, 'comments' | 'comment'> {
  newDocuments?: File[];
  claimGroupId?: string;
  comment?: string;
  comments?: string;
}

interface Props {
  claim: Entity;
  control: Control;
  claimGroups: RetailClaimGroupsOptionsQuery;
  errors: unknown;
}

export function SubClaimCreateForm({
  claim,
  control,
  claimGroups,
  errors,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={20}>
        <ClaimGroupControl
          claimGroups={claimGroups}
          control={control}
          disabled
          isGroupSelected
        />
      </Row>
      <SubclaimForm
        allowClearAreaPartDamage
        claim={claim}
        control={control}
        claimGroupId={claim.claimGroupId}
        claimGroups={claimGroups}
      />
      <SubclaimCreateCommentAndDocumentsForm
        claim={claim}
        control={control}
        errors={errors}
      />

      <Row gutter={20} className={cn.textarea}>
        <TextareaControlled
          labelCol={FIELD_LABEL_COL}
          rows={4}
          maxLength={300}
          label={t('bo.orderClaims.form.subClaim.BOcomments.label')}
          placeholder={t('bo.orderClaims.form.subClaim.BOcomments.placeholder')}
          controllerProps={{
            name: 'comments',
            control,
          }}
        />
      </Row>
    </>
  );
}
