// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9B3\\+I1LIHk47JF1o8krRFQ\\=\\= {\n  margin-bottom: 20px;\n}\n.qZlrZ230xcvJSI76xuIzQQ\\=\\= {\n  margin-bottom: 15px;\n  font-size: 18px;\n  text-transform: uppercase;\n}\n.htuAWRLIHQuSV5AKjyP\\+GQ\\=\\= .antd-4-21-7-input-number-input {\n  font-size: 14px;\n}\n.AkiUeGNCeumqr\\+-RGEvy\\+A\\=\\= {\n  color: #333333;\n}\n.NvG8fW0thzreyjOeExah8g\\=\\= .antd-4-21-7-alert-message {\n  color: #333333;\n}\n.yN0eyKprLnvMr9-5LWB9nQ\\=\\= .antd-4-21-7-input-number,\n.yN0eyKprLnvMr9-5LWB9nQ\\=\\= .antd-4-21-7-input-number-group-wrapper {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/content/compensations/cost-modal/styles.less"],"names":[],"mappings":"AAEA;EACE,mBAAA;AADF;AAIA;EACE,mBAAA;EAEA,eAAA;EACA,yBAAA;AAHF;AAMA;EAGM,eAAA;AANN;AAWA;EACE,cAAA;AATF;AAYA;EAGM,cAAA;AAZN;AAiBA;;EAIM,WAAA;AAjBN","sourcesContent":["@import '../../../../../../styles/constants.less';\n\n.offset {\n  margin-bottom: 20px;\n}\n\n.text {\n  margin-bottom: 15px;\n\n  font-size: 18px;\n  text-transform: uppercase;\n}\n\n.costInput {\n  :global {\n    .@{antd-version}-input-number-input {\n      font-size: 14px;\n    }\n  }\n}\n\n.label {\n  color: #333333;\n}\n\n.warning {\n  :global {\n    .@{antd-version}-alert-message {\n      color: #333333;\n    }\n  }\n}\n\n.form {\n  :global {\n    .@{antd-version}-input-number,\n    .@{antd-version}-input-number-group-wrapper {\n      width: 100%;\n    }\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"offset": "_9B3+I1LIHk47JF1o8krRFQ==",
	"text": "qZlrZ230xcvJSI76xuIzQQ==",
	"costInput": "htuAWRLIHQuSV5AKjyP+GQ==",
	"label": "AkiUeGNCeumqr+-RGEvy+A==",
	"warning": "NvG8fW0thzreyjOeExah8g==",
	"form": "yN0eyKprLnvMr9-5LWB9nQ=="
};
export default ___CSS_LOADER_EXPORT___;
