import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { useSaveVisitingLogMutation } from '@/apollo/gql-types';
import { LogEventType } from '@/enums/logEventTypes';
import { useChangeLogDetails } from '@/hooks/useChangeLogDetails';
import { downloadFile } from '@/utils/document';

interface Props {
  targetUrl: string;
  fileName: string;
}

export function DownloadButton({ targetUrl, fileName }: Props) {
  const changeLogDetails = useChangeLogDetails();
  const [saveLog] = useSaveVisitingLogMutation();

  function onClick() {
    downloadFile(targetUrl, fileName);
    saveLog({
      variables: {
        model: {
          eventType: LogEventType.DOCUMENT,
          eventSource: targetUrl,
          details: {
            pageUrl: window.location.href,
            ...changeLogDetails,
          },
        },
      },
    });
  }

  return (
    <Button
      type="primary"
      size="small"
      data-qa-selector="subclaim-comment"
      icon={<DownloadOutlined title="Download" />}
      onClick={onClick}
    />
  );
}
