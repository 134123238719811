import { OptionModel, SelectControlled } from '@retail/backoffice-ui';
import { Col } from 'antd';
import { flow, map, sortBy } from 'lodash/fp';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useClearFieldsValue } from '../hooks/useClearFieldsValue';

import { RetailClaimGroupsOptionsQuery } from '@/apollo/gql-types';
import { FIELD_LABEL_COL } from '@/constants/common';

export const CLAIM_GROUP_ID_NAME = 'claimGroupId';

interface Props {
  control: Control;
  claimGroups: RetailClaimGroupsOptionsQuery;
  disabled?: boolean;
  isGroupSelected?: boolean;
}

export function ClaimGroupControl({
  control,
  claimGroups,
  disabled,
  isGroupSelected,
}: Props) {
  const { t } = useTranslation();

  const claimGroupsOptions = useMemo(
    () =>
      flow(
        map(
          ({ id: value, name }): OptionModel => ({
            value,
            label: t(name) as string,
          }),
        ),
        sortBy<OptionModel>('label'),
      )(claimGroups?.claimGroups),
    [claimGroups?.claimGroups, t],
  );

  const clearFieldsValue = useClearFieldsValue();

  return (
    <Col span={isGroupSelected ? 12 : 24} data-qa-selector="claim-group">
      <SelectControlled
        labelCol={FIELD_LABEL_COL}
        showSearch
        required
        label={t('bo.orderClaims.form.claimGroupLabel')}
        placeholder={t('bo.orderClaims.form.claimGroupPlaceholder')}
        options={claimGroupsOptions}
        onChange={() => {
          clearFieldsValue('areaId', 'carPartId', 'damageId');
        }}
        disabled={disabled}
        controllerProps={{
          name: CLAIM_GROUP_ID_NAME,
          control,
        }}
      />
    </Col>
  );
}
