// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._10wlfgHuWP9eeBuzg0Yncw\\=\\= > div {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/SubClaimCreateModal/styles.less"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF","sourcesContent":[".textarea > div {\n  width: 100%;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": "_10wlfgHuWP9eeBuzg0Yncw=="
};
export default ___CSS_LOADER_EXPORT___;
