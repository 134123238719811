import { Typography } from 'antd';
import cns from 'classnames';

import { CreationInfo } from '../CreationInfo';
import { ListItem } from '../ListItem';
import cn from '../styles.less';

import { RetailClaimComplaintCommentProjection } from '@/apollo/gql-types';

const { Paragraph } = Typography;

export interface CommentProps extends RetailClaimComplaintCommentProjection {
  className?: string;
  sourceType?: string;
  comment?: string;
  text?: string;
}

const Comment = ({
  className,
  createdBy,
  createdOn,
  sourceType,
  text,
  comment,
  id,
}: CommentProps) => (
  <ListItem data-qa-selector="comment" key={id} className={cns(className)}>
    <div>
      <CreationInfo
        createdBy={createdBy}
        createdOn={createdOn}
        sourceType={sourceType}
      />
      <Paragraph data-qa-selector="text" className={cn.content}>
        {text || comment}
      </Paragraph>
    </div>
  </ListItem>
);

export default Comment;
