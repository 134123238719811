// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".idgRHfDaBNkCLivSd99Z7w\\=\\= {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/content/additional-info/OrderDetails/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "idgRHfDaBNkCLivSd99Z7w=="
};
export default ___CSS_LOADER_EXPORT___;
