import { Typography } from 'antd';
import { TFunction, useTranslation } from 'react-i18next';

import { CLAIM_SOURCE_TYPES } from '../../../constants';

import cn from './styles.less';

import {
  RetailSubClaimCommentProjection,
  UserProjection,
} from '@/apollo/gql-types';
import { formatDateTime } from '@/helpers/date';

const { Paragraph } = Typography;

function getCreatedByInfo(
  createdBy: UserProjection,
  sourceType: string,
  t: TFunction<'translation', undefined>,
) {
  if (createdBy?.firstName && createdBy?.lastName) {
    return `${createdBy?.firstName} ${createdBy?.lastName}`;
  }

  const defaultCustomerName = t('bo.orderClaims.autoHeroCustomer');
  const defaultBoUserName = t('bo.orderClaims.backofficeUser');

  if (!sourceType) {
    return defaultCustomerName;
  }

  return sourceType === CLAIM_SOURCE_TYPES.AUTO_HERO
    ? defaultCustomerName
    : defaultBoUserName;
}

type Props = Pick<
  RetailSubClaimCommentProjection,
  'createdBy' | 'createdOn' | 'sourceType'
>;

export function CreationInfo({ createdBy, createdOn, sourceType }: Props) {
  const { t } = useTranslation();

  return (
    <Paragraph data-qa-selector="creationInfo" className={cn.generalInfo}>
      {`${getCreatedByInfo(createdBy, sourceType, t)} - ${formatDateTime(
        createdOn,
      )}`}
    </Paragraph>
  );
}
