// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XeOVtQr1ADy5NFe\\+sPyV8Q\\=\\= {\n  display: flex;\n  justify-content: flex-end;\n  gap: 6px;\n  padding: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/content/SubClaims/footer/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;EACA,QAAA;EACA,aAAA;AACF","sourcesContent":[".footer {\n  display: flex;\n  justify-content: flex-end;\n  gap: 6px;\n  padding: 16px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "XeOVtQr1ADy5NFe+sPyV8Q=="
};
export default ___CSS_LOADER_EXPORT___;
