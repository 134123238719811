import { OptionModel, SelectControlled } from '@retail/backoffice-ui';
import { Col } from 'antd';
import { find, flow, get, map } from 'lodash/fp';
import { memo, useMemo } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  RetailClaimComplaintProjection,
  RetailSubClaimProjection,
} from '@/apollo/gql-types';
import { FIELD_LABEL_COL } from '@/constants/common';

interface Props {
  name: string;
  control: Control;
  complaints: RetailClaimComplaintProjection[];
  size: number;
  disabled?: boolean;
  subClaims?: RetailSubClaimProjection[];
  qaSelector?: string;
}

export const CustomerComplaintControl = memo<Props>(
  ({ name, control, complaints, size, disabled, subClaims, qaSelector }) => {
    const { t } = useTranslation();

    const customerComplaintsOptions = useMemo(
      (): OptionModel[] =>
        map(({ id, complaintNumber }) => {
          const subClaimNumber = flow(
            find(({ complaintId }) => complaintId === id),
            get(['number']),
          )(subClaims);

          return {
            value: id,
            label: `${t(
              'bo.orderClaims.complaints.complaint',
            )} #${complaintNumber}. ${subClaimNumber && `| ${subClaimNumber}`}`,
          };
        }, complaints),
      [complaints, subClaims, t],
    );

    return (
      <Col span={size}>
        <SelectControlled
          labelCol={FIELD_LABEL_COL}
          showSearch
          required
          label={t('bo.orderClaims.form.customerComplaintLabel')}
          placeholder={t('bo.orderClaims.form.customerComplaintPlaceholder')}
          options={customerComplaintsOptions || []}
          disabled={disabled}
          controllerProps={{ name, control }}
          qaSelector={qaSelector}
        />
      </Col>
    );
  },
);
