import { useCreateClaimV2 } from './useCreateClaimV2';

interface Props {
  orderId: string;
  language: string;
  retailCountry: string;
}

export const useFeatureCreateClaim = ({
  orderId,
  language,
  retailCountry,
}: Props) => {
  return useCreateClaimV2({ orderId, language, retailCountry });
};
