import { OptionModel } from '@retail/backoffice-ui';
import {
  ADDRESS_COUNTRIES_VALUES,
  COUNTRIES_VALUES,
} from '@retail/i18n/constants';
import { flow, sortBy, values } from 'lodash/fp';

import prepareFormattedOptions from '../prepareFormattedOptions';

import { toLocalizedCountry } from '@/utils/i18n/toLocalizedCountry';

const prepareCountryOptions = flow(
  prepareFormattedOptions(toLocalizedCountry),
  sortBy<OptionModel>('label'),
);

export const countryAddressOptions = flow(
  values,
  prepareCountryOptions,
)(ADDRESS_COUNTRIES_VALUES);
export const countriesOptions = flow(
  values,
  prepareCountryOptions,
)(COUNTRIES_VALUES);
