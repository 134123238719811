import { OptionModel } from '@retail/backoffice-ui';
import { filter } from 'lodash/fp';

import { AccessParametersFragmentFragment } from '@/apollo/gql-types';
import { countriesOptions } from '@/helpers/fieldOptions/country';
import {
  getUserAccess,
  hasAccess,
} from '@/hooks/useCheckPermissions/helpers/accessParams';

export const getUserAccessibleCountryCodeOptions = (
  accessParameters: AccessParametersFragmentFragment[],
) => {
  const userAccess = getUserAccess('country')(accessParameters);
  return filter(({ value }) => hasAccess(value, userAccess))(
    countriesOptions,
  ) as OptionModel[];
};
