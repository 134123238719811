import classNames from 'classnames';
import { differenceInDays, startOfDay } from 'date-fns';

import cn from './styles.less';

import { RetailClaimProjection } from '@/apollo/gql-types';
import { CLAIM_STATES } from '@/pages/claims/constants';

export function dueDateCellClassName({
  stateDueDate,
  state,
}: RetailClaimProjection) {
  if (!stateDueDate || state === CLAIM_STATES.CLOSED) {
    return cn.row;
  }

  const differenceDays = differenceInDays(
    startOfDay(new Date(stateDueDate)),
    startOfDay(new Date()),
  );

  const differenceDaysPlusOne = differenceDays - 1;

  return {
    className: classNames({
      [cn.redRow]: differenceDays <= 0,
      [cn.yellowRow]: differenceDaysPlusOne === 0,
    }),
  };
}
