// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bl7-36POnUPiP7uBmi1sZg\\=\\= {\n  display: inline-block;\n  margin-bottom: 0;\n}\n.rbxKO2BwVJcpK6bu360JJg\\=\\= {\n  text-decoration: line-through;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/content/compensations/status/styles.less"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,gBAAA;AACF;AAEA;EACE,6BAAA;AAAF","sourcesContent":[".status {\n  display: inline-block;\n  margin-bottom: 0;\n}\n\n.canceled {\n  text-decoration: line-through;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": "bl7-36POnUPiP7uBmi1sZg==",
	"canceled": "rbxKO2BwVJcpK6bu360JJg=="
};
export default ___CSS_LOADER_EXPORT___;
