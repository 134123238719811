import {
  FullscreenOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { CSSProperties, Component } from 'react';

import {
  centerScrollPosition,
  getZoomSize,
  toggleFitOrNaturalSize,
  zoomScrollPosition,
} from '../utils';

import cn from './styles.less';

const ZOOM_COEF = 1.25;

interface Props {
  image: HTMLImageElement;
  setStyle: (x: CSSProperties, fn: VoidFunction) => void;
}

export default class DocumentViewerToolbar extends Component<Props> {
  handleZoomOut = () => {
    const { image, setStyle } = this.props;

    setStyle(getZoomSize(image, 1 / ZOOM_COEF), () => {
      zoomScrollPosition(image, 1 / ZOOM_COEF);
    });
  };

  handleZoomIn = () => {
    const { image, setStyle } = this.props;

    setStyle(getZoomSize(image, ZOOM_COEF), () => {
      zoomScrollPosition(image, ZOOM_COEF);
    });
  };

  handleToggle = () => {
    const { image, setStyle } = this.props;

    setStyle(toggleFitOrNaturalSize(image), () => {
      centerScrollPosition(image);
    });
  };

  render() {
    return (
      <div className={cn.toolbar}>
        <button onClick={this.handleZoomOut}>
          <ZoomOutOutlined />
        </button>
        <button onClick={this.handleZoomIn}>
          <ZoomInOutlined />
        </button>
        <button onClick={this.handleToggle}>
          <FullscreenOutlined />
        </button>
      </div>
    );
  }
}
