// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Xp1IZ1MbsWQxomZwT4pdeA\\=\\= {\n  white-space: nowrap;\n}\n.Hod-KpESaJGz7dxSWGud7w\\=\\= {\n  display: flex;\n}\n.pBFb2kGKmh2E4lOdZzO3aw\\=\\= {\n  position: absolute;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClaimsOverview/ClaimNumber/styles.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAEA;EACE,aAAA;AAAF;AAGA;EACE,kBAAA;AADF","sourcesContent":[".text {\n  white-space: nowrap;\n}\n\n.section {\n  display: flex;\n}\n\n.trial {\n  position: absolute;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "Xp1IZ1MbsWQxomZwT4pdeA==",
	"section": "Hod-KpESaJGz7dxSWGud7w==",
	"trial": "pBFb2kGKmh2E4lOdZzO3aw=="
};
export default ___CSS_LOADER_EXPORT___;
