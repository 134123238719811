// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s2fkmxWe8sVfJkom8NAZJg\\=\\= {\n  vertical-align: top;\n}\n.p\\+stf8-2yz6lCc2MW4oXdQ\\=\\= {\n  font-weight: 600;\n}\n.FvkO4y7Ro7IQLa0Oagva-Q\\=\\= {\n  font-weight: 400;\n}\n.CsKAuAwVFfhdA1J3j1SxiA\\=\\= {\n  display: flex;\n  flex-direction: column;\n}\n.CsKAuAwVFfhdA1J3j1SxiA\\=\\= ._1hbh9F1zc1fqoWCPw6gpcg\\=\\= {\n  color: #c5c5c5;\n}\n.CsKAuAwVFfhdA1J3j1SxiA\\=\\= .Ffsp8Boq8zj\\+BYSP0YCgoQ\\=\\= {\n  color: #c5c5c5;\n}\n.JcAkytV6hsXEQtutfrS5Jw\\=\\= {\n  display: flex;\n  flex-direction: column;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/content/change-log/Table/hooks/useTableColumns/styles.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAEA;EACE,gBAAA;AAAF;AAGA;EACE,gBAAA;AADF;AAIA;EACE,aAAA;EACA,sBAAA;AAFF;AAAA;EAKI,cAAA;AAFJ;AAHA;EASI,cAAA;AAHJ;AAOA;EACE,aAAA;EACA,sBAAA;AALF","sourcesContent":[".valignTop {\n  vertical-align: top;\n}\n\n.textBold {\n  font-weight: 600;\n}\n\n.textDefault {\n  font-weight: 400;\n}\n\n.generalDataCell {\n  display: flex;\n  flex-direction: column;\n\n  .date {\n    color: #c5c5c5;\n  }\n\n  .operation {\n    color: #c5c5c5;\n  }\n}\n\n.objectDataCell {\n  display: flex;\n  flex-direction: column;\n}\n\n\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"valignTop": "s2fkmxWe8sVfJkom8NAZJg==",
	"textBold": "p+stf8-2yz6lCc2MW4oXdQ==",
	"textDefault": "FvkO4y7Ro7IQLa0Oagva-Q==",
	"generalDataCell": "CsKAuAwVFfhdA1J3j1SxiA==",
	"date": "_1hbh9F1zc1fqoWCPw6gpcg==",
	"operation": "Ffsp8Boq8zj+BYSP0YCgoQ==",
	"objectDataCell": "JcAkytV6hsXEQtutfrS5Jw=="
};
export default ___CSS_LOADER_EXPORT___;
