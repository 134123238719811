import cns from 'classnames';
import React, { memo } from 'react';

import cn from './styles.less';

interface StatusProps {
  children: React.ReactNode;
  isCanceled?: boolean;
  className?: string;
}

export const Status = memo<StatusProps>(
  ({ children, isCanceled, className }) => (
    <p className={cns(cn.status, { [cn.canceled]: isCanceled }, className)}>
      {children}
    </p>
  ),
);
