import { useTranslation } from 'react-i18next';

import { RowInfo } from '@/components/RowInfo';
import { FALLBACK_NA } from '@/constants/common';
import { useFormatDate } from '@/helpers/date';

interface Props {
  vehicleDeliveredOn: string;
}

export function CarHandoverOn({ vehicleDeliveredOn }: Props) {
  const { t } = useTranslation();
  const vehicleDeliveredDate = useFormatDate(vehicleDeliveredOn);

  return (
    <RowInfo
      qaSelector="carHandoverOn"
      label={t('bo.orderClaims.orderSummary.title.carHandoverOn')}
      value={vehicleDeliveredDate}
      fallback={FALLBACK_NA}
    />
  );
}
