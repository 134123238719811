import { SearchOutlined } from '@ant-design/icons';
import { SelectControlled } from '@retail/backoffice-ui';
import { Button, Form } from 'antd';
import cns from 'classnames';
import { Dispatch, memo, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { translateOptions } from 'src/pages/claims/utils';

import { OBJECT_NAME_OPTIONS, SORT_BY_OPTIONS } from './constants';
import { SearchFormData } from './search-form-data';
import cn from './styles.less';
import { getEndOfDayDate, getStartOfDayDate } from './utils';

import { InputControlled } from '@/components/form/input';
import { DatePickerRangeControlled } from '@/components/form/datepickerRange';

interface ChangeLogSearchProps {
  className?: string;
  disabled: boolean;
  onSubmit: Dispatch<SearchFormData>;
}

export const ChangeLogSearch = memo<ChangeLogSearchProps>(
  ({ className, disabled, onSubmit }) => {
    const { t } = useTranslation();
    const sortByOptions = useMemo(
      () => translateOptions(SORT_BY_OPTIONS, t),
      [t],
    );
    const objectNameOptions = useMemo(
      () => translateOptions(OBJECT_NAME_OPTIONS, t),
      [t],
    );

    const { handleSubmit, control } = useForm<SearchFormData>({
      defaultValues: {},
      mode: 'all',
    });

    function wrapOnSubmit(e: SearchFormData) {
      return onSubmit({
        ...e,
        range:
          e.range?.length === 2
            ? [getStartOfDayDate(e.range[0]), getEndOfDayDate(e.range[1])]
            : null,
      });
    }

    const handleOnSubmit = handleSubmit(wrapOnSubmit);

    return (
      <Form
        layout="vertical"
        onFinish={handleOnSubmit}
        data-qa-selector="changeLogForm"
        className={cns(cn.form, className)}
      >
        <DatePickerRangeControlled
          className={cn.controlOne}
          label={t('bo.orderClaims.changeLog.search.label.dateTime')}
          dateFormat="DD/MM/YYYY"
          placeholder={[
            t('bo.orderClaims.changeLog.search.label.startDateTime'),
            t('bo.orderClaims.changeLog.search.label.endDateTime'),
          ]}
          data-qa-selector="dateRangeInput"
          controllerProps={{
            name: 'range',
            control,
          }}
        />

        <SelectControlled
          className={cn.controlTwo}
          allowClear
          mode="multiple"
          data-qa-selector="objectTypeSelect"
          label={t('bo.orderClaims.changeLog.search.label.object')}
          placeholder={t('bo.orderClaims.form.selectPlaceholder')}
          options={objectNameOptions}
          controllerProps={{
            name: 'type',
            control,
          }}
        />

        <InputControlled
          className={cn.controlTwo}
          type="string"
          autoComplete="off"
          data-qa-selector="objectIdInput"
          label={t('bo.orderClaims.changeLog.search.label.objectID')}
          placeholder={t(
            'bo.orderClaims.changeLog.search.placeholder.objectID',
          )}
          controllerProps={{
            name: 'typeNumber',
            control,
          }}
        />

        <SelectControlled
          className={cn.controlTwo}
          data-qa-selector="sortBySelect"
          label={t('bo.orderClaims.changeLog.search.label.sortBy')}
          placeholder={t('bo.orderClaims.form.selectPlaceholder')}
          options={sortByOptions}
          controllerProps={{
            name: 'sortBy',
            control,
          }}
        />

        <Button
          data-qa-selector="applySearchButton"
          onClick={handleOnSubmit}
          disabled={disabled}
          type="primary"
        >
          <SearchOutlined />
        </Button>
      </Form>
    );
  },
);
