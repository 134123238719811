import { formatCurrency } from '@retail/backoffice-ui';
import { CurrencyCodes } from '@retail/currency';
import { useMemo } from 'react';

export interface FormatCurrencyOptions {
  currency?: CurrencyCodes;
  currencyCode?: CurrencyCodes;
  conversionMajor?: number;
  amountMinorUnits?: number;
}

export function useFormatCurrency({
  amountMinorUnits,
  currency,
  currencyCode,
  conversionMajor,
}: FormatCurrencyOptions) {
  return useMemo(
    () =>
      currency || currencyCode
        ? formatCurrency({
            amountMinorUnits,
            currency,
            currencyCode,
            conversionMajor,
          })
        : null,
    [amountMinorUnits, conversionMajor, currency, currencyCode],
  );
}
