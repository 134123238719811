/* eslint-disable no-unused-vars */
import { SortType } from '@retail/gql-utils';

export const enum CHANGELOG_OBJECT_TYPES {
  CLAIM = 'CLAIM',
  SUB_CLAIM = 'SUB_CLAIM',
  COST = 'COST',
}

export const SORT_BY_OPTIONS = [
  {
    label: 'bo.orderClaims.changeLog.search.options.sortBy.oldestFirst',
    value: SortType.ASC,
  },
  {
    label: 'bo.orderClaims.changeLog.search.options.sortBy.newestFirst',
    value: SortType.DESC,
  },
];

export const OBJECT_NAME_OPTIONS = [
  {
    label: 'bo.orderClaims.changeLog.table.objectType.CLAIM',
    value: CHANGELOG_OBJECT_TYPES.CLAIM,
  },
  {
    label: 'bo.orderClaims.changeLog.table.objectType.SUB_CLAIM',
    value: CHANGELOG_OBJECT_TYPES.SUB_CLAIM,
  },
  {
    label: 'bo.orderClaims.changeLog.table.objectType.COST',
    value: CHANGELOG_OBJECT_TYPES.COST,
  },
];
