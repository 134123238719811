import { memo } from 'react';

import { CLAIM_COST_PAYMENT_STATUSES } from '../../../constants';

import { RetailClaimCostPaymentProjection } from '@/apollo/gql-types';
import { useFormatDateTime } from '@/helpers/date/hooks';
import {
  FormatCurrencyOptions,
  useFormatCurrency,
} from '@/utils/i18n/useFormatCurrency';

interface PriceProps {
  price: FormatCurrencyOptions;
  payment?: RetailClaimCostPaymentProjection;
}

export const Price = memo<PriceProps>(({ price, payment }) => {
  const formattedPrice = useFormatCurrency(price);
  const formattedDate = useFormatDateTime(payment?.createdOn);

  return (
    <>
      <span data-qa-selector="subclaim-total-cost">
        {formattedPrice}
        {payment?.paymentStatus === CLAIM_COST_PAYMENT_STATUSES.SUCCESS && (
          <span style={{ marginLeft: '10px', color: '#008000' }}>Paid</span>
        )}
      </span>
      {payment?.paymentStatus === CLAIM_COST_PAYMENT_STATUSES.SUCCESS && (
        <div style={{ fontStyle: 'italic', fontSize: '12px' }}>
          {formattedDate}
        </div>
      )}
    </>
  );
});
