/* eslint-disable no-unused-vars */

import { Entity } from '../../selectors';
import Comment from '../customer-complaints/Comment';
import Documents from '../customer-complaints/Documents';

import { DeleteDocumentButton } from './DeleteDocumentButton';

export enum LIST_ITEM_TYPE {
  COMMENT = 'RetailSubClaimCommentProjection',
  DOCUMENTS = 'RetailSubClaimDocumentProjection',
}

export function getListItemByType(
  type: LIST_ITEM_TYPE,
  claimState: Entity['state'],
): <P>(x: P) => JSX.Element {
  switch (type) {
    case LIST_ITEM_TYPE.COMMENT:
      return Comment;
    case LIST_ITEM_TYPE.DOCUMENTS:
      return (props) => (
        <Documents
          {...props}
          renderSuffix={({ id, fileName }) => (
            <DeleteDocumentButton
              id={id}
              fileName={fileName}
              claimState={claimState}
            />
          )}
        />
      );
    default:
      return () => null;
  }
}
