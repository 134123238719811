import React from 'react';
import { CloseOutlined, DownloadOutlined } from '@ant-design/icons';

import cn from './styles.less';

import { downloadFile } from '@/utils/document';

interface IDocument {
  fullUrl?: string;
  fileName?: string;
}

interface Props {
  document: IDocument;
  close: () => void;
}

export function DocumentViewerHeader({ document, close }: Props) {
  function handleDownload() {
    downloadFile(document?.fullUrl, document?.fileName);
  }

  return (
    <header className={cn.documentViewerHeader}>
      <span className={cn.title}>{document?.fileName}</span>
      <div>
        <DownloadOutlined
          title="Download"
          onClick={handleDownload}
          style={{ fontSize: 18, color: 'white' }}
        />
        <CloseOutlined
          title="Close"
          onClick={close}
          style={{ fontSize: 18, color: 'white' }}
        />
      </div>
    </header>
  );
}

export default DocumentViewerHeader;
