import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { APPROVAL_LEVEL } from '../../../../constants';
import { getLevelComments } from '../approval';

import {
  useSetRetailClaimCostApprove1Mutation,
  useSetRetailClaimCostApprove2Mutation,
  useSetRetailClaimCostApprove3Mutation,
} from '@/apollo/gql-types';
import { DOMAINS, PERMISSIONS } from '@/constants/permissions';

const useRequiredApprovalLevel = () => {
  const { t } = useTranslation();

  const hasClaimCostApprove1Permission = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_SET_CLAIM_COST_APPROVE_1,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });
  const hasClaimCostApprove1ForcePermission = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_SET_CLAIM_COST_APPROVE_1_FORCE,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });
  const hasClaimCostApprove2Permission = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_SET_CLAIM_COST_APPROVE_2,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });
  const hasClaimCostApprove2ForcePermission = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_SET_CLAIM_COST_APPROVE_2_FORCE,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });
  const hasClaimCostApprove3Permission = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_SET_CLAIM_COST_APPROVE_3,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });
  const hasClaimCostApprove3ForcePermission = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_SET_CLAIM_COST_APPROVE_3_FORCE,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });

  const { isAllowed: isAllowedNotApproved1Only } = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_SETRETAILCLAIMCOSTAPPROVE1_RESET,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });
  const { isAllowed: isAllowedNotApproved2Only } = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_SETRETAILCLAIMCOSTAPPROVE2_RESET,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });
  const { isAllowed: isAllowedNotApproved3Only } = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_SETRETAILCLAIMCOSTAPPROVE3_RESET,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });

  const [setRetailClaimCostApprove1, { loading: loadingApprove1 }] =
    useSetRetailClaimCostApprove1Mutation({
      awaitRefetchQueries: true,
      refetchQueries: ['SearchRetailClaims', 'AllowedClaimStates'],
    });
  const [setRetailClaimCostApprove2, { loading: loadingApprove2 }] =
    useSetRetailClaimCostApprove2Mutation({
      awaitRefetchQueries: true,
      refetchQueries: ['SearchRetailClaims', 'AllowedClaimStates'],
    });
  const [setRetailClaimCostApprove3, { loading: loadingApprove3 }] =
    useSetRetailClaimCostApprove3Mutation({
      awaitRefetchQueries: true,
      refetchQueries: ['SearchRetailClaims', 'AllowedClaimStates'],
    });

  const setRetailClaimCostApprove1Handler = useCallback(
    ({ claimCostApprove, costId }) =>
      setRetailClaimCostApprove1({
        variables: {
          claimCostApprove,
          costId,
        },
      }),
    [setRetailClaimCostApprove1],
  );

  const setRetailClaimCostApprove2Handler = useCallback(
    ({ claimCostApprove, costId }) =>
      setRetailClaimCostApprove2({
        variables: {
          claimCostApprove,
          costId,
        },
      }),
    [setRetailClaimCostApprove2],
  );

  const setRetailClaimCostApprove3Handler = useCallback(
    ({ claimCostApprove, costId }) =>
      setRetailClaimCostApprove3({
        variables: {
          claimCostApprove,
          costId,
        },
      }),
    [setRetailClaimCostApprove3],
  );

  return useCallback(
    ({
      approvalLevel1,
      approvalLevel2,
      approvalLevel3,
      approvalLevel1SetOn,
      approvalLevel2SetOn,
      approvalLevel3SetOn,
      approvalLevel1SetByProjection,
      approvalLevel2SetByProjection,
      approvalLevel3SetByProjection,
    }) => {
      if (approvalLevel1 !== APPROVAL_LEVEL.NOT_REQUIRED) {
        return {
          label: (
            <>
              {`${t('bo.orderClaims.processing.table.levelApproval.level')} `}
              <strong>{'1:'}</strong>
            </>
          ),
          approvalLevelStatus: approvalLevel1,
          loading: loadingApprove1,
          approvalLevelSetOn: approvalLevel1SetOn,
          approvalLevelSetBy: approvalLevel1SetByProjection,
          onApprovalLevelUpdate: setRetailClaimCostApprove1Handler,
          getComments: getLevelComments('approvalLevel1Comment'),
          permission: hasClaimCostApprove1Permission.isAllowed,
          forcePermission: hasClaimCostApprove1ForcePermission.isAllowed,
          isAllowedNotApprovedOnly: isAllowedNotApproved1Only,
        };
      }

      if (approvalLevel2 !== APPROVAL_LEVEL.NOT_REQUIRED) {
        return {
          label: (
            <>
              {`${t('bo.orderClaims.processing.table.levelApproval.level')} `}
              <strong>{'2:'}</strong>
            </>
          ),
          approvalLevelStatus: approvalLevel2,
          loading: loadingApprove2,
          approvalLevelSetOn: approvalLevel2SetOn,
          approvalLevelSetBy: approvalLevel2SetByProjection,
          onApprovalLevelUpdate: setRetailClaimCostApprove2Handler,
          getComments: getLevelComments('approvalLevel2Comment'),
          permission: hasClaimCostApprove2Permission.isAllowed,
          forcePermission: hasClaimCostApprove2ForcePermission.isAllowed,
          isAllowedNotApprovedOnly: isAllowedNotApproved2Only,
        };
      }

      if (approvalLevel3 !== APPROVAL_LEVEL.NOT_REQUIRED) {
        return {
          label: (
            <>
              {`${t('bo.orderClaims.processing.table.levelApproval.level')} `}
              <strong>{'3:'}</strong>
            </>
          ),
          approvalLevelStatus: approvalLevel3,
          loading: loadingApprove3,
          approvalLevelSetOn: approvalLevel3SetOn,
          approvalLevelSetBy: approvalLevel3SetByProjection,
          onApprovalLevelUpdate: setRetailClaimCostApprove3Handler,
          getComments: getLevelComments('approvalLevel3Comment'),
          permission: hasClaimCostApprove3Permission.isAllowed,
          forcePermission: hasClaimCostApprove3ForcePermission.isAllowed,
          isAllowedNotApprovedOnly: isAllowedNotApproved3Only,
        };
      }
    },
    [
      hasClaimCostApprove1ForcePermission.isAllowed,
      hasClaimCostApprove1Permission.isAllowed,
      hasClaimCostApprove2ForcePermission.isAllowed,
      hasClaimCostApprove2Permission.isAllowed,
      hasClaimCostApprove3ForcePermission.isAllowed,
      hasClaimCostApprove3Permission.isAllowed,
      isAllowedNotApproved1Only,
      isAllowedNotApproved2Only,
      isAllowedNotApproved3Only,
      loadingApprove1,
      loadingApprove2,
      loadingApprove3,
      setRetailClaimCostApprove1Handler,
      setRetailClaimCostApprove2Handler,
      setRetailClaimCostApprove3Handler,
      t,
    ],
  );
};

export default useRequiredApprovalLevel;
