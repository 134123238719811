import { formatCurrency } from '@retail/backoffice-ui/src/utils/formatCurrency';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  APPROVAL_LEVEL_TRANSLATIONS,
  CLAIM_SOURCE_TYPE_TRANSLATIONS,
  CLAIM_STATES_TRANSLATIONS,
  CLOSE_REASON_TRANSLATIONS,
  COST_TYPE_TRANSLATIONS,
  CUSTOMER_DECISION_TRANSLATIONS,
  WARRANTY_TYPE_TRANSLATIONS,
} from '../../../../../constants';

import useClaimGroupDataMap from './useClaimGroupDataMap';

import { RetailClaimGroupsOptionsQuery } from '@/apollo/gql-types';
import { formatDate, formatDateTime } from '@/helpers/date';

const COMMON_FIELDS_TRANSLATIONS: Record<string, Record<string, string>> = {
  sourceType: CLAIM_SOURCE_TYPE_TRANSLATIONS,
  state: CLAIM_STATES_TRANSLATIONS,
  costType: COST_TYPE_TRANSLATIONS,
  warrantyCoverageType: WARRANTY_TYPE_TRANSLATIONS,
  approvalLevel1: APPROVAL_LEVEL_TRANSLATIONS,
  approvalLevel2: APPROVAL_LEVEL_TRANSLATIONS,
  approvalLevel3: APPROVAL_LEVEL_TRANSLATIONS,
  customerDecision: CUSTOMER_DECISION_TRANSLATIONS,
  closedReason: CLOSE_REASON_TRANSLATIONS,
};

const FIELDS_DATE_TIME_FORMAT = ['createdOn', 'carPickedUpOn'];
const FIELDS_DATE_FORMAT = ['stateDueDate'];

interface IUseFieldFormattedValueProps {
  claimGroups: RetailClaimGroupsOptionsQuery;
}

const useFieldFormattedValue = ({
  claimGroups,
}: IUseFieldFormattedValueProps) => {
  const { t } = useTranslation();
  const dataMap = useClaimGroupDataMap({ claimGroups });

  return useCallback(
    (field, value) => {
      if (!value) {
        return '-';
      }

      if (field === 'liablePartyType') {
        return t(`bo.orderClaims.submitReason.liableParty.${value}`);
      }

      if (field === 'cost') {
        const [amountMinorUnits, currencyCode] = value.split(' ');
        const currency = currencyCode;

        return formatCurrency({
          amountMinorUnits,
          currencyCode,
          currency,
        });
      }

      if (COMMON_FIELDS_TRANSLATIONS[field]) {
        return t(COMMON_FIELDS_TRANSLATIONS[field][value]);
      }

      const { claimGroupMap, areasMap, carPartsMap, damagesMap } = dataMap;

      if (field === 'claimGroupId') {
        return t(claimGroupMap[value]?.name);
      }
      if (field === 'areaId') {
        return t(areasMap[value]?.name);
      }
      if (field === 'carPartId') {
        return t(carPartsMap[value]?.name);
      }
      if (field === 'damageId') {
        return t(damagesMap[value]?.name);
      }

      if (field === 'subClaimDocument' || field === 'claimComplaintDocument') {
        const { fileName, fullUrl } = JSON.parse(value);

        return (
          <a href={fullUrl} target="_blank" rel="noreferrer">
            {fileName}
          </a>
        );
      }

      if (FIELDS_DATE_TIME_FORMAT.includes(field)) {
        return formatDateTime(value);
      }

      if (FIELDS_DATE_FORMAT.includes(field)) {
        return formatDate(value);
      }

      return value;
    },
    [dataMap, t],
  );
};

export default useFieldFormattedValue;
