import { COMMON_PERMISSION } from '@retail/backoffice-ui/src/Permission';

// Todo: review unused permissions
export const PERMISSIONS = {
  ...COMMON_PERMISSION,
  QUERY_USER: 'query/user',
  QUERY_SEARCH_USERS: 'query/searchusers',
  MUTATION_CREATE_USER: 'mutation/createuser',
  MUTATION_UPDATE_USER: 'mutation/updateuser',
  MUTATION_ASSIGN_USER_GROUP_ACCESS_PARAMS:
    'mutation/assignusergroupaccessparams',
  MUTATION_RESET_USER_PASSWORD: 'mutation/resetuserpassword',
  MUTATION_DELETE_AH_USER: 'mutation/userobfuscation',

  QUERY_GROUP: 'query/group',
  QUERY_SEARCH_GROUPS: 'query/searchgroups',
  MUTATION_UPDATE_GROUP: 'mutation/updategroup',
  MUTATION_CREATE_GROUP: 'mutation/creategroup',

  QUERY_SEARCH_CUSTOMER_USERS: 'query/searchcustomerusers',
  MUTATION_CREATE_CUSTOMER_USER: 'mutation/createcustomeruser',
  MUTATION_UPDATE_CUSTOMER_USER: 'mutation/updatecustomeruser',
  MUTATION_UPDATE_CUSTOMER_ADDRESS: 'mutation/updatecustomeraddress',
  MUTATION_CREATE_CUSTOMER_ADDRESS: 'mutation/createcustomeraddress',

  QUERY_ROLE: 'query/role',
  QUERY_SEARCH_ROLES: 'query/searchroles',
  MUTATION_UPDATE_ROLE: 'mutation/updaterole',

  QUERY_ORDER_FINANCING: 'query/orderfinancing',
  QUERY_SEARCH_ORDERS: 'query/searchorders',
  QUERY_SEARCH_ORDER_COMMENTS: 'query/searchordercomments',
  QUERY_SEARCH_ORDER_CHANGELOGS: 'query/searchorderchangelogs',
  QUERY_SEARCH_ORDER_TASKS: 'query/searchordertasks',
  QUERY_ORDER_CUSTOMER_NOTES: 'query/ordercustomernotes',
  QUERY_ORDER_FINANCE_CANCEL_REASONS: 'query/orderfinancecancelreasons',
  QUERY_ORDER_FINANCE_REJECT_REASONS: 'query/orderfinancerejectreasons',

  MUTATION_CREATE_ORDER_CARE_CALL_TASK: 'mutation/createordercarecalltask',
  MUTATION_DELETE_ORDER_CARE_CALL_TASK: 'mutation/deleteordercarecalltask',
  MUTATION_CREATE_ORDER_REGISTRATION_INITIATED_TASK:
    'mutation/createorderregistrationinitiatedtask',
  MUTATION_DELETE_ORDER_REGISTRATION_INITIATED_TASK:
    'mutation/deleteorderregistrationinitiatedtask',
  MUTATION_CREATE_ORDER_DOCUMENTS_REQUESTED_TASK:
    'mutation/createorderdocumentsrequestedtask',
  MUTATION_DELETE_ORDER_DOCUMENTS_REQUESTED_TASK:
    'mutation/deleteorderdocumentsrequestedtask',
  MUTATION_CREATE_ORDER_HANDOVER_APPOINTMENT_TASK:
    'mutation/createorderhandoverappointmenttask',
  MUTATION_DELETE_ORDER_HANDOVER_APPOINTMENT_TASK:
    'mutation/deleteorderhandoverappointmenttask',
  MUTATION_CREATE_ORDER_WARRANTY_CONTRACT_SENT_TASK:
    'mutation/createorderwarrantycontractsenttask',
  MUTATION_DELETE_ORDER_WARRANTY_CONTRACT_SENT_TASK:
    'mutation/deleteorderwarrantycontractsenttask',
  MUTATION_CREATE_ORDER_BRANCH_CAR_CHECKUP_TASK:
    'mutation/createorderbranchcarcheckuptask',
  MUTATION_DELETE_ORDER_BRANCH_CAR_CHECKUP_TASK:
    'mutation/deleteorderbranchcarcheckuptask',
  MUTATION_CREATE_ORDER_CAR_READY_FOR_PICKUP_CALL_TASK:
    'mutation/createordercarreadyforpickupcalltask',
  MUTATION_DELETE_ORDER_CAR_READY_FOR_PICKUP_CALL_TASK:
    'mutation/deleteordercarreadyforpickupcalltask',
  MUTATION_CREATE_ORDER_WARRANTY_CONTRACT_SIGNED_TASK:
    'mutation/createorderwarrantycontractsignedtask',
  MUTATION_DELETE_ORDER_WARRANTY_CONTRACT_SIGNED_TASK:
    'mutation/deleteorderwarrantycontractsignedtask',
  MUTATION_CREATE_ORDER_UPLOADED_HANDOVER_DOCUMENT_TASK:
    'mutation/createorderuploadedhandoverdocumenttask',
  MUTATION_DELETE_ORDER_UPLOADED_HANDOVER_DOCUMENT_TASK:
    'mutation/deleteorderuploadedhandoverdocumenttask',
  MUTATION_CREATE_ORDER_FINAL_DOCUMENTS_RECEIVED_TASK:
    'mutation/createorderfinaldocumentsreceivedtask',
  MUTATION_DELETE_ORDER_FINAL_DOCUMENTS_RECEIVED_TASK:
    'mutation/deleteorderfinaldocumentsreceivedtask',
  MUTATION_CREATE_ORDER_HAPPINESS_CALL_TASK:
    'mutation/createorderhappinesscalltask',
  MUTATION_DELETE_ORDER_HAPPINESS_CALL_TASK:
    'mutation/deleteorderhappinesscalltask',
  MUTATION_CREATE_ORDER_CLAIM_RECEIVED_TASK:
    'mutation/createorderclaimreceivedtask',
  MUTATION_DELETE_ORDER_CLAIM_RECEIVED_TASK:
    'mutation/deleteorderclaimreceivedtask',
  MUTATION_CREATE_ORDER_CLAIM_CLOSED_TASK:
    'mutation/createorderclaimclosedtask',
  MUTATION_DELETE_ORDER_CLAIM_CLOSED_TASK:
    'mutation/deleteorderclaimclosedtask',
  MUTATION_CREATE_ORDER_CAR_READY_FOR_HOME_DELIVERY_TASK:
    'mutation/createordercarreadyforhomedeliverytask',
  MUTATION_DELETE_ORDER_CAR_READY_FOR_HOME_DELIVERY_TASK:
    'mutation/deleteordercarreadyforhomedeliverytask',
  MUTATION_CREATE_ORDER_CUSTOMER_AVAILABILITY_FOR_HOME_DELIVERY_TASK:
    'mutation/createordercustomeravailabilityforhomedeliverytask',
  MUTATION_DELETE_ORDER_CUSTOMER_AVAILABILITY_FOR_HOME_DELIVERY_TASK:
    'mutation/deleteordercustomeravailabilityforhomedeliverytask',
  MUTATION_CREATE_DOCUMENTS_REGISTRATION_READY_TASK:
    'mutation/createorderdocumentsregistrationreadytask',
  MUTATION_DELETE_DOCUMENTS_REGISTRATION_READY_TASK:
    'mutation/deleteorderdocumentsregistrationreadytask',
  MUTATION_CREATE_ORDER_CANCELLATION_REQUESTED_TASK:
    'mutation/createordercancellationrequestedtask',
  MUTATION_DELETE_ORDER_CANCELLATION_REQUESTED_TASK:
    'mutation/deleteordercancellationrequestedtask',

  MUTATION_CREATE_ORDER: 'mutation/createorder',
  MUTATION_UPDATE_ORDER: 'mutation/updateorder',
  MUTATION_UPDATE_ORDER_ITEM_PRICE_GROSS: 'mutation/updateorderitempricegross',
  MUTATION_UPDATE_ORDER_ITEM_DISCOUNT_GROSS:
    'mutation/updateorderitemdiscountgross',
  MUTATION_UPDATE_ORDER_FINANCING: 'mutation/updateorderfinancing',
  MUTATION_PRE_APPROVE_ORDER_FINANCING: 'mutation/preapproveorderfinancing',
  MUTATION_APPROVE_ORDER_FINANCING: 'mutation/approveorderfinancing',
  MUTATION_UPDATE_ORDER_CAR_REGISTRATION: 'mutation/updateordercarregistration',
  MUTATION_UPDATE_ORDER_PAYMENT_DETAILS: 'mutation/updateorderpaymentdetails',
  MUTATION_CREATE_ORDER_COMMENT: 'mutation/createordercomment',
  MUTATION_MOVE_ORDER_TO_DELIVERED: 'mutation/moveordertodelivered',
  MUTATION_MOVE_ORDER_TO_VERIFIED: 'mutation/moveordertoverified',
  MUTATION_MOVE_ORDER_TO_CONTRACT_SENT: 'mutation/moveordertocontractsent',
  MUTATION_UPDATE_ORDER_DOCUMENT_HANDOVER_ON:
    'mutation/updateorderdocumenthandoveron',
  MUTATION_UPDATE_PLANNED_HANDOVER_DATE_TIME_RANGE:
    'mutation/updateplannedhandoverdatetimerange',
  MUTATION_UPDATE_ORDER_PLANNED_CAR_HANDOVER_ON:
    'mutation/updateorderplannedcarhandoveron',
  MUTATION_UPDATE_ORDER_VEHICLE_DELIVERED_ON:
    'mutation/updateordervehicledeliveredon',
  MUTATION_MOVE_ORDER_TO_CANCELED: 'mutation/moveordertocanceled',
  MUTATION_MOVE_ORDER_TO_CONTRACT_SIGNED: 'mutation/moveordertocontractsigned',
  MUTATION_CREATE_ORDER_CUSTOMER_PRIVATE: 'mutation/createordercustomerprivate',
  MUTATION_UPDATE_ORDER_CUSTOMER_PRIVATE: 'mutation/updateordercustomerprivate',
  MUTATION_UPDATE_ORDER_CUSTOMER_PRIVATE_BY_ID:
    'mutation/updateordercustomerprivatebyid',
  MUTATION_UPDATE_ORDER_IS_BRANCH_CHECKUP_SUCCESSFUL:
    'mutation/updateorderisbranchcheckupsuccessful',

  MUTATION_UPDATE_ORDER_CAR_REGISTRATION_ADDRESS:
    'mutation/updateordercarregistrationaddress',
  MUTATION_UPDATE_ORDER_DOCUMENT_SHIPPING_ADDRESS:
    'mutation/updateorderdocumentshippingaddress',
  MUTATION_UPDATE_ORDER_SHIPPING_ADDRESS: 'mutation/updateordershippingaddress',
  MUTATION_UPDATE_ORDER_SHIPPING_ADDRESS_FORCE:
    'mutation/updateordershippingaddressforce',
  MUTATION_UPDATE_ORDER_BILLING_ADDRESS: 'mutation/updateorderbillingaddress',

  MUTATION_UPDATE_RETAIL_SHIPPING_ADDRESS_FORCE:
    'mutation/updateretailshippingaddressforce',
  MUTATION_UPDATE_ORDER_BRANCH: 'mutation/updateorderbranch',
  MUTATION_UPDATE_CUSTOMER_EXPECTED_MAX_ETA:
    'mutation/updatecustomerexpectedmaxeta',

  QUERY_SEARCH_INVOICES: 'query/searchinvoices',
  QUERY_SEARCH_CREDIT_NOTES: 'query/searchcreditnotes',
  MUTATION_CREATE_CREDIT_NOTES: 'mutation/createcreditnotes',
  MUTATION_UPDATE_CREDIT_NOTE: 'mutation/updatecreditnote',
  MUTATION_UPDATE_INVOICE: 'mutation/updateinvoice',
  MUTATION_CREATE_INVOICE_STANDARD: 'mutation/createinvoicestandard',
  MUTATION_CREATE_INVOICE_DOWN_PAYMENT: 'mutation/createinvoicedownpayment',
  MUTATION_UPDATE_TEST_ORDER_DOWN_PAYMENT:
    'mutation/updatetestorderdownpayment',

  QUERY_SEARCH_RETAIL_PAYMENTS: 'query/searchretailpayments',
  MUTATION_CREATE_RETAIL_PAYMENT: 'mutation/createretailpayment',
  MUTATION_UPDATE_RETAIL_PAYMENT: 'mutation/updateretailpayment',
  MUTATION_CANCEL_RETAIL_PAYMENT: 'mutation/cancelretailpayment',
  MUTATION_IMPORT_RETAIL_PAYMENT_ITEMS: 'mutation/importretailpaymentitems',
  QUERY_SEARCH_REFUNDS: 'query/searchrefunds',
  MUTATION_CREATE_REFUND: 'mutation/createrefund',
  QUERY_SEARCH_OUTBOUND_PAYMENT: 'query/searchoutboundpayment',
  MUTATION_CREATE_OUTBOUND_PAYMENT: 'mutation/createoutboundpayment',
  MUTATION_UPDATE_OUTBOUND_PAYMENT: 'mutation/updateoutboundpayment',
  MUTATION_UPDATE_REFUND: 'mutation/updaterefund',
  MUTATION_UPDATE_REFUND_AMOUNT: 'mutation/updaterefundamount',
  MUTATION_CREATE_REFUND_OVERWRITE_DETAILS:
    'mutation/createrefundwithbankdetails',
  MUTATION_RETRY_REFUND: 'mutation/retryrefund',

  QUERY_RETAIL_AD_PNL_DATA: 'query/retailadpnldata',
  QUERY_SEARCH_RETAIL_AD_PNL_DATA: 'query/searchretailadpnldata',
  QUERY_SEARCH_RETAIL_DATA_IMPORT: 'query/searchretaildataimport',
  MUTATION_IMPORT_PROFIT_AND_LOSS_ITEMS: 'mutation/importprofitandlossitems',

  MUTATION_CREATE_PNL_ITEM: 'mutation/createpnlitem',
  MUTATION_UPDATE_PNL_ITEM: 'mutation/updatepnlitem',
  MUTATION_DELETE_PNL_ITEM: 'mutation/deletepnlitem',

  QUERY_SEARCH_PNL_PARTNERS: 'query/searchpnlpartners',
  MUTATION_CREATE_PNL_PARTNER: 'mutation/createpnlpartner',
  MUTATION_DELETE_PNL_PARTNER: 'mutation/deletepnlpartner',

  QUERY_SEARCH_DOCUMENTS: 'query/searchdocuments',
  MUTATION_CREATE_DOCUMENT: 'mutation/createdocument',
  MUTATION_PATCH_DOCUMENT: 'mutation/patchdocument',

  QUERY_REFURBISHMENT: 'query/refurbishment',
  QUERY_REFURBISHMENT_TYPES: 'query/getrefurbishmenttypes',
  QUERY_REFURBISHMENTS_OVERVIEW: 'query/refurbishmentsoverview',
  QUERY_REFURBISHMENTS_BY_RETAIL_AD_ID: 'query/refurbishmentsbyretailadid',
  MUTATION_CREATE_REFURBISHMENT: 'mutation/createrefurbishment',
  MUTATION_UPDATE_REFURBISHMENT: 'mutation/updaterefurbishment',
  MUTATION_UPDATE_REFURBISHMENT_STATE: 'mutation/updaterefurbishmentstate',

  QUERY_SEARCH_PNL_ITEM_TYPES: 'query/searchpnlitemtypes',
  MUTATION_CREATE_PNL_ITEM_TYPE: 'mutation/createpnlitemtype',
  MUTATION_DELETE_PNL_ITEM_TYPE: 'mutation/deletepnlitemtype',

  QUERY_RETAIL_AD_BY_AD_ID: 'query/retailadbyadid',
  MUTATION_UPDATE_RETAIL_AD_AUTO1_RETURN_REASON:
    'mutation/updateretailadauto1returnreason',
  MUTATION_UPDATE_RETAIL_AD_AUTO1_RETURN_ON:
    'mutation/updateretailadauto1returnon',
  MUTATION_UPDATE_RETAIL_AD_FIRST_IMPORT_ON:
    'mutation/updateretailadfirstimporton',
  MUTATION_UPDATE_RETAIL_AD_AUTOHERO_PURCHASE_ON:
    'mutation/updateretailadautoheropurchaseon',
  MUTATION_UPDATE_RETAIL_AD_AUTO1_BUY_PRICE:
    'mutation/updateretailadauto1buyprice',
  MUTATION_UPDATE_RETAIL_AD_HIDING_REASON:
    'mutation/updateretailadhidingreason',
  MUTATION_UPDATE_RETAIL_AD_RETAIL_READY_DATE:
    'mutation/updateretailadretailreadydate',
  MUTATION_MOVE_RETAIL_AD_TO_RETURN_TO_AUTO1:
    'mutation/moveretailadtoreturntoauto1',
  MUTATION_MOVE_RETAIL_AD_TO_RETURN_TO_IMPORTED_TO_RETAIL:
    'mutation/moveretailadtoimportedtoretail',
  MUTATION_IMPORT_DAD_CAR_REGISTRATION:
    'mutation/importdadcarregistrationitems',

  QUERY_AD_SECONDARY_WHEELS: 'query/retailadsecondarywheels',
  QUERY_ORDER_SECONDARY_WHEELS: 'query/retailad/secondarywheels',
  QUERY_RETAIL_AD_DAMAGE: 'query/retailaddamage',
  MUTATION_UPDATE_RETAIL_AD_DAMAGE_DISPLAY_TO_CUSTOMER_FRONTEND:
    'mutation/updateretailaddamagedisplaytocustomerfrontend',
  MUTATION_AD_SECONDARY_WHEELS: 'mutation/updateretailadsecondarywheels',
  MUTATION_UPDATE_AD_SECONDARY_WHEELS_IMG:
    'mutation/updateretailadsecondarywheelsimages',
  MUTATION_ORDER_ADD_SECONDARY_WHEELS: 'mutation/createorderitem',
  MUTATION_ORDER_DELETE_SECONDARY_WHEELS: 'mutation/deleteorderitem',
  MUTATION_UPDATE_SECONDARY_WHEELS_DETAILS:
    'mutation/updateretailadsecondarywheelsdetails',
  MUTATION_UPDATE_SECONDARY_WHEELS_RIMS_DETAILS:
    'mutation/updateretailadsecondarywheelsrimsdetails',
  MUTATION_UPDATE_SECONDARY_WHEELS_TIRES_DETAILS:
    'mutation/updateretailadsecondarywheelstiresdetails',

  QUERY_SEARCH_CAR_REGISTRATION_ACTIONS: 'query/carregistrationactions',
  MUTATION_IMPORT_CAR_REGISTRATION_ACTION_ITEMS:
    'mutation/importcarregistrationactionitems',
  MUTATION_IMPORT_INVENTORY_SOURCING_ITEMS:
    'mutation/importinventorysourcingitems',

  MUTATION_APPLY_PRICE_UPDATE: 'mutation/applypriceupdate',

  QUERY_SEARCH_TRADE_IN_BY_STOCK_NUMBER: 'query/searchtradeinbystocknumber',
  MUTATION_GET_ATTACHABLE_TRADE_IN: 'mutation/getAttachableTradeIn',

  MUTATION_CREATE_PRODUCT: 'mutation/createproduct',
  MUTATION_DELETE_PRODUCT: 'mutation/deleteproduct',
  MUTATION_UPDATE_PRODUCT: 'mutation/updateproduct',

  QUERY_SEARCH_PRODUCTS: 'query/searchproducts',

  MUTATION_CREATE_TRADE_IN: 'mutation/createtradein',
  QUERY_DIGITAL_HANDOVER_PROTOCOL: 'query/digitalhandovercomplementary',
  MUTATION_DIGITAL_HANDOVER_PROTOCOL_SUBMISSION:
    'mutation/createdigitalhandoverprotocol',
  MUTATION_DIGITAL_HANDOVER_PROTOCOL_UPLOAD_IMAGE:
    'mutation/uploaddigitalhandoverimage',
  RESET_HANDOVER_DATE: 'mutation/resetorderhandoverdate',

  UPDATE_RETAIL_AD_IS_TEST: 'mutation/updateretailadistest',
  UPDATE_ORDER_IS_TEST: 'mutation/updateorderistest',

  QUERY_DELIVERY_ROUTE: 'query/deliveryroute',
  QUERY_SEARCH_REFURBISHMENTS: 'query/searchrefurbishments',
  QUERY_SEARCH_REFURBISHMENT_EXIT_CHECKS: 'query/searchrefurbishmentexitchecks',

  MUTATION_CREATE_IMAGE_TAG: 'mutation/createimagetag',
  MUTATION_UPDATE_IMAGE_TAG: 'mutation/updateimagetag',
  MUTATION_DELETE_IMAGE_TAG: 'mutation/deleteimagetag',
  QUERY_SEARCH_IMAGE_TAGS: 'query/searchimagetags',

  MUTATION_CREATE_DOCUMENT_INVENTORY_TYPE:
    'mutation/createdocumentinventorytype',
  MUTATION_DISABLE_DOCUMENT_INVENTORY_TYPE:
    'mutation/disabledocumentinventorytype',
  MUTATION_ENABLE_DOCUMENT_INVENTORY_TYPE:
    'mutation/enabledocumentinventorytype',
  MUTATION_DELETE_DOCUMENT_INVENTORY_ITEM:
    'mutation/deletedocumentinventoryitem',
  MUTATION_IMPORT_DOCUMENT_INVENTORY_ITEMS:
    'mutation/importretaildocumentinventoryitems',

  MUTATION_IMPORT_DOCUMENT_PACKAGES:
    'mutation/retaildocumentpackageupdateimport',

  QUERY_DOCUMENT_INVENTORY_TYPES: 'query/documentinventorytypes',
  QUERY_DOCUMENT_INVENTORY_ITEMS: 'query/ad/documentinventoryitems',

  MUTATION_PUBLISHING_BLOCKERS: 'mutation/updateadblockers',
  MUTATION_ADD_PUBLISHING_BLOCKERS: 'mutation/addpublishingblockers',
  MUTATION_REMOVE_PUBLISHING_BLOCKERS: 'mutation/removeadpublishingblockers',

  QUERY_DOCUMENT_INVENTORY_PACKAGES: 'query/documentinventorypackages',

  // TASK MANAGEMENT
  QUERY_TASKS_SEARCH_OPTIONS: 'query/taskssearchoptions',
  QUERY_SEARCH_HAPPINESS_CALL_TASKS: 'query/searchhappinesscalltasks',
  MUTATION_ASSIGN_HAPPINESS_CALL_TASK: 'mutation/assignhappinesscalltask',
  MUTATION_SELF_ASSIGN_HAPPINESS_CALL_TASK:
    'mutation/selfassignhappinesscalltask',
  MUTATION_COMPLETE_HAPPINESS_CALL_TASK: 'mutation/completehappinesscalltask',

  QUERY_TASKS_MANAGER_SEARCH_OPTIONS: 'query/tasksmanagersearchoptions',
  QUERY_TASK_MANAGER_TASKS: 'query/searchtaskmanagertasks',
  MUTATION_TASK_MANAGER_ASSIGN_TASK: 'mutation/assigntaskmanagertask',
  MUTATION_TASK_MANAGER_UNASSIGN_TASK: 'mutation/unassigntaskmanagertask',
  MUTATION_TASK_MANAGER_SELF_ASSIGN_TASK: 'mutation/selfAssignTaskManagerTask',
  MUTATION_TASK_MANAGER_COMPLETE_TASK: 'mutation/completetaskmanagertask',

  QUERY_DOCUMENT_INVENTORY_PACKAGE_ITEMS: 'query/documentinventoryitems',
  MUTATION_CREATE_DOCUMENT_PACKAGE: 'mutation/createdocumentpackage',
  MUTATION_UPDATE_DOCUMENT_PACKAGE: 'mutation/updatedocumentpackage',
  MUTATION_UPDATE_DOCUMENT_PACKAGE_PARTIAL:
    'mutation/updatedocumentpackagepartial',
  QUERY_ORDER_ADDRESSES: 'query/orderaddresses',
  QUERY_DOCUMENT_ITEM_LOCATIONS: 'query/documentinventoryitemlocations',
  QUERY_DOCUMENT_SHIPPING_DELIVERY_ADDRESSES:
    'query/documentshippingdeliveryaddresses',

  // CLAIMS
  QUERY_ORDER_CLAIMS_VIEW: 'query/searchretailclaims',
  MUTATION_CLAIMS_EDIT_ORDER_CLAIMS: 'mutation/claimseditorderclaims',
  MUTATION_CLAIMS_EDIT_ORDER_CLAIMS_CLOSED:
    'mutation/claimseditorderclaimsclosed',
  MUTATION_CLAIMS_UPLOAD_DOC_CLAIMS_CLOSED:
    'mutation/claimsuploaddocclaimsclosed',
  MUTATION_ORDER_CLAIMS_CREATE: 'mutation/createretailclaimv2',
  MUTATION_ORDER_CLAIMS_UPDATE_STATUS: 'mutation/updateretailorderclaimstate',
  MUTATION_ORDER_CLAIMS_CREATE_SUB_CLAIM: 'mutation/createretailordersubclaim',
  MUTATION_ORDER_CLAIMS_UPDATE_SUB_CLAIM: 'mutation/updateretailordersubclaim',
  MUTATION_ORDER_CLAIMS_CREATE_SUB_CLAIM_DOC:
    'mutation/createretailsubclaimdocument',
  MUTATION_ORDER_CLAIMS_DELETE_SUB_CLAIM_DOC:
    'mutation/deleteretailsubclaimdocument',
  MUTATION_ORDER_CLAIMS_CREATE_SUB_CLAIM_COMMENT:
    'mutation/createretailsubclaimcomment',
  MUTATION_ORDER_CLAIMS_CREATE_CLAIM_COSTS_COMMENT:
    'mutation/createretailclaimcostscomment',
  MUTATION_ORDER_CLAIMS_DELETE_SUB_CLAIM: 'mutation/deleteretailsubclaim',
  QUERY_DOCUMENT_CATEGORIES: 'query/documentcategories',
  QUERY_CLAIMS_ASSIGNEE_VIEW: 'query/searchclaimassigneeusers',
  // TODO: check this permissions
  CLM_SELF_AVAILABILITY_VIEW: 'UserInfo.getSelfAvailability',
  CLM_SELF_AVAILABILITY_EDIT: 'UserInfo.setSelfAvailability',
  CLM_AUTO_LEAD_ASSIGN: 'CustomerLead.autoAssignable',
  CLM_TEAM_AVAILABILITY_EDIT: 'UserInfo.setUserAvailability',

  QUERY_CLAIMS_OVERVIEW: 'mutation/claimsviewclaimsoverview',
  QUERY_ORDER_CLAIMS: 'mutation/claimsvieworderclaims',
  QUERY_CLAIM_ORDER_DETAILS: 'query/claimorderdetails',

  MUTATION_ASSIGN_CLAIMS: 'mutation/updateretailorderclaimassigneeuser',
  QUERY_REFUNDS_WITH_CREDIT_NOTES_DISTRIBUTE:
    'query/refundswithcreditnotesdistribute',
  MUTATION_CREATE_REFUNDS_WITH_CREDIT_NOTES_DISTRIBUTION:
    'mutation/createrefundswithcreditnotesdistribution',
  MUTATION_CLAIM_COSTS: 'mutation/distributeretailclaimcosts',

  MUTATION_CREATE_WORKSHOP_DETAILS: 'mutation/createretailclaimworkshopdetails',
  MUTATION_UPDATE_WORKSHOP_DETAILS: 'mutation/updateRetailClaimworkshopDetails',

  MUTATION_SET_CLAIM_COST_APPROVE_1: 'mutation/setretailclaimcostapprove1',
  MUTATION_SET_CLAIM_COST_APPROVE_2: 'mutation/setretailclaimcostapprove2',
  MUTATION_SET_CLAIM_COST_APPROVE_3: 'mutation/setretailclaimcostapprove3',
  MUTATION_SET_CLAIM_COST_APPROVE_1_FORCE:
    'mutation/setretailclaimcostapprove1_force',
  MUTATION_SET_CLAIM_COST_APPROVE_2_FORCE:
    'mutation/setretailclaimcostapprove2_force',
  MUTATION_SET_CLAIM_COST_APPROVE_3_FORCE:
    'mutation/setretailclaimcostapprove3_force',
  MUTATION_CANCEL_CLAIM_COST: 'mutation/cancelretailclaimcost',
  MUTATION_SET_CLAIM_COST_CUSTOMER_DECISION:
    'mutation/setretailclaimcostcustomerdecision',
  MUTATION_SET_CLAIM_COST_CUSTOMER_DECISION_FORCE:
    'mutation/setretailclaimcostcustomerdecision_force',
  MUTATION_DELETE_CLAIM_COST: 'mutation/deleteretailclaimcost',
  MUTATION_UPDATE_CLAIM_COST: 'mutation/updateretailclaimcost',
  MUTATION_CREATE_CLAIM_COST: 'mutation/createretailclaimcost',

  // Order History
  MUTATION_ORDER_COMMENT_PIN: 'mutation/pinordercomment',

  // Invoice Auto creation
  MUTATION_CREATE_DOWN_PAYMENT_AUTOMATIC_INVOICE:
    'mutation/createoracledownpaymentinvoice',
  MUTATION_CREATE_STANDARD_AUTOMATIC_INVOICE:
    'mutation/createoraclestandardinvoice',

  // Appointments for home delivery
  QUERY_APPOINTMENT_ORDER_SEARCH: 'query/appointmentordersearch',
  QUERY_APPOINTMENT_DETAILS: 'query/appointmentorderdetails',
  MUTATION_CREATE_APPOINTMENT: 'mutation/createappointment',
  MUTATION_UPDATE_APPOINTMENT: 'mutation/updateappointment',
  MUTATION_RESCHEDULE_APPOINTMENT: 'mutation/rescheduleappointment',

  // Appointments for branch pickup
  QUERY_BRANCH_PICKUP_APPOINTMENT_ORDER_SEARCH:
    'query/appointmentBranchPickupOrderSearch',
  QUERY_BRANCH_PICKUP_APPOINTMENT_DETAILS:
    'query/appointmentBranchPickupOrderDetails',
  MUTATION_CREATE_BRANCH_PICKUP_APPOINTMENT:
    'mutation/createBranchPickupAppointment',
  MUTATION_UPDATE_BRANCH_PICKUP_APPOINTMENT:
    'mutation/updateBranchPickupAppointment',
  MUTATION_RESCHEDULE_BRANCH_PICKUP_APPOINTMENT:
    'mutation/rescheduleBranchPickupAppointment',

  // Publishing Queue
  QUERY_PUBLISHING_QUEUE: 'query/publishingQueue',

  // Inbound Payments
  QUERY_SEARCH_RETAIL_PAYMENT: 'query/retailpayment',
  QUERY_SEARCH_INTERNAL_PAYMENT: 'query/internaltransferpaymentbyid',
  QUERY_SEARCH_INTERNAL_PAYMENTS: 'query/searchinternaltransferpayments',
  QUERY_SEARCH_UNIDENTIFIED_PAYMENT: 'query/unidentifiedpaymentbyid',
  QUERY_SEARCH_UNIDENTIFIED_PAYMENTS: 'query/searchretailunidentifiedpayments',

  // Finance Risk Evaluation
  QUERY_FINANCE_RISK_EVALUATION_BY_ORDER: 'query/financeriskevaluationbyorder',
  MUTATION_SAVE_FINANCE_RISK_EVALUATION: 'mutation/savefinanceriskevaluation',

  // Super Order Editing
  MUTATION_UPDATE_RETAIL_CUSTOMER_FORCE: 'mutation/updateretailcustomer_force',
  MUTATION_UPDATE_RETAIL_ADDRESS_FORCE: 'mutation/updateretailaddress_force',
  MUTATION_CREATE_ORDER_ITEM_FORCE: 'mutation/createorderitem_force',
  MUTATION_DELETE_ORDER_ITEM_FORCE: 'mutation/deleteorderitem_force',
  MUTATION_CREATE_RETAIL_CUSTOMER_FORCE: 'mutation/createretailcustomer_force',
  MUTATION_DELETE_RETAIL_CUSTOMER_FORCE: 'mutation/deleteretailcustomer_force',
  MUTATION_UPDATE_ORDER_DISCOUNT_GROSS_FORCE:
    'mutation/updateorderitemdiscountgross_force',
  MUTATION_UPDATE_ORDER_PAYMENT_DETAILS_FORCE:
    'mutation/updateorderpaymentdetails_force',
  QUERY_ORDERS_VIEW_ORDER_DETAILS: 'query/ordersvieworderdetails',

  // Edit Claims FULL Levels Reset Approval
  MUTATION_SETRETAILCLAIMCOSTAPPROVE1_RESET:
    'mutation/setretailclaimcostapprove1_reset',
  MUTATION_SETRETAILCLAIMCOSTAPPROVE2_RESET:
    'mutation/setretailclaimcostapprove2_reset',
  MUTATION_SETRETAILCLAIMCOSTAPPROVE3_RESET:
    'mutation/setretailclaimcostapprove3_reset',

  MUTATION_UPDATE_CLAIM_STATE_DUE_DATE: 'mutation/updateclaimstateduedate',
  MUTATION_UPDATE_CLAIM_STATE_COMMENT: 'mutation/updateclaimstatecomment',

  CLAIMS_STATE_DUE_DAYS_CONFIG: 'query/claimstateduedaysconfig',
  UPDATE_CLAIMS_STATE_DUE_DAYS_CONFIG: 'mutation/updateclaimstateduedaysconfig',
};
