import { find, flow, isObject } from 'lodash/fp';

import { getFileNameFromUrl, getFileTypeByName } from '../../../helpers/file';
import { DocumentModel, ViewerDocumentModel } from '../types';

function mapDocument({ fileName, fileType, ...document }: DocumentModel) {
  fileName = fileName || getFileNameFromUrl(document.fullUrl);

  return {
    ...document,
    fileName,
    fileType: fileType || getFileTypeByName(fileName),
  } as ViewerDocumentModel;
}

interface FindDocumentByActiveUrlProps {
  activeUrl: string;
  documents: ViewerDocumentModel[];
}

export const findDocumentByActiveUrl = ({
  activeUrl,
  documents,
}: FindDocumentByActiveUrlProps): ViewerDocumentModel =>
  flow(find<DocumentModel>({ fullUrl: activeUrl }), (x) =>
    isObject(x) ? mapDocument(x) : undefined,
  )(documents);

interface MapDocumentsProps {
  documents: DocumentModel[];
}
export const mapDocuments = ({ documents }: MapDocumentsProps) =>
  documents.map(mapDocument);
