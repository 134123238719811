import {
  LoadLinkedOrdersQuery,
  OrderPaymentState,
  OrderType,
} from '@/apollo/gql-types';

export const useShouldShowAdditionalOrderWarrantyDetails = (
  orderType: OrderType,
  linkedOrders: LoadLinkedOrdersQuery['linkedOrders']['entities'],
) => {
  if (orderType !== OrderType.Standard) {
    return false;
  }

  const additionalLinkedOrders = linkedOrders.filter(
    (order) => order?.type === OrderType.Additional,
  );

  const additionalLinkedOrdersWithWarranty = additionalLinkedOrders.filter(
    (order) => order.warrantyDetails?.length > 0,
  );

  const hasAdditionalFullyPaidLinkedOrderWithWarranty =
    additionalLinkedOrdersWithWarranty.some(
      (order) => order.paymentStatus === OrderPaymentState.FullyPaid,
    );

  return hasAdditionalFullyPaidLinkedOrderWithWarranty;
};
