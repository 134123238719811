/* eslint-disable no-unused-vars */
import { flow, keys, map } from 'lodash/fp';

export const enum CLAIM_COST_EDITABLE_STATUSES {
  EDITABLE = 'EDITABLE',
}

export const enum CLAIM_COST_PAYMENT_STATUSES {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  FAILURE = 'FAILURE',
}

export const enum ORDER_CLAIMS_STATUSES {
  NONE = 'NONE',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export enum CLOSE_REASON_STATES {
  STANDARD_HAPPY_FLOW = 'STANDARD_HAPPY_FLOW',
  WRONG_STRUCTURE = 'WRONG_STRUCTURE',
  CUSTOMER_NOT_RESPONDING = 'CUSTOMER_NOT_RESPONDING',
}

export const CLOSE_REASON_TRANSLATIONS = {
  [CLOSE_REASON_STATES.STANDARD_HAPPY_FLOW]:
    'wkda_retail_claim.claim.closed_reason.resolved',
  [CLOSE_REASON_STATES.WRONG_STRUCTURE]:
    'wkda_retail_claim.claim.closed_reason.wrong_structure',
  [CLOSE_REASON_STATES.CUSTOMER_NOT_RESPONDING]:
    'wkda_retail_claim.claim.closed_reason.customer_not_responding',
};

export const CLOSE_REASON_OPTIONS = [
  {
    label: CLOSE_REASON_TRANSLATIONS[CLOSE_REASON_STATES.STANDARD_HAPPY_FLOW],
    value: CLOSE_REASON_STATES.STANDARD_HAPPY_FLOW,
  },
  {
    label: CLOSE_REASON_TRANSLATIONS[CLOSE_REASON_STATES.WRONG_STRUCTURE],
    value: CLOSE_REASON_STATES.WRONG_STRUCTURE,
  },
  {
    label:
      CLOSE_REASON_TRANSLATIONS[CLOSE_REASON_STATES.CUSTOMER_NOT_RESPONDING],
    value: CLOSE_REASON_STATES.CUSTOMER_NOT_RESPONDING,
  },
];

export const enum CLAIM_SOURCE_TYPES {
  AUTO_HERO = 'AUTO_HERO',
  BACK_OFFICE = 'BACK_OFFICE',
}

export const CLAIM_SOURCE_TYPE_TRANSLATIONS = {
  [CLAIM_SOURCE_TYPES.AUTO_HERO]:
    'bo.orderClaims.processing.claimSourceType.AUTO_HERO',
  [CLAIM_SOURCE_TYPES.BACK_OFFICE]:
    'bo.orderClaims.processing.claimSourceType.BACK_OFFICE',
};

export const enum CLAIM_COST_STATUSES {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export const enum AUX_USER_TYPE {
  AUTOHERO = 1,
  BACK_OFFICE = 2,
  REFURBISHMENT = 3,
}

// NOTE Order is important
export enum CLAIM_STATES {
  SUBMITTED = 'SUBMITTED',
  ADDITIONAL_PROOF_REQUIRED = 'ADDITIONAL_PROOF_REQUIRED',
  PROOF_SUBMITTED = 'PROOF_SUBMITTED',
  IN_PROGRESS = 'IN_PROGRESS',
  AWAITING_COST_ESTIMATE = 'AWAITING_COST_ESTIMATE',
  AWAITING_REPAIR = 'AWAITING_REPAIR',
  CLOSED = 'CLOSED',
}

export const CLAIM_STATES_TRANSLATIONS: Record<CLAIM_STATES, string> = {
  SUBMITTED: `bo.orderClaims.claim.status.${CLAIM_STATES.SUBMITTED}`,
  ADDITIONAL_PROOF_REQUIRED: `bo.orderClaims.claim.status.${CLAIM_STATES.ADDITIONAL_PROOF_REQUIRED}`,
  PROOF_SUBMITTED: `bo.orderClaims.claim.status.${CLAIM_STATES.PROOF_SUBMITTED}`,
  IN_PROGRESS: `bo.orderClaims.claim.status.${CLAIM_STATES.IN_PROGRESS}`,
  CLOSED: `bo.orderClaims.claim.status.${CLAIM_STATES.CLOSED}`,
  AWAITING_COST_ESTIMATE: `bo.orderClaims.claim.status.${CLAIM_STATES.AWAITING_COST_ESTIMATE}`,
  AWAITING_REPAIR: `bo.orderClaims.claim.status.${CLAIM_STATES.AWAITING_REPAIR}`,
};

export enum PROOF_REQUEST_TYPE {
  OTHER = 'OTHER',
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO',
  DOCUMENT_INVOICE_RECEIPTS = 'DOCUMENT_INVOICE_RECEIPTS',
}

export const PROOF_REQUEST_TYPE_TRANSLATIONS: Record<string, string> = {
  OTHER: `bo.orderClaims.processing.popUp.additionalProof.proofType.${PROOF_REQUEST_TYPE.OTHER}`,
  PHOTO: `bo.orderClaims.processing.popUp.additionalProof.proofType.${PROOF_REQUEST_TYPE.PHOTO}`,
  VIDEO: `bo.orderClaims.processing.popUp.additionalProof.proofType.${PROOF_REQUEST_TYPE.VIDEO}`,
  DOCUMENT_INVOICE_RECEIPTS: `bo.orderClaims.processing.popUp.additionalProof.proofType.${PROOF_REQUEST_TYPE.DOCUMENT_INVOICE_RECEIPTS}`,
};

export enum COST_STATUS {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
}

export enum CUSTOMER_DECISION {
  OFFER_SENT = 'OFFER_SENT',
  NOT_REQUIRED = 'NOT_REQUIRED',
  OFFER_NOT_SENT = 'OFFER_NOT_SENT',
  OFFER_ACCEPTED = 'OFFER_ACCEPTED',
  OFFER_NOT_ACCEPTED = 'OFFER_NOT_ACCEPTED',
}

export const CUSTOMER_DECISION_TRANSLATIONS = {
  OFFER_SENT: 'bo.orderClaims.processing.customerDecisionStatus.offerSent',
  NOT_REQUIRED: 'bo.orderClaims.processing.customerDecisionStatus.notRequired',
  OFFER_NOT_SENT:
    'bo.orderClaims.processing.customerDecisionStatus.offerNotSent',
  OFFER_ACCEPTED:
    'bo.orderClaims.processing.customerDecisionStatus.offerAccepted',
  OFFER_NOT_ACCEPTED:
    'bo.orderClaims.processing.customerDecisionStatus.offerNotAccepted',
};

export const CUSTOMER_DECISION_SEARCH_OPTIONS = [
  {
    label: 'bo.orderClaims.processing.customerDecisionStatus.offerNotSent',
    value: CUSTOMER_DECISION.OFFER_NOT_SENT,
  },
  {
    label: 'bo.orderClaims.processing.customerDecisionStatus.offerSent',
    value: CUSTOMER_DECISION.OFFER_SENT,
  },
  {
    label: 'bo.orderClaims.processing.customerDecisionStatus.offerAccepted',
    value: CUSTOMER_DECISION.OFFER_ACCEPTED,
  },
  {
    label: 'bo.orderClaims.processing.customerDecisionStatus.offerNotAccepted',
    value: CUSTOMER_DECISION.OFFER_NOT_ACCEPTED,
  },
  {
    label: 'bo.orderClaims.processing.customerDecisionStatus.notRequired',
    value: CUSTOMER_DECISION.NOT_REQUIRED,
  },
];

export const CUSTOMER_DECISION_OPTIONS = [
  {
    label: 'bo.orderClaims.processing.customerDecisionStatus.offerNotSent',
    value: CUSTOMER_DECISION.OFFER_NOT_SENT,
  },
  {
    label: 'bo.orderClaims.processing.customerDecisionStatus.offerSent',
    value: CUSTOMER_DECISION.OFFER_SENT,
  },
  {
    label: 'bo.orderClaims.processing.customerDecisionStatus.offerAccepted',
    value: CUSTOMER_DECISION.OFFER_ACCEPTED,
  },
  {
    label: 'bo.orderClaims.processing.customerDecisionStatus.offerNotAccepted',
    value: CUSTOMER_DECISION.OFFER_NOT_ACCEPTED,
  },
];

export enum APPROVAL_LEVEL {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  NOT_APPROVED = 'NOT_APPROVED',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

export const APPROVAL_LEVEL_TRANSLATIONS = {
  [APPROVAL_LEVEL.PENDING]: 'bo.orderClaims.processing.approvalStatus.pending',
  [APPROVAL_LEVEL.APPROVED]:
    'bo.orderClaims.processing.approvalStatus.approved',
  [APPROVAL_LEVEL.NOT_APPROVED]:
    'bo.orderClaims.processing.approvalStatus.notApproved',
};

export const APPROVAL_OPTIONS = [
  {
    label: 'bo.orderClaims.processing.approvalStatus.pending',
    value: APPROVAL_LEVEL.PENDING,
  },
  {
    label: 'bo.orderClaims.processing.approvalStatus.approved',
    value: APPROVAL_LEVEL.APPROVED,
  },
  {
    label: 'bo.orderClaims.processing.approvalStatus.notApproved',
    value: APPROVAL_LEVEL.NOT_APPROVED,
  },
];

export const CLAIM_STATES_OPTIONS = flow(
  keys,
  map((value) => ({ value, label: value })),
)(CLAIM_STATES);

export const PROOF_REQUEST_TYPE_OPTIONS = flow(
  keys,
  map((value) => ({ value, label: value })),
)(PROOF_REQUEST_TYPE);

export enum COST_TYPE {
  WORKSHOP = 'WORKSHOP',
  COMPENSATION_DELAY = 'COMPENSATION_DELAY',
  INVOICE_PAID_BY_CUSTOMER = 'INVOICE_PAID_BY_CUSTOMER',
  OPTICAL_DAMAGES_DIRTY_CAR_NOT_REPAIRED = 'OPTICAL_DAMAGES_DIRTY_CAR_NOT_REPAIRED',
  COMPENSATION_WRONG_CAR_DESCRIPTION_MISSING_CAR_FEATURES = 'COMPENSATION_WRONG_CAR_DESCRIPTION_MISSING_CAR_FEATURES',
}

export const COST_TYPE_TRANSLATIONS: Record<string, string> = {
  [COST_TYPE.WORKSHOP]: 'bo.orderClaims.processing.costType.workshop',
  [COST_TYPE.COMPENSATION_DELAY]:
    'bo.orderClaims.processing.costType.compensationDelay',
  [COST_TYPE.INVOICE_PAID_BY_CUSTOMER]:
    'bo.orderClaims.processing.costType.invoicePaidByCustomer',
  [COST_TYPE.OPTICAL_DAMAGES_DIRTY_CAR_NOT_REPAIRED]:
    'bo.orderClaims.processing.costType.opticalDamagesDirtyCar',
  [COST_TYPE.COMPENSATION_WRONG_CAR_DESCRIPTION_MISSING_CAR_FEATURES]:
    'bo.orderClaims.processing.costType.wrongDescription',
};

export const COST_TYPE_OPTIONS = [
  {
    label: COST_TYPE_TRANSLATIONS[COST_TYPE.INVOICE_PAID_BY_CUSTOMER],
    value: COST_TYPE.INVOICE_PAID_BY_CUSTOMER,
  },
  {
    label:
      COST_TYPE_TRANSLATIONS[COST_TYPE.OPTICAL_DAMAGES_DIRTY_CAR_NOT_REPAIRED],
    value: COST_TYPE.OPTICAL_DAMAGES_DIRTY_CAR_NOT_REPAIRED,
  },
  {
    label:
      COST_TYPE_TRANSLATIONS[
        COST_TYPE.COMPENSATION_WRONG_CAR_DESCRIPTION_MISSING_CAR_FEATURES
      ],
    value: COST_TYPE.COMPENSATION_WRONG_CAR_DESCRIPTION_MISSING_CAR_FEATURES,
  },
  {
    label: COST_TYPE_TRANSLATIONS[COST_TYPE.COMPENSATION_DELAY],
    value: COST_TYPE.COMPENSATION_DELAY,
  },
  {
    label: COST_TYPE_TRANSLATIONS[COST_TYPE.WORKSHOP],
    value: COST_TYPE.WORKSHOP,
  },
];

export const WARRANTY_NOT_COVERED_BY_WARRANTY = 'NOT_COVERED_BY_WARRANTY';

export enum WARRANTY {
  NOT_COVERED_BY_WARRANTY = 'NOT_COVERED_BY_WARRANTY',
  LEGAL_WARRANTY = 'LEGAL_WARRANTY',
  BASIC_WARRANTY = 'BASIC_WARRANTY',
  PREMIUM_WARRANTY = 'PREMIUM_WARRANTY',
}

export const WARRANTY_OPTIONS = [
  {
    label: 'bo.orderClaims.processing.warrantyType.notCovered',
    value: WARRANTY.NOT_COVERED_BY_WARRANTY,
  },
  {
    label: 'bo.orderClaims.processing.warrantyType.legalWarranty',
    value: WARRANTY.LEGAL_WARRANTY,
  },
  {
    label: 'bo.orderClaims.processing.warrantyType.basicWarranty',
    value: WARRANTY.BASIC_WARRANTY,
  },
  {
    label: 'bo.orderClaims.processing.warrantyType.premiumWarranty',
    value: WARRANTY.PREMIUM_WARRANTY,
  },
];

export const WARRANTY_TYPE_TRANSLATIONS: Record<string, string> = {
  NOT_COVERED_BY_WARRANTY: 'bo.orderClaims.processing.warrantyType.notCovered',
  LEGAL_WARRANTY: 'bo.orderClaims.processing.warrantyType.legalWarranty',
  BASIC_WARRANTY: 'bo.orderClaims.processing.warrantyType.basicWarranty',
  PREMIUM_WARRANTY: 'bo.orderClaims.processing.warrantyType.premiumWarranty',
};

export enum CHANGELOG_OPERATIONS {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum CHANGELOG_AUX_USER_TYPES {
  CLASSIFIED_USER = 1,
  BACK_OFFICE = 2,
  REFURBISHMENT_PROVIDER = 3,
}

export enum CLAIM_COST_EDIT_STATES {
  EDITABLE = 'EDITABLE',
}

export const PRICE_INPUT_STEP = 100;
