import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import { SwitchControlled } from '@retail/backoffice-ui';
import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';
import { SelectControlled } from '@retail/backoffice-ui/src/SelectControlled';
import { OptionModel } from '@retail/backoffice-ui/src/types/OptionModel';
import { Button, Card, Col, Form, Row, Tooltip } from 'antd';
import { flow, get, head, isEmpty, map, size, sortBy } from 'lodash/fp';
import { memo, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ClaimCostProcessingSection } from './ClaimCostProcessingSection';
import { ClaimStatus } from './ClaimStatus';
import { FieldCol } from './FieldCol';
import { OnlyShowActive } from './OnlyShowActive';
import cn from './styles.less';

import { useRetailClaimGroupsOptionsQuery } from '@/apollo/gql-types';
import { InputControlled } from '@/components/form/input';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';

interface Props {
  assignees: OptionModel[];
  assigneeViewPermission: ReturnType<typeof useCheckPermissions>;
  assigneeUsersIsLoading?: boolean;
  userAccessibleCountryOptions: OptionModel[];
  isLoading?: boolean;
  refetch: () => void;
  clearController: () => void;
  updateController: <T>(data: T) => void;
}

export const SearchForm = memo<Props>(
  ({
    assignees,
    assigneeViewPermission,
    assigneeUsersIsLoading,
    userAccessibleCountryOptions,
    isLoading,
    refetch,
    clearController,
    updateController,
  }) => {
    const { t } = useTranslation();
    const {
      handleSubmit,
      control,
      formState: { isDirty },
      getValues,
      setValue,
    } = useFormContext();

    const { data: claimGroupsData, loading: claimGroupsLoading } =
      useRetailClaimGroupsOptionsQuery(REQUEST_BASE_OPTIONS);

    const claimGroupsOptions = useMemo(
      () =>
        flow(
          map(({ id, name }) => ({ value: id, label: t(name) })),
          sortBy<OptionModel>('label'),
        )(claimGroupsData?.claimGroups),
      [claimGroupsData?.claimGroups, t],
    );

    const liablePartiesOptions = useMemo(
      (): OptionModel[] =>
        flow(
          head,
          get('liableParties'),
          map(({ value, name }: OptionModel) => ({ value, label: t(name) })),
          sortBy<OptionModel>('label'),
        )(claimGroupsData?.claimGroups),
      [claimGroupsData?.claimGroups, t],
    );

    const onSubmit = useCallback(
      (values) => {
        if (isDirty) {
          updateController(values);
        } else {
          refetch();
        }
      },
      [refetch, isDirty, updateController],
    );

    function handleRefetch() {
      Promise.resolve().then(() => {
        updateController(getValues());
        refetch();
      });
    }

    return (
      <Card title={t('bo.claimsOverview.searchTitle')}>
        <Form
          layout="vertical"
          disabled={isLoading}
          onFinish={handleSubmit(onSubmit)}
        >
          <Row gutter={[16, 0]}>
            <FieldCol>
              <SelectControlled
                allowClear
                mode="multiple"
                data-qa-selector="country"
                options={userAccessibleCountryOptions}
                label={t('bo.claimsOverview.retailCountry')}
                placeholder={t('bo.claims.selectPlaceholder')}
                controllerProps={{
                  name: 'country',
                  control,
                }}
              />
            </FieldCol>
            <ClaimStatus control={control} />
            <FieldCol>
              <InputControlled
                type="string"
                data-qa-selector="orderNumber"
                label={t('bo.claimsOverview.orderNumber')}
                placeholder={t('bo.claims.inputPlaceholder')}
                controllerProps={{
                  name: 'orderNumber',
                  control,
                }}
              />
            </FieldCol>
            {assigneeViewPermission?.isAllowed && (
              <FieldCol>
                <SelectControlled
                  allowClear
                  mode="multiple"
                  options={assignees}
                  data-qa-selector="assignTo"
                  loading={assigneeUsersIsLoading}
                  label={t('bo.claimsOverview.search.assignedTo.title')}
                  checker={(value, options) => (size(options) > 1 ? value : [])}
                  placeholder={t(
                    'bo.claimsOverview.search.assignedTo.placeholder',
                  )}
                  controllerProps={{
                    name: 'assignTo',
                    control,
                  }}
                />
              </FieldCol>
            )}
            <FieldCol>
              <InputControlled
                type="string"
                data-qa-selector="claimNumber"
                label={t('bo.claimsOverview.search.claimNumber.title')}
                placeholder={t(
                  'bo.claimsOverview.search.claimNumber.placeholder',
                )}
                controllerProps={{
                  name: 'claimNumber',
                  control,
                }}
              />
            </FieldCol>
            <FieldCol>
              <InputControlled
                type="string"
                data-qa-selector="stockNumber"
                label={t('bo.claimsOverview.search.stockNumber.title')}
                placeholder={t(
                  'bo.claimsOverview.search.stockNumber.placeholder',
                )}
                controllerProps={{
                  name: 'stockNumber',
                  control,
                }}
              />
            </FieldCol>
            <FieldCol>
              <SelectControlled
                allowClear
                mode="multiple"
                options={claimGroupsOptions}
                loading={claimGroupsLoading}
                data-qa-selector="claimGroups"
                label={t('bo.orderClaims.form.claimGroupLabel')}
                placeholder={t('bo.orderClaims.form.claimGroupPlaceholder')}
                checker={(value, options) => (!isEmpty(options) ? value : [])}
                controllerProps={{
                  name: 'claimGroups',
                  control,
                }}
              />
            </FieldCol>
            <FieldCol>
              <SelectControlled
                allowClear
                mode="multiple"
                loading={claimGroupsLoading}
                options={liablePartiesOptions}
                data-qa-selector="liableParties"
                label={t('bo.orderClaims.form.liablePartyLabel')}
                placeholder={t('bo.orderClaims.form.selectPlaceholder')}
                checker={(value, options) => (!isEmpty(options) ? value : [])}
                controllerProps={{
                  name: 'liableParties',
                  control,
                }}
              />
            </FieldCol>
            <ClaimCostProcessingSection control={control} />
            <OnlyShowActive
              control={control}
              refetch={handleRefetch}
              setValue={setValue}
            />
            <FieldCol>
              <SwitchControlled
                label={t(
                  'bo.orderOverview.searchForm.field.isOnlyShowTrialPeriod',
                )}
                className={cn.section}
                controllerProps={{
                  name: 'isOnlyShowTrialPeriod',
                  control,
                }}
              />
            </FieldCol>
            <Col flex="auto">
              <div className={cn.btn}>
                <Tooltip
                  placement="top"
                  title={t('bo.orderClaims.processing.clear-search.tooltip')}
                >
                  <Button
                    type="default"
                    onClick={clearController}
                    data-qa-selector="clearSearch"
                    disabled={isLoading}
                    icon={<ClearOutlined />}
                  />
                </Tooltip>
                <Tooltip
                  placement="top"
                  title={t('bo.orderClaims.processing.apply-search.tooltip')}
                >
                  <Button
                    data-qa-selector="applySearch"
                    onClick={handleSubmit(onSubmit)}
                    loading={isLoading}
                    htmlType="submit"
                    type="primary"
                    icon={<SearchOutlined />}
                  />
                </Tooltip>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
    );
  },
);
