// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X34TUHK7HDvsek8JYDTnRw\\=\\= {\n  margin-bottom: 20px;\n}\n.Cx7PfVpAe1NExpyUYqkp1Q\\=\\= {\n  display: flex;\n}\n.-OoQ0gNLO6-QHMpV\\+AWeGw\\=\\= {\n  margin-bottom: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/styles.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAEA;EACE,aAAA;AAAF;AAGA;EACE,kBAAA;AADF","sourcesContent":[".offset {\n  margin-bottom: 20px;\n}\n\n.inputWrapper {\n  display: flex;\n}\n\n.label {\n  margin-bottom: 8px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"offset": "X34TUHK7HDvsek8JYDTnRw==",
	"inputWrapper": "Cx7PfVpAe1NExpyUYqkp1Q==",
	"label": "-OoQ0gNLO6-QHMpV+AWeGw=="
};
export default ___CSS_LOADER_EXPORT___;
