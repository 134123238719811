import { makeVar, useReactiveVar } from '@apollo/client';
import { Button, Form, Modal } from 'antd';
import { memo, useCallback } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CLAIM_STATES } from '../../../constants';
import { Entity } from '../../selectors';

import { CloseReasonForm } from './form';
import cn from './styles.less';

import { useUpdateRetailOrderClaimStateMutation } from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';

interface VarProps {
  data: {
    claim: Entity;
  };
  visible?: boolean;
  onSuccess?: () => unknown;
  onDismiss?: () => unknown;
}

export const manageRequestProofModal = makeVar<VarProps>({
  data: null,
  visible: false,
  onSuccess: () => null,
  onDismiss: () => null,
});

export const hideModal = () =>
  manageRequestProofModal({
    data: null,
    visible: false,
    onSuccess: () => null,
    onDismiss: () => null,
  });
export const showModal = ({
  data,
  onSuccess = () => null,
  onDismiss = () => null,
}: VarProps) =>
  manageRequestProofModal({
    data,
    onSuccess,
    onDismiss,
    visible: true,
  });

const initialValues: FieldValues = {
  reason: null,
  description: '',
};

export const CloseReasonModal = memo(() => {
  const { visible, data } = useReactiveVar(manageRequestProofModal);
  const { t } = useTranslation();

  const form = useForm<FieldValues>({
    defaultValues: initialValues,
    mode: 'all',
  });

  const { handleSubmit, control, reset } = form;

  const [updateStatus, { loading: updateStatusLoading }] =
    useUpdateRetailOrderClaimStateMutation({
      ...REQUEST_BASE_OPTIONS,
      awaitRefetchQueries: true,
      refetchQueries: ['SearchRetailClaims'],
    });

  const onSubmit = useCallback(
    async (formData) => {
      await updateStatus({
        variables: {
          id: data?.claim?.id,
          updateState: {
            state: CLAIM_STATES.CLOSED,
            closedReason: formData,
          },
        },
      });
      reset(initialValues);
      hideModal();
    },
    [updateStatus, data?.claim?.id, reset],
  );

  return (
    <Modal
      centered
      width={600}
      visible={visible}
      onCancel={() => {
        reset(initialValues);
        hideModal();
      }}
      footer={
        <div className={cn.modalFooter}>
          <Button
            key="cancel"
            type="default"
            data-qa-selector="modal-cancel-reason-cancel"
            onClick={() => {
              reset(initialValues);
              hideModal();
            }}
          >
            <span data-qa-selector="cancel-reason-modal">
              {t('bo.orderClaims.close.popUp.discard.cta')}
            </span>
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={updateStatusLoading}
            onClick={handleSubmit(onSubmit)}
            data-qa-selector="modal-cancel-reason-submit"
          >
            <span data-qa-selector="save-cancel-reason-modal">
              {t('bo.orderClaims.close.popUp.save.cta')}
            </span>
          </Button>
        </div>
      }
      title={
        <strong
          className="text-uppercase"
          data-qa-selector="cost-comments-title"
        >
          {t('bo.orderClaims.close.popUp.title')}
        </strong>
      }
    >
      <FormProvider {...form}>
        <Form
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
          className={cn.form}
        >
          <CloseReasonForm control={control} />
        </Form>
      </FormProvider>
    </Modal>
  );
});
