import { setSeconds } from 'date-fns';
import {
  every,
  find,
  get,
  isEmpty,
  isNull,
  pick,
  reduce,
  some,
} from 'lodash/fp';
import { TFunction } from 'react-i18next';

import { COST_STATUS, COST_TYPE } from '../constants';

import {
  RetailClaimCostProjection,
  RetailClaimGroupProjection,
  RetailClaimProjection,
  RetailSubClaimDocumentProjection,
  RetailSubClaimProjection,
} from '@/apollo/gql-types';

export const validateCloseState = (
  claim: RetailClaimProjection,
  t: TFunction<'translation', undefined>,
): string => {
  if (isAnyActiveCost(claim.costs)) {
    return t(
      'bo.orderClaims.processing.approvalCustomerDecisionPending.warning',
    );
  }
  const totalSubClaims = claim?.subClaims.reduce(
    (sum, subclaim) => sum + subclaim.costMinorUnits,
    0,
  );

  // TODO isAllCostsRejected(claim.costs) && totalSubClaims === 0
  if (isAllCostsRejected(claim.costs)) {
    return;
  }

  const acceptedCost = claim?.costs.filter((cost) => !isCostRejected(cost));
  const totalAcceptedCost = acceptedCost.reduce(
    (sum, cost) => sum + cost.costMinorUnits,
    0,
  );

  if (totalAcceptedCost !== totalSubClaims) {
    return t('bo.orderClaims.processing.subclaimEmpty.warning');
  }
};

export const isCostAccepted = (cost: RetailClaimCostProjection) =>
  cost.status === COST_STATUS.COMPLETED;

export const isSubClaimsWaitingApproval = (
  costs: RetailClaimCostProjection[],
) => {
  if (isEmpty(costs)) {
    return false;
  }

  return every<RetailClaimCostProjection>(
    ({ status }) => status === COST_STATUS.ACTIVE,
  )(costs);
};

export const isRequiredSubClaimsValues = (
  subClaims: RetailSubClaimProjection[],
  claimGroup: RetailClaimGroupProjection,
) => {
  if (isEmpty(subClaims) || isEmpty(claimGroup)) {
    return false;
  }

  return every<RetailSubClaimProjection>(
    ({ areaId, carPartId, damageId, liablePartyType }) => {
      const nestedKeyValues = [
        ['areas', areaId],
        ['carParts', carPartId],
        ['damages', damageId],
      ];

      if (!liablePartyType) {
        return false;
      }

      let i = -1;
      let nextData = claimGroup;

      while (++i < nestedKeyValues.length) {
        const [prop, id] = nestedKeyValues[i];

        nextData = get([prop], nextData);

        if (isEmpty(nextData)) {
          return true;
        }

        nextData = find({ id }, nextData);

        if (isEmpty(nextData)) {
          return false;
        }
      }

      return true;
    },
  )(subClaims);
};

export const isAnyActiveCost = (costs: RetailClaimCostProjection[]) =>
  some((cost) => !isCostRejected(cost) && !isCostAccepted(cost))(costs);

const isCostRejected = (cost: RetailClaimCostProjection) =>
  cost.status === COST_STATUS.CANCELED;

export const isAllCostsRejected = (costs: RetailClaimCostProjection[]) =>
  every((cost) => isCostRejected(cost))(costs);

const isCostsNotApproved = (costs: RetailClaimCostProjection[]) => {
  return every<RetailClaimCostProjection>(
    ({ status }) => status !== COST_STATUS.CANCELED,
  )(costs);
};

export const isCostTypeWorkshop = (costs: RetailClaimCostProjection[]) =>
  find(({ costType }) => costType === COST_TYPE.WORKSHOP, costs);

export const isWorkshopDetailsIncomplete = ({
  workshopDetails,
  costs,
}: RetailClaimProjection) => {
  if (!isCostTypeWorkshop(costs)) {
    return false;
  }

  if (isCostTypeWorkshop(costs) && isNull(workshopDetails)) {
    return true;
  }

  if (isNull(workshopDetails)) {
    return !isCostsNotApproved(costs);
  }

  return !every(
    (el) => !isNull(el),
    pick(['address', 'carPickedUp', 'carPickedUpOn'], workshopDetails),
  );
};

export interface IGroupedDocument
  extends Pick<
    RetailSubClaimDocumentProjection,
    'createdBy' | 'createdOn' | 'sourceType' | '__typename'
  > {
  documents?: RetailSubClaimDocumentProjection[];
}

export const groupDocumentsByTime = (
  documents: RetailSubClaimDocumentProjection[],
): IGroupedDocument[] => {
  const documentsTimeMap = reduce<
    RetailSubClaimDocumentProjection,
    Record<string, IGroupedDocument>
  >((acc: Record<string, IGroupedDocument>, current) => {
    const { createdOn, createdBy, __typename } = current;
    // Remove seconds and set them to common value (59s) and thus grouping them by minute
    const createdOnInMinutes = setSeconds(
      new Date(createdOn),
      59,
    ).toISOString();
    const groupedDocumentsItem: IGroupedDocument = {
      createdOn: createdOnInMinutes,
      createdBy,
      __typename,
      documents: [],
    };

    if (!acc[createdOnInMinutes]) {
      groupedDocumentsItem.documents.push(current);
      acc[createdOnInMinutes] = groupedDocumentsItem;

      return acc;
    }

    acc[createdOnInMinutes].documents.push(current);

    return acc;
  }, {})(documents);

  return Object.values(documentsTimeMap);
};
