import { format, isValid } from 'date-fns';
import { flow, head, join, split } from 'lodash/fp';

import { createParseDate } from './parseDate';

import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DATE_TIME_SERVER_SHORT,
  ISO_DATE_TIME_FORMAT,
  ISO_DATE_TIME_REG_EXP,
  LOCAL_DATE_FORMAT,
  LOCAL_DATE_TIME_FORMAT,
  TIME_FORMAT,
} from '@/constants/date';

// reexport
export { useFormatDate } from './hooks';

export const parseDate = createParseDate();
export const isValidDate = flow(parseDate, isValid);

export const customFormatDate =
  (dateFormat: string) => (rawDate: string | null | undefined) => {
    const date = parseDate(rawDate);
    return isValid(date) ? format(date, dateFormat) : '';
  };

export const formatDate = customFormatDate(DATE_FORMAT);
export const formatTime = customFormatDate(TIME_FORMAT);
export const formatDateTime = customFormatDate(DATE_TIME_FORMAT);
export const formatDateTimeShort = customFormatDate(DATE_TIME_SERVER_SHORT);
export const formatDateFromTimeToTime = (fromDate: string, toDate: string) =>
  isValidDate(fromDate) && isValidDate(toDate)
    ? `${formatDate(fromDate)}\n${formatTime(fromDate)} - ${formatTime(toDate)}`
    : '';

export const formatLocalDate = customFormatDate(LOCAL_DATE_FORMAT);
export const formatLocalDateTime = customFormatDate(LOCAL_DATE_TIME_FORMAT);
export const formatIsoDateTime = customFormatDate(ISO_DATE_TIME_FORMAT);

export const findAndFormatDateInText = (text?: string) => {
  const result = text?.match(new RegExp(ISO_DATE_TIME_REG_EXP));
  const date = head(result);

  if (!date) {
    return text;
  }

  return flow(split(date), join(formatDateTime(date)))(text);
};
