import { InboxOutlined } from '@ant-design/icons';
import { notification, Typography } from 'antd';
import cns from 'classnames';
import { concat, get, isEmpty, pullAt } from 'lodash/fp';
import { memo, useCallback } from 'react';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import {
  FieldErrors,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { FileList } from './file-list';
import cn from './styles.less';

const { Text } = Typography;

type DropzoneControlledProps<T extends FieldValues> = {
  error: string;
  errors: FieldErrors;
  hint: string;
  label: string;
  maxSizeMB: number;
  disabled?: boolean;
  qaSelector: string;
  placeholder: string;
  controllerProps: UseControllerProps<T>;
};

export const DropzoneControlled = memo<DropzoneControlledProps<FieldValues>>(
  ({
    error,
    errors,
    disabled,
    hint,
    label,
    placeholder,
    maxSizeMB,
    qaSelector,
    controllerProps,
  }) => {
    const { field } = useController(controllerProps);

    const onDrop = useCallback(
      async (accepted: File[], rejected: FileRejection[]) => {
        if (!isEmpty(rejected)) {
          notification.warning({
            message: 'Error', // TODO Add translations
            description: rejected
              .map(({ file, errors }) =>
                errors
                  ?.map(({ code, message }) =>
                    code === ErrorCode.FileInvalidType
                      ? `Invalid file type: ${file?.name} ${file?.type}`
                      : message,
                  )
                  .join(', '),
              )
              .join(', '),
          });
        } else {
          field.onChange(concat(accepted, field.value || []));
        }
      },
      [field],
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      disabled,
      multiple: true,
      maxSize: 1024 * 1024 * maxSizeMB,
    });

    const onRemove = useCallback(
      (item, index) => {
        field.onChange(pullAt(index)(field.value));
      },
      [field],
    );

    const isError = get(controllerProps?.name)(errors);

    return (
      <>
        <div>
          <label className={cn.label} htmlFor="fileUpload">
            {label}
          </label>
          <div
            {...getRootProps()}
            data-qa-selector={qaSelector}
            className={cns(cn.dropzone, { active: isDragActive })}
          >
            <input {...getInputProps()} id="fileUpload" />
            <InboxOutlined style={{ fontSize: 42, color: '#6EB5F2' }} />
            <p className={cn.placeholder}>{placeholder}</p>
            <span className={cn.hint}>{hint}</span>
          </div>
          <div>{isError && <Text type="danger">{error}</Text>}</div>
        </div>
        {!isEmpty(field?.value) && (
          <FileList list={field.value} handleRemove={onRemove} />
        )}
      </>
    );
  },
);
