export const DATE_FORMAT = 'dd/MM/yyyy' as const;
export const TIME_FORMAT = 'HH:mm' as const;
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}` as const;
export const DATE_TIME_SERVER_SHORT = 'yyyy-MM-dd HH:mm' as const;
export const LOCAL_DATE_FORMAT = 'yyyy-MM-dd' as const;
export const LOCAL_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss" as const;
export const ISO_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ssxx" as const;
export const ISO_DATE_TIME_REG_EXP =
  /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;
export const DATE_TIME_FORMAT_BE = "yyyy-MM-dd'T'HH:mm:ss'+0000'";
