import { useCallback } from 'react';

import { useCreateSubclaimDocuments } from '../hooks/useCreateSubclaimDocuments';
import { getCreateCommentPayload } from '../utils';

import { useCreateRetailSubClaimCommentMutation } from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';

interface PayloadArg {
  comment: string;
  newDocuments: File[];
}

interface CreateCommentAndDocumentsArg {
  subClaimId: string;
  retailCountry: string;
}

export function useCreateSubclaimCommentAndDocuments({
  subClaimId,
  retailCountry,
}: CreateCommentAndDocumentsArg) {
  const createDocuments = useCreateSubclaimDocuments({ retailCountry });
  const [createComment] =
    useCreateRetailSubClaimCommentMutation(REQUEST_BASE_OPTIONS);

  return useCallback(
    async ({ comment = '', newDocuments = [] }: PayloadArg) => {
      const newCommentPayload = getCreateCommentPayload({
        subClaimId,
        comment,
      });

      if (newCommentPayload) {
        await createComment({
          variables: newCommentPayload,
        });
      }

      await createDocuments({ subClaimId, newDocuments });
    },
    [createComment, createDocuments, subClaimId],
  );
}
