import { Table } from 'antd';

import { useTableColumns } from './hooks/useTableColumns';
import { useTableDataSource } from './hooks/useTableDataSource';

import {
  ClaimChangelogResponseProjection,
  RetailClaimGroupsOptionsQuery,
} from '@/apollo/gql-types';

interface ChangeLogTableProps {
  claimsChangeLog: ClaimChangelogResponseProjection[];
  claimGroups: RetailClaimGroupsOptionsQuery;
  loading: boolean;
}

export const ChangeLogTable = ({
  claimsChangeLog,
  claimGroups,
  loading,
}: ChangeLogTableProps) => {
  const columns = useTableColumns();
  const dataSource = useTableDataSource({ claimGroups, claimsChangeLog });

  return (
    <Table
      data-qa-selector="changeLogTable"
      rowClassName="changeLogTable-row"
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      tableLayout="fixed"
      bordered
    />
  );
};
