import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import getUserName from '../utils/getUserName';

import useFieldFormattedValue from './useFieldFormattedValue';

import {
  ClaimChangelogResponseProjection,
  Maybe,
  RetailClaimGroupsOptionsQuery,
} from '@/apollo/gql-types';
import { formatDateTime } from '@/helpers/date';

interface IUseTableDataSourceProps {
  claimGroups: RetailClaimGroupsOptionsQuery;
  claimsChangeLog?: Array<Maybe<ClaimChangelogResponseProjection>> | [];
}

export function useTableDataSource({
  claimGroups,
  claimsChangeLog,
}: IUseTableDataSourceProps) {
  const { t } = useTranslation();
  const getFieldFormattedValue = useFieldFormattedValue({ claimGroups });

  return useMemo(() => {
    if (claimsChangeLog.length < 1) {
      return [];
    }

    return claimsChangeLog.map(
      ({
        id,
        operation,
        createdOn,
        type,
        typeNumber,
        field,
        createdBy,
        newValue,
        oldValue,
      }) => ({
        key: id,
        generalData: {
          operation: t(`bo.orderClaims.changeLog.table.operation.${operation}`),
          user: getUserName(createdBy, t),
          createdOn: formatDateTime(createdOn),
        },
        objectData: {
          objectType: t(`bo.orderClaims.changeLog.table.objectType.${type}`),
          objectId: typeNumber,
        },
        field: t(`bo.orderClaims.changeLog.table.field.${field}`),
        oldValue: {
          defaultValue: oldValue,
          formattedValue: getFieldFormattedValue(field, oldValue),
        },
        newValue: {
          defaultValue: newValue,
          formattedValue: getFieldFormattedValue(field, newValue),
        },
      }),
    );
  }, [claimsChangeLog, getFieldFormattedValue, t]);
}
