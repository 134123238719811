import { withPermissions } from '@retail/backoffice-ui/src/Permission';
import { ADDRESS_TYPES } from '@retail/order-constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { InfoCard } from './InfoCard';

import {
  RetailAddressProjection,
  useOrderAddressesQuery,
} from '@/apollo/gql-types';
import { DOMAINS, PERMISSIONS } from '@/constants/permissions';
// eslint-disable-next-line import/order
import { toLocalizedCountry } from '@/utils/i18n/toLocalizedCountry';

import { InlineKeyValueRow } from '@/components/InlineKeyValueRow';
import { NA } from '@/components/NA';

export const ShippingAddress = withPermissions({
  allow: PERMISSIONS.QUERY_ORDER_ADDRESSES,
  domain: DOMAINS.CLAIM_MANAGEMENT,
})(() => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const { data, loading } = useOrderAddressesQuery({
    skip: !orderId,
    variables: {
      orderId,
    },
  });

  const shippingAddress = useMemo(() => {
    const shippingOrderAddress =
      data?.orderAddresses?.find(
        (x) => x.addressType === ADDRESS_TYPES.SHIPPING,
      ) ?? {};

    const { addressId, address, branch } = shippingOrderAddress;
    const isHomeDelivery = !!addressId;

    return isHomeDelivery ? address : branch;
  }, [data]);

  const { street, city, country, zipcode, houseNumber } =
    (shippingAddress as RetailAddressProjection) || {};

  return (
    <InfoCard
      loading={loading}
      size="small"
      title={t(
        'bo.orderClaims.orderSummary.shippingAddress.title.shippingAddress',
      )}
    >
      <InlineKeyValueRow
        qaSelector="street"
        label={t('bo.orderClaims.orderSummary.shippingAddress.title.street')}
        value={
          <>
            {street ?? <NA />} {houseNumber ?? ''}
          </>
        }
      />
      <InlineKeyValueRow
        qaSelector="city"
        label={t('bo.orderClaims.orderSummary.shippingAddress.title.city')}
        value={city}
      />
      <InlineKeyValueRow
        qaSelector="country"
        label={t('bo.orderClaims.orderSummary.shippingAddress.title.country')}
        value={country ? toLocalizedCountry(country) : null}
      />
      <InlineKeyValueRow
        qaSelector="zipcode"
        label={t('bo.orderClaims.orderSummary.shippingAddress.title.zipcode')}
        value={zipcode}
      />
    </InfoCard>
  );
});
