import { endsWith, toLower } from 'lodash';
import { Dispatch, MouseEvent } from 'react';

import Arrows from '../Arrows';
import Document from '../Document';
import Image from '../Image';
import { Video } from '../Video';
import { ViewerDocumentModel } from '../types';

import cn from './styles.less';

interface Props {
  document: ViewerDocumentModel;
  documents: ViewerDocumentModel[];
  open: Dispatch<string>;
  close: VoidFunction;
}

const DocumentViewerBody = ({ document, documents, open, close }: Props) => {
  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    if ((event.target as HTMLDivElement).hasAttribute?.('data-closable')) {
      close();
    }
  };

  const getDocumentComponent = () => {
    const { fileType, fileName } = document;

    if (
      fileType?.mime?.includes('image/') ||
      endsWith(toLower(fileName), 'heic')
    ) {
      return <Image document={document} />;
    }
    if (
      endsWith(toLower(fileName), 'mp4') ||
      endsWith(toLower(fileName), 'mov')
    ) {
      return <Video document={document} />;
    }

    return <Document document={document} />;
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      data-closable
      className={cn.documentViewerBody}
      onMouseDown={handleMouseDown}
    >
      {getDocumentComponent()}
      {documents.length > 1 && (
        <Arrows document={document} documents={documents} open={open} />
      )}
    </div>
  );
};

export default DocumentViewerBody;
