import { SelectControlled } from '@retail/backoffice-ui';
import { toUpper } from 'lodash/fp';
import { useMemo } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FieldCol } from './FieldCol';

import { useRetailFeatureStatusV2Query } from '@/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '@/constants/api';
import { defaultCountry } from '@/constants/common';
import { FEATURE } from '@/constants/features';
import {
  APPROVAL_OPTIONS,
  CUSTOMER_DECISION_SEARCH_OPTIONS,
} from '@/pages/claims/constants';

interface Props {
  control: Control<FieldValues>;
}

export function ClaimCostProcessingSection({ control }: Props) {
  const { t } = useTranslation();
  const approvalStatusOptions = useMemo(
    () =>
      APPROVAL_OPTIONS.map(({ value, label }) => ({
        value,
        label: t(label),
      })),
    [t],
  );
  const customerDecisionOptions = useMemo(
    () =>
      CUSTOMER_DECISION_SEARCH_OPTIONS.map(({ value, label }) => ({
        value,
        label: t(label),
      })),
    [t],
  );
  const { data } = useRetailFeatureStatusV2Query({
    ...REQUEST_BASE_OPTIONS,
    variables: {
      name: FEATURE.RETAIL_CLAIM_COST_PROCESSING,
      context: {
        environment: toUpper(process.env.ENV),
        country: defaultCountry,
        locale: 'de-DE',
      },
    },
  });

  return data?.data?.enabled ? (
    <>
      <FieldCol>
        <SelectControlled
          allowClear
          options={approvalStatusOptions}
          data-qa-selector="approvalLevel1"
          label={t('bo.orderClaims.processing.table.level1Approval.title')}
          placeholder={t(
            'bo.orderClaims.processing.popUp.workshopDetails.carPickedUpStatus.dropdown.placeholder',
          )}
          controllerProps={{
            name: 'approvalLevel1',
            control,
          }}
        />
      </FieldCol>
      <FieldCol>
        <SelectControlled
          allowClear
          options={approvalStatusOptions}
          data-qa-selector="approvalLevel2"
          label={t('bo.orderClaims.processing.table.level2Approval.title')}
          placeholder={t(
            'bo.orderClaims.processing.popUp.workshopDetails.carPickedUpStatus.dropdown.placeholder',
          )}
          controllerProps={{
            name: 'approvalLevel2',
            control,
          }}
        />
      </FieldCol>
      <FieldCol>
        <SelectControlled
          allowClear
          options={approvalStatusOptions}
          data-qa-selector="approvalLevel3"
          label={t('bo.orderClaims.processing.table.level3Approval.title')}
          placeholder={t(
            'bo.orderClaims.processing.popUp.workshopDetails.carPickedUpStatus.dropdown.placeholder',
          )}
          controllerProps={{
            name: 'approvalLevel3',
            control,
          }}
        />
      </FieldCol>
      <FieldCol>
        <SelectControlled
          allowClear
          mode="multiple"
          options={customerDecisionOptions}
          data-qa-selector="customerDecision"
          label={t('bo.orderClaims.processing.table.customerDecision.title')}
          placeholder={t(
            'bo.orderClaims.processing.popUp.workshopDetails.carPickedUpStatus.dropdown.placeholder',
          )}
          controllerProps={{
            name: 'customerDecision',
            control,
          }}
        />
      </FieldCol>
    </>
  ) : null;
}
