// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NtWwWuYK7bGBchm\\+MAfZSA\\=\\= .antd-4-21-7-list-item {\n  padding: 0 !important;\n}\n.NtWwWuYK7bGBchm\\+MAfZSA\\=\\= .antd-4-21-7-list-item a {\n  width: 100%;\n  padding: 12px 24px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.NtWwWuYK7bGBchm\\+MAfZSA\\=\\= .antd-4-21-7-list-item a.active {\n  background-color: #2780e3;\n  color: #fff;\n}\n.NtWwWuYK7bGBchm\\+MAfZSA\\=\\= .antd-4-21-7-list-item:hover {\n  background-color: #e6e6e6;\n}\n.iJa9Ayu71rX6\\+oEOld-KhA\\=\\= {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 200px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/claims/overview/styles.less"],"names":[],"mappings":"AAEA;EAGM,qBAAA;AAHN;AAAA;EAMQ,WAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AAHR;AAKQ;EACE,yBAAA;EACA,WAAA;AAHV;AAOM;EACE,yBAAA;AALR;AAWA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;AATF","sourcesContent":["@import '../../../styles/constants.less';\n\n.list {\n  :global {\n    .@{antd-version}-list-item {\n      padding: 0 !important;\n\n      a {\n        width: 100%;\n        padding: 12px 24px;\n        display: flex;\n        flex-direction: column;\n        align-items: flex-start;\n        white-space: nowrap;\n        overflow: hidden;\n        text-overflow: ellipsis;\n\n        &.active {\n          background-color: #2780e3;\n          color: #fff;\n        }\n      }\n\n      &:hover {\n        background-color: #e6e6e6;;\n      }\n    }\n  }\n}\n\n.spin {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 200px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "NtWwWuYK7bGBchm+MAfZSA==",
	"spin": "iJa9Ayu71rX6+oEOld-KhA=="
};
export default ___CSS_LOADER_EXPORT___;
