import { PathRouteProps } from 'react-router-dom';

import Claims from './pages/claims';
// eslint-disable-next-line import/no-named-as-default
import ClaimsOverview from './pages/ClaimsOverview';

interface RootParams {
  language: string;
}

interface OrderParams extends RootParams {
  orderId: string;
}

interface ClaimParams extends OrderParams {
  claimId: string;
}

interface AdParams extends RootParams {
  adId: string;
}

// ROUTES' LIST
export const ROOT = {
  ROUTE: '/:language',
  LINK: ({ language }: RootParams) => `/${language}`,
};

export const ORDERS = {
  ROUTE: `${ROOT.ROUTE}/orders`,
  LINK: ({ language }: RootParams) => `${ROOT.LINK({ language })}/orders`,
};

export const ORDER = {
  ROUTE: `${ORDERS.ROUTE}/:orderId`,
  LINK: ({ language, orderId }: OrderParams) =>
    `${ORDERS.LINK({ language })}/${orderId}`,
};

export const ORDER_CLAIMS = {
  ROUTE: 'claims',
  ROUTE_RELATIVE: `${ORDER.ROUTE}/claims`,
  ROUTE_RELATIVE_DEEP: `${ORDER.ROUTE}/claims/*`,
  LINK: ({ language, orderId }: OrderParams) =>
    `${ORDER.LINK({ language, orderId })}/claims`,
};

export const ORDER_CLAIMS_DETAILS = {
  ROUTE: ':claimId',
  ROUTE_RELATIVE: `${ORDER_CLAIMS.ROUTE}/:claimId`,
  ROUTE_RELATIVE_DEEP: `${ORDER_CLAIMS.ROUTE}/:claimId/*`,
  LINK: ({ language, orderId, claimId }: ClaimParams) =>
    `${ORDER_CLAIMS.LINK({ language, orderId })}/${claimId}`,
};

export const ORDER_CLAIMS_DETAILS_WORKFLOW = {
  ROUTE: '/workflow',
  ROUTE_RELATIVE: `${ORDER_CLAIMS_DETAILS.ROUTE}/workflow`,
  ROUTE_RELATIVE_DEEP: `${ORDER_CLAIMS_DETAILS.ROUTE}/workflow/*`,
  LINK: ({ language, orderId, claimId }: ClaimParams) =>
    `${ORDER_CLAIMS_DETAILS.LINK({ language, orderId, claimId })}/workflow`,
};

export const ORDER_CLAIMS_DETAILS_CHANGELOG = {
  ROUTE: '/changelog',
  ROUTE_RELATIVE: `${ORDER_CLAIMS_DETAILS.ROUTE}/changelog`,
  ROUTE_RELATIVE_DEEP: `${ORDER_CLAIMS_DETAILS.ROUTE}/changelog/*`,
  LINK: ({ language, orderId, claimId }: ClaimParams) =>
    `${ORDER_CLAIMS_DETAILS.LINK({ language, orderId, claimId })}/changelog`,
};

export const ORDER_CLAIMS_OVERVIEW = {
  ROUTE: 'claims/overview',
  ROUTE_RELATIVE: `${ORDERS.ROUTE}/claims/overview`,
  ROUTE_RELATIVE_DEEP: `${ORDERS.ROUTE}/claims/overview/*`,
  LINK: ({ language }: RootParams) =>
    `${ORDERS.LINK({ language })}/claims/overview`,
};

// ADS
export const AD = {
  ROUTE: `${ROOT.ROUTE}/ad`,
  LINK: ({ language }: RootParams) => `${ROOT.LINK({ language })}/ad`,
};

export const AD_ITEM = {
  ROUTE: ':adId',
  ROUTE_RELATIVE: `${AD.ROUTE}/:adId`,
  ROUTE_RELATIVE_DEEP: `${AD.ROUTE}/:adId/*`,
  LINK: ({ language, adId }: AdParams) => `${AD.LINK({ language })}/${adId}`,
};

const routes: Array<PathRouteProps> = [
  {
    path: ORDER_CLAIMS.ROUTE_RELATIVE_DEEP,
    element: <Claims />,
  },
  {
    path: ORDER_CLAIMS_OVERVIEW.ROUTE_RELATIVE_DEEP,
    element: <ClaimsOverview />,
  },
];

export default routes;
