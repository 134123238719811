import { PropsWithChildren } from 'react';
import { Collapse, CollapsePanelProps } from 'antd';
import cns from 'classnames';

import './styles.less';

type Props = CollapsePanelProps;

export function CollapsePanel({
  className,
  ...rest
}: PropsWithChildren<Props>) {
  return (
    <Collapse.Panel {...rest} className={cns(className, 'custom-panel')} />
  );
}
