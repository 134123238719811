import { formatCurrency } from '@retail/backoffice-ui/src/utils/formatCurrency';
import { CurrencyCodes } from '@retail/currency';
import { orderBy } from 'lodash/fp';
import { useMemo } from 'react';

import { getOrderItemName } from './constants';
import { OrderItem, useOrderItems } from './useOrderItems';
import { useRefundByOrderItemExternalId } from './useRefundByOrderItemExternalId';

const isDiscount = (item: OrderItem) =>
  item.__typename === 'ProductItemProjection' &&
  item.product.type.toLowerCase().includes('discount');

type OrderItemOption = {
  label: string;
  isDefault: boolean;
  value: string;
};

const adaptOrderItemToOption = (
  item: OrderItem,
  refundAmount = 0,
): OrderItemOption => {
  const price = formatCurrency({
    currency: item.priceGross.currencyCode as CurrencyCodes,
    currencyCode: item.priceGross.currencyCode as CurrencyCodes,
    amountMinorUnits: item.priceGross.amountMinorUnits - refundAmount,
  });
  return {
    label: `${getOrderItemName(item)} (${price})`,
    isDefault: item.__typename === 'AdItemProjection',
    value: item.id,
  };
};

export const useOrderItemOptions = (orderId: string) => {
  const orderItems = useOrderItems(orderId);

  const { data: refundByOrderItemExternalId, loading: refundsLoading } =
    useRefundByOrderItemExternalId(orderId);

  return useMemo<OrderItemOption[]>(() => {
    if (refundsLoading) {
      return [];
    }
    const options = (orderItems ?? [])
      .filter((item) => item.priceGross.amountMinorUnits && !isDiscount(item))
      .map((item) =>
        adaptOrderItemToOption(
          item,
          item.externalId && refundByOrderItemExternalId[item.externalId],
        ),
      );
    return orderBy('isDefault', 'desc', options);
  }, [orderItems, refundByOrderItemExternalId, refundsLoading]);
};
