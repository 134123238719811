import {
  castArray,
  filter,
  find,
  flow,
  get,
  map,
  orderBy,
  sortBy,
} from 'lodash/fp';
import { createSelector, createStructuredSelector } from 'reselect';

import { CLAIM_SOURCE_TYPES } from '../constants';
import { claimGroupsSelector, claimsSelector } from '../selectors';

import { UseComplaintsData } from './content/customer-complaints/hooks/useComplaintsDataV2';
import { groupDocumentsByTime } from './helpers';

import {
  RetailClaimGroupProjectionFragmentFragment,
  RetailClaimGroupsOptionsQuery,
  RetailClaimProjection,
  RetailSubClaimCommentProjection,
  RetailSubClaimDocumentProjection,
  RetailSubClaimProjection,
  SearchRetailClaimsQuery,
} from '@/apollo/gql-types';
import { getIsTrial } from '@/utils/claims';

type SubEntity = {
  sourceType?: string;
  createdOn?: string;
};

const getEntitiesBySourceType =
  (sourceType: string) =>
  <T extends SubEntity>(entities: T[]) =>
    flow(
      filter({ sourceType }),
      sortBy<SubEntity>(({ createdOn }) => new Date(createdOn)),
    )(entities);

const filterByCustomer = getEntitiesBySourceType(CLAIM_SOURCE_TYPES.AUTO_HERO);
const filterByBoUser = getEntitiesBySourceType(CLAIM_SOURCE_TYPES.BACK_OFFICE);
const sortByCreatedOn = (entities: SubEntity[]) =>
  sortBy<SubEntity>(({ createdOn }) => new Date(createdOn))(entities);

interface InputSelectorProps {
  claims: SearchRetailClaimsQuery;
  claimGroups: RetailClaimGroupsOptionsQuery;
}

type ItemType<Item> = {
  customer: Item[];
  backOffice: Item[];
};

export type ExtendedSubClaim = Omit<
  RetailSubClaimProjection,
  'comments' | 'documents'
> & {
  reasons: [string, string][];
  comments: ItemType<RetailSubClaimCommentProjection>;
  documents: ItemType<RetailSubClaimDocumentProjection>;
  commentsAndDocuments: (
    | RetailSubClaimCommentProjection
    | RetailSubClaimDocumentProjection
  )[];
  complaintsData: UseComplaintsData[];
};

export type Entity = RetailClaimProjection & {
  isTrial?: boolean;
  claimGroup: RetailClaimGroupProjectionFragmentFragment;
  subClaims: ExtendedSubClaim[];
};

interface OutputSelectorProps {
  entities: Entity[];
}

export default createStructuredSelector<
  InputSelectorProps,
  OutputSelectorProps
>({
  entities: createSelector(
    claimsSelector,
    claimGroupsSelector,
    (claims, claimGroups) =>
      map((claim) => {
        const claimGroup = flow(find({ id: claim.claimGroupId }))(claimGroups);
        const [areaLabel, partLabel, damageLabel] = castArray(
          claimGroup?.labels,
        );

        return {
          ...claim,
          isTrial: getIsTrial(claim.trialExpiredOn),
          claimGroup,
          subClaims: flow(
            get('subClaims'),
            orderBy<RetailSubClaimProjection>(['createdOnMillis'], ['asc']),
            map((subClaim: RetailSubClaimProjection): ExtendedSubClaim => {
              const { comments, documents, areaId, carPartId, damageId } =
                subClaim;
              const area = find({ id: areaId }, claimGroup?.areas);
              const carPart = find({ id: carPartId }, area?.carParts);
              const damage = find({ id: damageId }, carPart?.damages);
              const commentsObject: ItemType<RetailSubClaimCommentProjection> =
                {
                  customer: filterByCustomer(comments),
                  backOffice: filterByBoUser(comments),
                };
              const documentsObject: ItemType<RetailSubClaimDocumentProjection> =
                {
                  customer: filterByCustomer(documents),
                  backOffice: filterByBoUser(documents),
                };

              return {
                ...subClaim,
                reasons: filter(([, val]) => !!val, [
                  [areaLabel, area?.name],
                  [partLabel, carPart?.name],
                  [damageLabel, damage?.name],
                ] as [string, string][]),
                commentsAndDocuments: sortByCreatedOn([
                  ...commentsObject.backOffice,
                  ...groupDocumentsByTime(documentsObject.backOffice),
                ]),
                comments: commentsObject,
                documents: documentsObject,
                complaintsData: [],
              };
            }),
          )(claim),
        };
      }, claims),
  ),
});
