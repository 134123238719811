// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./spinner.gif";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yeQ-YNO0B6zIUz10i\\+kfQw\\=\\= {\n  width: 100%;\n  height: 100%;\n  background: transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n  background-size: 50px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DocumentViewer/Document/styles.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EAEA,gFAAA;EACA,qBAAA;AAAF","sourcesContent":[".document {\n  width: 100%;\n  height: 100%;\n\n  background: transparent url('./spinner.gif') no-repeat center;\n  background-size: 50px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"document": "yeQ-YNO0B6zIUz10i+kfQw=="
};
export default ___CSS_LOADER_EXPORT___;
