import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { InfoCard } from '../InfoCard';

import { MileageDto } from '@/apollo/gql-types';
import { InlineKeyValueRow } from '@/components/InlineKeyValueRow';
import { formatMileage } from '@/utils/i18n/formatMileage';
import {
  FormatCurrencyOptions,
  useFormatCurrency,
} from '@/utils/i18n/useFormatCurrency';

const { Text } = Typography;

interface Props {
  loading: boolean;
  car: string;
  price: FormatCurrencyOptions;
  manufacturingYear: string;
  mileage: MileageDto;
  vin: string;
  licensePlate: string;
}

export function CarDetails({
  loading,
  car,
  price,
  manufacturingYear,
  mileage,
  vin,
  licensePlate,
}: Props) {
  const { t } = useTranslation();
  const formattedPrice = useFormatCurrency(price);
  const miliageFormatted = formatMileage(mileage);

  return (
    <InfoCard
      loading={loading}
      size="small"
      title={t('bo.orderClaims.orderSummary.title.carDetails')}
    >
      <InlineKeyValueRow
        qaSelector="car"
        label={t('bo.orderClaims.orderSummary.title.car')}
        value={car}
      />
      <InlineKeyValueRow
        qaSelector="sellPrice"
        label={t('bo.orderClaims.orderSummary.title.sellPrice')}
        value={formattedPrice}
      />
      <InlineKeyValueRow
        qaSelector="manufacturingYear"
        label={t('bo.orderClaims.orderSummary.title.manufacturingYear')}
        value={manufacturingYear}
      />
      <InlineKeyValueRow
        qaSelector="mileage"
        label={t('bo.orderClaims.orderSummary.title.mileage')}
        value={miliageFormatted}
      />
      <InlineKeyValueRow
        qaSelector="vin"
        label={t('bo.orderClaims.orderSummary.title.vin')}
        value={vin ? <Text copyable>{vin}</Text> : null}
      />
      <InlineKeyValueRow
        qaSelector="licensePlate"
        label={t('bo.orderClaims.orderSummary.title.licensePlate')}
        value={vin ? <Text copyable>{licensePlate}</Text> : null}
      />
    </InfoCard>
  );
}
