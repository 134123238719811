import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission/useCheckPermissions';
import { Alert, AlertProps, Button, Card, List, Tag, Typography } from 'antd';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';

import { showModal as handleCreateClaim } from '../claim-create-modal';
import { CLAIM_STATES } from '../constants';
import useActiveClaimsTab from '../hooks/useActiveClaimsTab';
import selector from '../overview/selectors'; // Todo: refactor

import cn from './styles.less';

import {
  RetailClaimGroupsOptionsQuery,
  SearchRetailClaimsQuery,
} from '@/apollo/gql-types';
import { DOMAINS, PERMISSIONS } from '@/constants/permissions';
import { ORDER_CLAIMS_DETAILS } from '@/routes';

const { Text } = Typography;

const claimStateAlertTypeMap: Record<CLAIM_STATES, AlertProps['type']> = {
  [CLAIM_STATES.SUBMITTED]: 'info',
  [CLAIM_STATES.IN_PROGRESS]: 'success',
  [CLAIM_STATES.AWAITING_REPAIR]: 'success',
  [CLAIM_STATES.AWAITING_COST_ESTIMATE]: 'success',
  [CLAIM_STATES.ADDITIONAL_PROOF_REQUIRED]: 'warning',
  [CLAIM_STATES.PROOF_SUBMITTED]: 'warning',
  [CLAIM_STATES.CLOSED]: 'info',
};

interface Props {
  loading: boolean;
  claims: SearchRetailClaimsQuery;
  claimGroups: RetailClaimGroupsOptionsQuery;
}

export const ClaimsList = memo<Props>(({ loading, claims, claimGroups }) => {
  const { language, orderId } = useParams();
  const { t } = useTranslation();
  const activeTabKey = useActiveClaimsTab();
  const result = useMemo(
    () =>
      selector({
        claims,
        claimGroups,
      }),
    [claimGroups, claims],
  );
  const orderClaimsCreatePermission = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_ORDER_CLAIMS_CREATE,
    domain: DOMAINS.CLAIM_MANAGEMENT,
  });

  const hasAtLeastOneTrial = result.entities.some((x) => x.isTrial);

  return (
    <Card
      loading={loading}
      className={cn.infoCard}
      bodyStyle={{ padding: 0 }}
      title={
        orderClaimsCreatePermission.isAllowed && (
          <Button
            type="primary"
            disabled={loading}
            onClick={() => handleCreateClaim()}
            className={cn.submitNewClaim}
            data-qa-selector="request-new-claim"
          >
            {t('bo.orderClaims.submitNewClaim.CTA')}
          </Button>
        )
      }
    >
      {Array.isArray(result?.entities) ? (
        <>
          <Text strong className={cn.listTitle}>
            {`${t('bo.orderClaims.claimsList.title')} (${
              result.entities.length
            })`}
          </Text>
          <List
            bordered
            data-qa-selector="test1"
            loading={loading}
            className={cn.list}
            dataSource={result.entities}
            renderItem={({ id, number, claimGroup, state, isTrial }) => (
              <List.Item
                key={number}
                data-qa-selector={number}
                title={`${number} - ${t(claimGroup?.name)}`}
              >
                <NavLink
                  className={cn.itemLink}
                  title={`${number}, ${t(claimGroup?.name)}, ${t(
                    `bo.orderClaims.claim.status.${state}`,
                  )}`}
                  to={`${ORDER_CLAIMS_DETAILS.LINK({
                    language,
                    orderId,
                    claimId: id,
                  })}${activeTabKey}`}
                >
                  <div className={cn.itemInfo}>
                    {isTrial ? (
                      <Tag
                        data-qa-selector="trial"
                        data-qa-selector-value={number}
                        className={cn.trial}
                        color="error"
                      >
                        {t('bo.orderOverview.table.column.claimNumber.trial')}
                      </Tag>
                    ) : null}
                    <span
                      style={{
                        marginLeft: hasAtLeastOneTrial ? '48px' : undefined,
                      }}
                      data-qa-selector="claim-id-side-column"
                    >
                      {number}
                    </span>
                    <Alert
                      className={cn.alert}
                      type={claimStateAlertTypeMap[state as CLAIM_STATES]}
                      message={t(`bo.orderClaims.claim.status.${state}`)}
                    />
                  </div>
                  <span
                    className={cn.claimGroupName}
                    data-qa-selector="claim-group-side-column"
                  >
                    {t(claimGroup?.name)}
                  </span>
                </NavLink>
              </List.Item>
            )}
          />
        </>
      ) : (
        <Alert
          showIcon
          type="warning"
          message={t('bo.orderClaims.notFoundText')}
        />
      )}
    </Card>
  );
});
