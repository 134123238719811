import { identity, isFunction, isString, map } from 'lodash/fp';

export default (...args: unknown[]) => {
  let [formatLabel, prop] = args;

  if (isString(formatLabel)) {
    prop = formatLabel;
  }

  if (!isFunction(formatLabel)) {
    formatLabel = identity;
  }

  return map((item) => {
    let value = item;

    if (prop) {
      value = (item as Record<string, unknown>)[prop as string];
    }

    return {
      value,
      label: (formatLabel as (x: unknown) => unknown)(value),
    };
  });
};
